import { styled } from "@mui/material/styles";
import { Divider, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "../../components/crud/Container";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { Button } from "@components/Button";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../../recoil/auth";
import { ReportPostView } from "@components/ReportPostView";
import { GridColDef } from "@mui/x-data-grid";
import { RenderTableView } from "@components/RenderTableView";
import { PostCommentAction } from "@components/PostCommentAction";
import { getReportDetail, reportAction } from "@services/Network";
import { SkeletonFeedCommentLoader } from "@components/SkeletonFeedCommentLoader";
import { profileAtom } from "@recoil/auth";
import {
  ModelPost,
  ModelReport,
  PostFlagActionType,
  useAdminFeedStreamActionPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { VerticalListTooltip } from "@components/VerticalListTooltip";
import { objectPropertyRecursive } from "@utils/objectPropertyRecursive";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import format from "date-fns/format";
import { capitalize } from "@utils/capitalize";
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300,
    color: "#1E293B",
    fontFamilt: "Inter"
  }
}));
const TableHeader = styled(Typography)(() => ({
  color: "#B3B3B3",
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Inter"
}));
const TableValue = styled(Typography)(() => ({
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Inter"
}));
const StyeldButtonGrid = styled(Grid)(() => ({
  "& Button": {
    margin: "12px"
  }
}));

const wentLiveDate = (isoString: string | undefined | Date) => {
  if (!isoString) return "Not Started";
  return format(new Date(isoString), "h:mm a MM/dd/yyyy");
};

const formatTimestamp = (timestamp: string): string => {
  const [hours, minutes, seconds] = timestamp.split(":").map(Number);

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds}s`;
  }

  return formattedTime.trim();
};

export const ReportDetailView = () => {
  const [searchParams] = useSearchParams();
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const userId = useRecoilValue(profileAtom)?.userId;
  const { filter, type, id } = useParams<{
    filter: string;
    type: string;
    id: string;
  }>() as { filter: string; type: string; id: string };
  const [onRemove, setOnRemove] = useState(false);
  const [removeConfirmation, setRemoveconfirmation] = useState(false);
  const [approveConfirmation, setApproveConfirmation] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [deleteExplanation, setDeleteExplanation] = useState({ message: "" });
  const [tableType] = useState({
    type: type?.charAt(0).toUpperCase() + type.slice(1),
    filter: filter?.charAt(0).toUpperCase() + filter.slice(1)
  });

  const getReport = getReportDetail(
    type?.slice(0, -1),
    id,
    {},
    searchParams.get("post")
  );
  useEffect(() => {
    if (getReport?.error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [getReport?.error]);

  const {
    data,
    isLoading
  }: {
    data: ModelPost;
    isLoading: boolean;
  } = getReport!;

  useEffect(() => {
    if (onRemove || approveConfirmation || removeConfirmation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [onRemove, approveConfirmation, removeConfirmation]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    if (searchParams.get("post"))
      navigate(`/reports/${filter}/${type}?post=${searchParams.get("post")}`);
    else navigate(`/reports/${filter}/${type}`);
  };
  const handleRemoveClick = () => {
    if (!searchParams.get("post")) {
      if (type == "posts") {
        if (!organizationId) {
          setDisplayMessage(
            "Removing this post will delete it from SportsGravy. Please provide an explanation to the author of this post."
          );
        } else {
          setDisplayMessage(
            "Removing this post will delete it from the feed of users within your organization who received this content. Please provide an explanation to the original poster author."
          );
        }
      }
      if (type == "comments") {
        if (!organizationId) {
          setDisplayMessage(
            "Removing this comment will delete it from SportsGravy. Please provide an explanation to the author of this comment."
          );
        } else {
          setDisplayMessage(
            "Removing this comment will delete it from the feed of users within your organization who view this post. Please provide an explanation to the commenter."
          );
        }
      }
    }
    if (searchParams.get("post")) {
      if (type == "posts") {
        if (organizationId) {
          setDisplayMessage(
            `${
              filter === "flagged" ? "Shutting down" : "Removing"
            } this live stream will delete it from your organization and to all other SportsGravy users. Please provide an explanation to the Author/Filmer of this Live Stream.`
          );
        } else {
          setDisplayMessage(
            `${
              filter === "flagged" ? "Shutting down" : "Removing"
            } this live stream will delete it from SportsGravy. Please provide an explanation to the Author/Filmer of this Live Stream.`
          );
        }
      }
      if (type == "comments") {
        if (!organizationId) {
          setDisplayMessage(
            "Removing this comment will delete it from this Live Stream in SportsGravy. Please provide an explanation to the author of this comment."
          );
        } else {
          setDisplayMessage(
            "Removing this comment will delete it from this Live Stream in your organization and to all other SportsGravy users. Please provide an explanation to the author of this comment."
          );
        }
      }
    }

    setOnRemove(true);
  };
  const handleApproveClick = () => {
    setApproveConfirmation(true);
  };
  const COLUMNS: GridColDef<ModelReport>[] = [
    {
      headerName: "Date Reported",
      field: "dateReported",
      maxWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => dateFormat(row?.createdAt)
    },
    {
      headerName: "Reporter",
      field: "reporter",
      maxWidth: 200,
      flex: 1,
      valueGetter: ({ row }) =>
        row?.user?.person?.firstName + " " + row?.user?.person?.lastName
    },
    ...(searchParams.get("post") === "livestream" && type === "posts"
      ? [
          {
            headerName: "Time Stamp",
            field: "timeStamp",
            flex: 1,
            valueGetter: ({ row }) => formatTimestamp(row.timestamp)
          }
        ]
      : []),
    {
      headerName: "Issue Category",
      field: "issueCategory",
      maxWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => row?.reason?.parent?.name
    },
    {
      headerName: "Issue",
      field: "issue",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row?.reason?.name
    }
  ];
  const handleDeleteConfirmation = () => {
    setOnRemove(false);
    setRemoveconfirmation(true);
  };
  const handleDeleteClose = () => {
    setOnRemove(false);
  };
  const dateFormat = (date) => {
    const formatedDate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    //@ts-ignore
    return formatedDate.toLocaleDateString("en-US", options);
  };

  const mutation = reportAction();
  const livestreamMutation = useAdminFeedStreamActionPost();
  const reportActionClick = (action: PostFlagActionType) => {
    if (!searchParams.get("post"))
      mutation.mutate(
        {
          data: {
            ...(organizationId && { organizationId: organizationId }),
            postId: data.postId as string,
            ...(type == "comments" && { commentId: id }),
            explanation: deleteExplanation.message,
            action: action
          }
        },
        {
          onSuccess: () => {
            navigate(`/reports/${filter}/${type}`);
          }
        }
      );
    if (searchParams.get("post"))
      livestreamMutation.mutate(
        {
          data: {
            ...(organizationId && { organizationId: organizationId }),
            streamId: id as string,
            ...(type == "comments" && { commentId: id }),
            explanation: deleteExplanation.message,
            action: action
          }
        },
        {
          onSuccess: () => {
            setRemoveconfirmation(false);
            navigate(`/reports/${filter}/${type}?post=livestream`);
          }
        }
      );
  };
  return (
    <>
      {isLoading == false && (
        <Container>
          <StyledToolbar>
            <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
              <Grid
                xs={6}
                md={"auto"}
                sx={{ flexGrow: { md: "1 !important" } }}
              >
                {!searchParams.get("post") || type === "comments" ? (
                  <Typography variant="h2">{`${capitalize(filter)} ${filter === "removed" ? "Flagged" : ""} ${capitalize(type.slice(0, -1))} Detail`}</Typography>
                ) : (
                  <Typography variant="h2">{`${filter
                    ?.charAt(0)
                    .toUpperCase()}${filter?.slice(
                    1
                  )} Live Stream Detail`}</Typography>
                )}
              </Grid>
              <StyeldButtonGrid
                data-testid="report-button"
                xs={6}
                md={"auto"}
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={2}
                margin={"12px"}
              >
                <Button onClick={handleBackClick} variant="admin-secondary">
                  Back to List
                </Button>
                {filter === "flagged" && (
                  <Button variant="admin-success" onClick={handleApproveClick}>
                    Approve
                  </Button>
                )}
                {(filter === "approved" || filter == "flagged") &&
                  !searchParams.get("post") && (
                    <Button variant="admin-error" onClick={handleRemoveClick}>
                      Remove
                    </Button>
                  )}
                {(filter === "approved" || filter == "flagged") &&
                  searchParams.get("post") && (
                    <>
                      {type === "posts" && filter === "flagged" ? (
                        <Button
                          variant="admin-error"
                          onClick={handleRemoveClick}
                        >
                          Shutdown
                        </Button>
                      ) : (
                        <Button
                          variant="admin-error"
                          onClick={handleRemoveClick}
                        >
                          Remove
                        </Button>
                      )}
                    </>
                  )}
              </StyeldButtonGrid>
            </Grid>
          </StyledToolbar>
          <Divider />
          <StyledToolbar sx={{ maxWidth: "fit-content" }}>
            <Container data-testid="report-post-header">
              <Grid
                container
                columnSpacing={
                  searchParams.get("post") && type === "posts" ? 15 : 30
                }
              >
                <Grid>
                  {!searchParams.get("post") ? (
                    <TableHeader>
                      {tableType.type.slice(0, -1)} Author
                    </TableHeader>
                  ) : (
                    <TableHeader>
                      {type == "posts" ? "Filmer" : "Comment Author"}
                    </TableHeader>
                  )}

                  {type == "posts" && (
                    <Link
                      to={`/users/${data?.createdBy?.personId}`}
                      target="_blank"
                    >
                      <TableValue sx={{ color: "#3B6CF8" }}>
                        {data?.createdBy?.person?.firstName +
                          " " +
                          data?.createdBy?.person?.lastName}
                      </TableValue>
                    </Link>
                  )}
                  {type == "comments" && (
                    <Link
                      to={`/users/${data?.comments?.[0].user?.person?.personId}`}
                      target="_blank"
                    >
                      <TableValue sx={{ color: "#3B6CF8" }}>
                        {data?.comments?.[0]?.user?.person?.firstName +
                          " " +
                          data?.comments?.[0]?.user?.person?.lastName}
                      </TableValue>
                    </Link>
                  )}
                </Grid>
                <Grid>
                  {!searchParams.get("post") ? (
                    <TableHeader>
                      <TableHeader>
                        {tableType.type.slice(0, -1)} Date
                      </TableHeader>
                    </TableHeader>
                  ) : (
                    <TableHeader>
                      {type == "posts" ? "Created Date" : "Post Date"}
                    </TableHeader>
                  )}
                  {type == "posts" && (
                    <TableValue>{dateFormat(data?.createdAt)}</TableValue>
                  )}
                  {type == "comments" && (
                    <TableValue>
                      {dateFormat(data?.comments?.[0]?.createdAt)}
                    </TableValue>
                  )}
                </Grid>
                {searchParams.get("post") && type === "posts" && (
                  <Grid>
                    <TableHeader>
                      <TableHeader>Went Live On</TableHeader>
                    </TableHeader>
                    <TableValue>
                      {wentLiveDate(data?.liveStream?.startedAt)}
                    </TableValue>
                  </Grid>
                )}
                {organizationId && (
                  <Grid>
                    <TableHeader>Team/Training Program</TableHeader>
                    {!searchParams.get("post") ? (
                      <TableValue>
                        <VerticalListTooltip
                          text={
                            data?.teams
                              ?.concat(
                                (data?.programs ?? []).map((program) => ({
                                  ...program,
                                  updatedAt: program.updatedAt
                                    ? new Date(program.updatedAt)
                                    : undefined
                                }))
                              )
                              ?.slice(0, 1)
                              ?.map((record) =>
                                objectPropertyRecursive(record, "name")
                              )
                              ?.join(" / ") || "-"
                          }
                          tooltipList={
                            data?.teams
                              ?.concat(
                                (data?.programs ?? []).map((program) => ({
                                  ...program,
                                  updatedAt: program.updatedAt
                                    ? new Date(program.updatedAt)
                                    : undefined
                                }))
                              )
                              ?.slice(1)
                              ?.map((record) =>
                                objectPropertyRecursive(record, "name")
                              ) || []
                          }
                        />{" "}
                      </TableValue>
                    ) : (
                      <TableValue>
                        {data.liveStream?.teamId
                          ? data.liveStream.team?.name
                          : data.liveStream?.programId
                            ? data.liveStream.trainingProgram?.name
                            : "-"}
                      </TableValue>
                    )}
                  </Grid>
                )}
                <Grid>
                  <TableHeader>Times Flagged</TableHeader>
                  <TableValue>
                    <a
                      style={{ color: "#3B6CF8", cursor: "pointer" }}
                      href="#tableDetails"
                    >
                      {!searchParams.get("post") ? (
                        <>
                          {type == "posts"
                            ? data.reports && data?.reports.length
                            : data.comments?.[0].reports &&
                              data?.comments?.[0]?.reports.length}
                        </>
                      ) : (
                        <>
                          {type == "posts"
                            ? data.liveStream?._count?.reports
                            : data?.comments?.[0]?._count?.reports}
                        </>
                      )}
                    </a>
                  </TableValue>
                </Grid>
              </Grid>
            </Container>
          </StyledToolbar>
          <StyledToolbar sx={{ margin: "0 auto" }}>
            <ReportPostView userId={userId} content={data} rePost={false} />
          </StyledToolbar>
          <StyledToolbar>
            {((!searchParams.get("post") &&
              type == "posts" &&
              data.reports &&
              data?.reports.length > 0) ||
              (type == "comments" &&
                data.comments &&
                data.comments[0].reports &&
                data.comments?.[0].reports.length > 0)) && (
              <Container id="tableDetails">
                <RenderTableView
                  title=""
                  hideToolbar={true}
                  rows={
                    type == "posts"
                      ? (data?.reports as ModelReport[])
                      : (data?.comments?.[0]?.reports as ModelReport[])
                  }
                  columns={COLUMNS}
                  getRowId={(row) => row?.reportId}
                  searchable={false}
                  hasActionColumn={false}
                  hideFooter={true}
                />
              </Container>
            )}
            {searchParams.get("post") &&
              type == "posts" &&
              data.liveStream?.reports &&
              data?.liveStream.reports.length > 0 && (
                <Container id="tableDetails">
                  <RenderTableView
                    title=""
                    hideToolbar={true}
                    rows={
                      type == "posts"
                        ? (data?.liveStream?.reports as ModelReport[])
                        : (data?.comments?.[0]?.reports as ModelReport[])
                    }
                    columns={COLUMNS}
                    getRowId={(row) => row?.reportId}
                    searchable={false}
                    hasActionColumn={false}
                    hideFooter={true}
                  />
                </Container>
              )}
          </StyledToolbar>
        </Container>
      )}
      {onRemove && (
        <PostCommentAction
          onClick={handleDeleteConfirmation}
          setMessage={setDeleteExplanation}
          id={data.postId}
          displayMessage={displayMessage}
          action={"delete"}
          button={
            !searchParams.get("post") || type === "comments"
              ? `Remove ${tableType.type.slice(0, -1)}`
              : type === "posts" && filter === "flagged"
                ? "Shutdown"
                : "Remove"
          }
          onClose={handleDeleteClose}
          title={
            !searchParams.get("post") || type === "comments"
              ? `Remove ${tableType.type.slice(0, -1)} ${
                  !organizationId ? "from SportsGravy" : "within Organization"
                }`
              : `${filter === "flagged" ? "Shutdown" : "Remove"} Live Stream ${
                  !organizationId ? "from SportsGravy" : "within Organization"
                }`
          }
          field={[
            {
              field:
                !searchParams.get("post") || type === "comments"
                  ? `${tableType.type.slice(0, -1)} Author`
                  : "Author/Filmer",
              value:
                type === "posts"
                  ? `${data?.createdBy?.person?.firstName} ${data?.createdBy?.person?.lastName}`
                  : `${data?.comments?.[0]?.user?.person?.firstName} ${data?.comments?.[0]?.user?.person?.lastName}`
            },
            ...(organizationId
              ? [
                  {
                    field: "Organization Removed From",
                    value: `${
                      organizations.find(
                        (org) => org.organizationId === organizationId
                      )?.name
                    }`
                  }
                ]
              : [])
          ]}
        />
      )}
      <ConfirmationDialog
        open={removeConfirmation}
        title={`${searchParams.get("post") === "livestream" ? `${filter === "flagged" ? "Shutdown" : "Remove"} Live Stream` : `Remove ${tableType.type.slice(0, -1)}`}`}
        body={
          searchParams.get("post") === "livestream" &&
          type?.slice(0, -1) == "post"
            ? `Are you sure you want to ${filter === "flagged" ? "shutdown" : "remove"} this live stream ${
                !organizationId
                  ? "within SportsGravy"
                  : "within your organization"
              }? This change cannot be undone.`
            : `Are you sure you want to remove this ${type?.slice(0, -1)} ${
                !organizationId
                  ? "from SportsGravy"
                  : "within your organization"
              }? This change cannot be undone.`
        }
        close={() => setRemoveconfirmation(false)}
        onConfirm={() => {
          setRemoveconfirmation(false);
          reportActionClick("REMOVE");
        }}
        onCancel={() => setRemoveconfirmation(false)}
        confirmBtnVariant="admin-error"
        icon="error"
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <ConfirmationDialog
        title={`Are you sure you want to Approve this ${
          !searchParams.get("post") || type === "comments"
            ? capitalize(type?.slice(0, -1))
            : "Live Stream"
        }?`}
        body=""
        open={approveConfirmation}
        close={() => setApproveConfirmation(false)}
        onCancel={() => setApproveConfirmation(false)}
        isConfirming={mutation.isLoading || livestreamMutation.isLoading}
        onConfirm={() => {
          reportActionClick("APPROVE");
        }}
      />
      {isLoading && (
        <Container alignItems="center">
          <Grid container>
            <SkeletonFeedCommentLoader type={"feed"} />
          </Grid>
        </Container>
      )}
    </>
  );
};
