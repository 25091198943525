import { Loader } from "@components/crud/Loader";
import { RenderTableView } from "@components/RenderTableView";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  ModelAdvertisement,
  useAdminAdvertiserAdvertisementGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AD_LAYOUT_OPTIONS, AD_PLACEMENT_OPTIONS } from "@utils/constants";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};
const LIST_COLUMNS: GridColDef<ModelAdvertisement>[] = [
  {
    headerName: "Advertisement",
    field: "name",
    minWidth: 250,
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <>
        {(row.media || row.metadata?.image) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            {row.placement === "INSTREAM" ||
            (row.media && row.media.type == "VIDEO") ? (
              <>
                {row.media?.path?.includes(".m3u8") ? (
                  <img
                    width="140px"
                    height="100px"
                    style={{ marginRight: "1rem" }}
                    src={(row?.media?.baseUrl
                      ? row?.media?.baseUrl + row?.media?.path
                      : ""
                    )?.replace(".m3u8", ".0000001.jpg")}
                  />
                ) : (
                  <ReactPlayer
                    width="140px"
                    height="100px"
                    style={{ marginRight: "1rem" }}
                    url={
                      row?.media?.baseUrl
                        ? row?.media?.baseUrl + row?.media?.path
                        : ""
                    }
                  />
                )}
              </>
            ) : row.layout == "Link" || row.media ? (
              <img
                src={
                  row.layout != "Link"
                    ? row.media?.baseUrl && row.media.baseUrl + row.media.path
                    : row.layout == "Link" && row.metadata?.image
                      ? row.metadata.image
                      : ""
                }
                style={{
                  width: "140px",
                  height: "100px",
                  marginRight: "1rem"
                }}
                alt="Media"
              />
            ) : null}
          </div>
        )}
      </>
    )
  },
  {
    headerName: "Ad Placement",
    field: "placement",
    minWidth: 150,
    sortable: false,
    flex: 1,
    valueGetter: ({ value }) =>
      AD_PLACEMENT_OPTIONS.find((o) => o.value === value)?.label
  },
  {
    headerName: "Ad Layout",
    field: "layout",
    minWidth: 400,
    sortable: false,
    flex: 1,
    valueGetter: ({ value }) =>
      AD_LAYOUT_OPTIONS.find((layout) => layout.value === value)?.label || "-"
  },
  {
    headerName: "Duration",
    field: "duration",
    minWidth: 100,
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => {
      if (row.placement === "INSTREAM") {
        return row.duration ? `${row.duration} sec` : "-";
      }
      return "-";
    }
  },
  {
    headerName: "Is Approved",
    field: "isApproved",
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) => {
      if (!params.value) {
        return "No";
      }
      return `Yes`;
    }
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value == "INACTIVE") {
        return "Inactive";
      }
      return `Active`;
    }
  },
  {
    headerName: "Created on",
    field: "createdAt",
    minWidth: 250,
    flex: 1,
    valueGetter: ({ value }) => value,
    valueFormatter: ({ value }) => dateFormat(value)
  }
];

export const Advertisements = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { advertiserId } = useParams();
  const { data: advertisements, isLoading: isAdvertisementLoading } =
    useAdminAdvertiserAdvertisementGet(advertiserId!);
  const [permissions, setPermissions] = useState({
    edit: false
  });
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);

  const onAdd = () => navigate("/advertisers/create");
  const onEdit = (ad) => navigate(`advertisement/${ad.advertisementId}/edit`);
  const onView = (ad) => navigate(`advertisement/${ad.advertisementId}`);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      setPermissions({
        edit: edit
      });
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  return (
    <Loader isLoading={isAdvertisementLoading || isLoadingPermissions}>
      <RenderTableView
        title=""
        columns={LIST_COLUMNS}
        hideToolbar={true}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isEditDisabled={() => !permissions.edit}
        isDeleteDisabled={() => true}
        getRowHeight={() => 150}
        getRowId={(row) => row.advertisementId}
        rows={advertisements?.data || []}
        hideFooter={true}
      />
    </Loader>
  );
};
