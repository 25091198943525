import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { FormProvider, useForm } from "react-hook-form";
import { AboutBuisness } from "./components/AboutBuisness";
import { AboutOwner } from "./components/AboutOwner";
import { useAdminMerchantGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { Loader } from "@components/crud/Loader";
import { getCountries } from "@services/Network";
import { useNavigate } from "react-router-dom";
import { AccountSetup } from "./components/AccountSetup";
import { AddBankAccount } from "./components/AddBankAccount";

export const convertStringToDate = (dateNumber: string): Date => {
  const dateString = dateNumber.toString();
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1;
  const day = parseInt(dateString.substring(6, 8), 10);
  return new Date(year, month, day);
};

export const MerchantView = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("Business");
  const orgId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currOrg = orgs.find((org) => org.organizationId === orgId);
  const onTabChange = (event: SyntheticEvent, value: string) => {
    setTab(value as string);
  };
  const form = useForm({
    mode: "onTouched"
  });

  const { data: countries } = getCountries({
    staleTime: Infinity
  });

  const countryOptions: {
    value: string;
    label: string;
    altId: string;
  }[] = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId!,
        label: country.name!,
        altId: country.countryAltId!
      }));
    }
    return [];
  }, [countries]);

  const { reset } = form;

  const { data: merchant, isLoading: isLoading } = useAdminMerchantGet(
    currOrg?.merchantId as string
  );

  useEffect(() => {
    if (merchant?.data) {
      const merchantDetails = merchant?.data;
      reset({
        type: merchantDetails.merchant?.entity?.type?.toString(),
        name: merchantDetails.merchant?.entity?.name,
        dba: merchantDetails.merchant?.dba,
        haveEmployees: true,
        sdLabel: merchantDetails.merchant?.dba,
        customerPhone: merchantDetails.merchant?.entity?.customerPhone,
        established: convertStringToDate(
          merchantDetails.merchant?.established as string
        ),
        globalBusinessId:
          "*****" + merchantDetails.merchant?.entity?.globalBusinessId,
        avgTicket: merchantDetails.merchant?.avgTicket,
        acceptCreditCard: merchantDetails.merchant?.annualCCSales
          ? true
          : false,
        annualCCSales: merchantDetails.merchant?.annualCCSales,
        phone: merchantDetails.merchant?.entity?.phone,
        fax: merchantDetails.merchant?.entity?.fax,
        email: merchantDetails.merchant?.entity?.email,
        website: merchantDetails.merchant?.entity?.website,
        country: countryOptions.find(
          (country) =>
            country.altId === merchantDetails.merchant?.entity?.country
        )?.value,
        address1: merchantDetails.merchant?.entity?.address1,
        address2: merchantDetails.merchant?.entity?.address2,
        merchantType: merchantDetails.merchant?.environment,
        city: merchantDetails.merchant?.entity?.city,
        state: merchantDetails.merchant?.entity?.state,
        zip: merchantDetails.merchant?.entity?.zip,
        ownerDetails: merchantDetails.merchant?.members?.map((owner) => ({
          primary: owner.primary === 1,
          firstName: owner.first,
          lastName: owner.last,
          title: owner.title,
          dob: owner.dob,
          ownership: (owner?.ownership || 0) / 100,
          ssn: "***-**-" + owner.ssn,
          phone: owner.phone,
          email: owner.email,
          country: countryOptions.find(
            (country) => country.altId === owner.country
          )?.value,
          address1: owner.address1,
          address2: owner.address2,
          city: owner.city,
          state: owner.state,
          zip: owner.zip,
          significantResponsibility: owner.significantResponsibility === 1,
          politicallyExposed: owner.politicallyExposed === 1,
          dl: owner.dl ? "****" + owner.dl : "",
          dlstate: owner.dlstate
        })),
        method: String(merchantDetails.merchant?.accounts?.account?.method),
        routing: merchantDetails.merchant?.accounts?.account?.routing,
        number: "*****" + merchantDetails.merchant?.accounts?.account?.number,
        mcc: merchantDetails.merchant?.mcc,
        status: merchantDetails.merchant?.status?.toString(),
        tnc: true,
        tcAttestation: true,
        tcVersion: merchantDetails.merchant?.entity?.tcVersion,
        tcAcceptDate: convertStringToDate(
          merchantDetails.merchant?.entity?.tcAcceptDate as string
        ),
        tcAcceptTime: convertStringToDate(
          merchantDetails.merchant?.entity?.tcAcceptDate as string
        ),
        tcAcceptIp: merchantDetails.merchant?.entity?.tcAcceptIp
      });
    }
  }, [merchant]);

  return (
    <Container>
      <Loader isLoading={isLoading}>
        <Toolbar
          title="View Merchant Account"
          tabs={{
            tabs: [
              "Business",
              "Owners Details",
              "Account Setup",
              "Bank Account"
            ],
            onTabChange: onTabChange,
            activeTab: tab
          }}
          editBtnClick={() => navigate(`/merchant-account/edit`)}
        />
        <Form>
          <FormProvider {...form}>
            {tab === "Business" && (
              <AboutBuisness disabled={true} mode="VIEW" />
            )}
            {tab === "Owners Details" && (
              <AboutOwner disabled={true} mode="VIEW" />
            )}
            {tab === "Account Setup" && (
              <AccountSetup disabled={true} mode="VIEW" />
            )}
            {tab === "Bank Account" && (
              <AddBankAccount disabled={true} mode="VIEW" />
            )}
          </FormProvider>
        </Form>
      </Loader>
    </Container>
  );
};
