import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect, useMemo, useState } from "react";
import { CampaignForm } from "./CampaignForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  ModelOrganizationAccountContact,
  useAdminAdvertiserCampaignPost,
  useAdminAdvertiserIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { Button } from "@components/Button";
import colors from "theme/colors";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { SendInvoiceModal } from "@pages/advertiser/SendInvoiceModal";
import { enqueueSnackbar } from "notistack";

export const CampaignAdd = () => {
  const navigate = useNavigate();
  const { advertiserId } = useParams();

  const form = useForm({ mode: "onBlur" });
  const {
    reset,
    setValue,
    watch,
    getValues,
    formState: { isValid },
    clearErrors,
    trigger
  } = form;

  const contactId = watch("contactId");

  const { data: advertiser, isLoading: isAdvertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!);

  const { mutate: createCampaign, isLoading: isCreatingCampaign } =
    useAdminAdvertiserCampaignPost();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);

  useEffect(() => {
    const date = new Date();
    if (advertiser?.data)
      reset({
        advertiser: advertiser?.data.businessName,
        name: `${date.getFullYear()} - ${date.getFullYear() + 1} Campaign`
      });
  }, [advertiser]);

  const contactOptions: {
    label: string;
    value: string;
    email: string;
    contact: ModelOrganizationAccountContact;
  }[] = useMemo(
    () =>
      advertiser?.data?.account?.contacts?.map((contact) => ({
        label: `${contact?.contact?.firstName} ${contact?.contact?.lastName}`,
        value: contact?.contactId as string,
        email: contact.workEmail,
        contact: contact
      })) || [],
    [advertiser]
  );

  const saveHandler = async () => {
    const values = getValues();
    createCampaign(
      {
        advertiserId: advertiserId!,
        data: {
          name: values.name,
          sponsorshipId: values.sponsorshipId,
          startDate: values.startDate,
          endDate: values.endDate,
          billingContactId: values.contactId,
          isTaxExempt: values.taxExempt,
          sendInvoice: values.sendInvoice || false
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Campaign created successfully", {
            variant: "success"
          });
          if (values.sendInvoice)
            enqueueSnackbar("Invoice sent successfully", {
              variant: "success"
            });
          navigate(`/advertisers/${advertiserId}?tab=Campaigns`);
        },
        onError: () => {
          enqueueSnackbar("Failed to create campaign", {
            variant: "error"
          });
        }
      }
    );
  };

  const contact = useMemo(() => {
    return contactOptions.find((contact) => contact.value === contactId);
  }, [contactId]);

  return (
    <Container>
      <Toolbar title="Add Campaign" />
      <Loader isLoading={isAdvertiserLoading}>
        <Form>
          <FormProvider {...form}>
            <CampaignForm contactOptions={contactOptions} mode="CREATE" />
          </FormProvider>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        additionalBtns={[
          <Button
            key="save"
            variant="admin-secondary"
            type="button"
            style={{
              border: `2px solid ${isValid ? colors.primary.main : colors.grey.main}`
            }}
            isLoading={isCreatingCampaign}
            disabled={isCreatingCampaign || !isValid}
            onClick={saveHandler}
          >
            Save
          </Button>,
          <Button
            key="save&send"
            variant="admin-primary"
            type="button"
            isLoading={isCreatingCampaign}
            disabled={isCreatingCampaign || !isValid}
            onClick={() => {
              setValue(
                "workEmail",
                contactOptions.find((contact) => contact.value === contactId)
                  ?.email
              );
              setShowSendDialog(true);
            }}
          >
            Save & Send Invoice
          </Button>
        ]}
      />
      {showSendDialog && (
        <FormProvider {...form}>
          <SendInvoiceModal
            onClose={() => {
              clearErrors("workEmail");
              trigger();
              setShowSendDialog(false);
            }}
            title="Send Invoice"
            advertiserId={advertiserId!}
            type="SEND"
            contactSelected={{
              value: contactId,
              email: contactOptions.find(
                (contact) => contact.value === contactId
              )?.email,
              label: contactOptions.find(
                (contact) => contact.value === contactId
              )?.label,
              contact: {
                firstName: contact?.contact?.contact?.firstName as string,
                lastName: contact?.contact?.contact?.lastName as string,
                personalEmail: contact?.contact?.contact?.email as string,
                workEmail: contact?.contact?.workEmail as string,
                accountId: contact?.contact?.accountId as string,
                contactId: contactId,
                jobTitle: contact?.contact?.jobTitle as string
              }
            }}
            onSave={(contact) => {
              setValue("contactId", contact.contactId);
              setValue("sendInvoice", true);
              setShowSendDialog(false);
              saveHandler();
            }}
          />
        </FormProvider>
      )}
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/advertisers/${advertiserId}?tab=Campaigns`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
