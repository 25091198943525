import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  useAdminAdvertiserIdGet,
  ModelAdvertiser,
  useAdminAdvertiserIdDelete,
  ModelMedia,
  useAdminAdvertiserAdvertiserIdPut,
  useAdminCrmOrgContactContactIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { FSOAdvertiserForm, SelectedAccountContact } from "./FSOAdvertiserForm";
import { Footer } from "@components/crud/Footer";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const FSOAdvertiserEdit = () => {
  const orgId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const [advertiserToDelete, setAdvertiserToDelete] =
    useState<ModelAdvertiser | null>(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [logo, setLogo] = useState<ModelMedia | File | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const [accountContacts, setAccountContacts] = useState<
    SelectedAccountContact[]
  >([]);

  const { advertiserId } = useParams();

  const { data: advertiser, isLoading: advertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!);

  const { mutate: advertiserEdit, isLoading: isEditing } =
    useAdminAdvertiserAdvertiserIdPut();

  const { mutate: contactUpdate, isLoading: contactUpdating } =
    useAdminCrmOrgContactContactIdPut();

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminAdvertiserIdDelete();

  const form = useForm({
    mode: "onTouched"
  });

  const {
    reset,
    formState: { isValid, isDirty },
    getValues
  } = form;

  useEffect(() => {
    if (advertiser?.data) {
      const advertiserContacts = advertiser.data?.contacts;
      const contacts = advertiser.data?.account?.contacts?.map((contact) => {
        return {
          contactId: contact.contactId as string,
          firstName: contact?.contact?.firstName as string,
          lastName: contact?.contact?.lastName as string,
          workEmail: contact.workEmail,
          workPhone: contact.workPhone,
          personalEmail: contact?.contact?.email,
          jobTitle: contact.jobTitle,
          allowApproveDecline: advertiserContacts?.find(
            (c) => c.contactId === contact.contactId
          )?.isApprovalRequired,
          emailType:
            !advertiserContacts?.find((c) => c.contactId === contact.contactId)
              ?.sendApprovalEmailsToPersonal && contact.workEmail
              ? "WORK_EMAIL"
              : contact?.contact?.email
                ? "PERSONAL_EMAIL"
                : undefined
        };
      });
      reset({
        businessName: advertiser.data.businessName,
        account: advertiser.data.businessName,
        accountId: advertiser.data.account?.accountId,
        contact: contacts,
        icon: advertiser.data.logo?.mediaId
      });
      setAccountContacts(contacts || []);
      setLogo(advertiser?.data.logo);
    }
  }, [advertiser]);

  const onConfirmDelete = async () => {
    if (!advertiserToDelete?.advertiserId) return;
    try {
      await deleteAsync({ advertiserId: advertiserToDelete.advertiserId });
      enqueueSnackbar("Advertiser deleted successfully", {
        variant: "success"
      });
      setAdvertiserToDelete(null);
      navigate("/advertisers");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete advertiser.", {
        variant: "error"
      });
      setAdvertiserToDelete(null);
    }
  };

  const saveHandler = async () => {
    const values = getValues();

    if (logo instanceof File) {
      setLoading(true);
      const filesPromises = await Promise.all(
        [logo].map((file) => {
          if (file instanceof File) {
            const promise = uploadMediaUsingPresignedUrl(file);
            return promise;
          }
        })
      );
      setLoading(false);
      if (filesPromises[0]) values["logoId"] = filesPromises[0];
    } else values["logoId"] = logo?.mediaId;

    const contacts = (values.contact as SelectedAccountContact[]).map(
      (contact) => {
        return {
          contactId: contact.contactId,
          isApprovalRequired: contact.allowApproveDecline || false,
          sendApprovalEmailsToPersonal:
            contact.emailType === "PERSONAL_EMAIL" || false
        };
      }
    );
    const updatedContacts = accountContacts
      .map((contact) => {
        const updatedContact = (
          values.contact as SelectedAccountContact[]
        ).find((c) => c.contactId === contact.contactId);
        if (
          updatedContact &&
          (updatedContact.workEmail !== contact.workEmail ||
            updatedContact.personalEmail !== contact.personalEmail)
        ) {
          return updatedContact;
        }
        return null;
      })
      .filter((contact) => contact !== null);

    advertiserEdit(
      {
        advertiserId: advertiserId!,
        data: {
          accountId: advertiser?.data.account?.accountId,
          businessName: values.businessName,
          logoId: values.logoId,
          contacts: contacts
        }
      },
      {
        onSuccess: () => {
          updatedContacts.forEach((contact) => {
            contactUpdate({
              contactId: contact.contactId,
              data: {
                email: contact.personalEmail,
                firstName: contact.firstName,
                lastName: contact.lastName,
                organizationId: orgId!,
                accounts: [
                  {
                    jobTitle: contact.jobTitle,
                    accountId: values.accountId,
                    workEmail: contact.workEmail
                  }
                ]
              }
            });
          });
          enqueueSnackbar("Advertiser updated successfully", {
            variant: "success"
          });
          navigate(`/advertisers/${advertiserId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit advertiser", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Loader isLoading={advertiserLoading}>
      <Container>
        <Toolbar title="Edit Advertiser" />

        <Form>
          <FormProvider {...form}>
            <FSOAdvertiserForm
              mode="EDIT"
              logo={logo as ModelMedia}
              setLogo={setLogo}
              advertiser={advertiser?.data}
              selectedAccountContacts={accountContacts}
              setAccountContacts={setAccountContacts}
            />
          </FormProvider>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={saveHandler}
          isDisabled={
            !isValid ||
            isEditing ||
            isLoading ||
            contactUpdating ||
            !isDirty ||
            !accountContacts.some((contact) => contact.allowApproveDecline)
          }
          isLoading={isEditing || isLoading || contactUpdating}
          saveBtnLabel="Save"
        />
      </Container>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/advertisers")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <ConfirmationDialog
        open={!!advertiserToDelete}
        title="Delete Advertiser?"
        body={`Are you sure you want to delete ${advertiserToDelete?.businessName}?`}
        close={() => setAdvertiserToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setAdvertiserToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Loader>
  );
};
