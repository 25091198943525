import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { AccountDetailsForm } from "./components/AccountDetailsForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCountries } from "@services/Network";
import {
  ModelAccountCreateInput,
  ModelCountry,
  usePostAdminCrmAccount,
  usePostAdminCrmOrgAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Footer } from "@components/crud/Footer";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { RelatedLink } from "../components/ReleatedLinks";
import { cleanObject } from "@utils/cleanObject";
import { enqueueSnackbar } from "notistack";
import { Button } from "@components/Button";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const AccountAdd = () => {
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "onTouched"
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.accounts", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);
  const countries = getCountries();
  const [isBillingSame, setIsBillingSame] = useState(true);
  const [isShippingSame, setIsShippingSame] = useState(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [country, setCountry] = useState<ModelCountry | undefined>(undefined);
  const organizationId = useRecoilValue(organizationAtom);
  const selectAddressSuggestion = (
    place,
    addressType: "officeAddress" | "billingAddress" | "shippingAddress"
  ) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue(addressType, address);
    if (
      countries &&
      countries.data &&
      countries.data.find((c) => c.countryId == country?.short_name)
    ) {
      setCountry(
        countries.data.find((c) => c.countryId == country?.short_name)
      );
    }
  };

  useEffect(() => {
    setValue("isBillingSameAs", isBillingSame);
    setValue("isShippingSameAs", isShippingSame);
    setValue("billingSameAs", "OFFICE");
    setValue("shippingSameAs", "OFFICE");
    setValue("phoneType", "MOBILE");
  }, []);
  const { mutate: save, isLoading: isSaving } = organizationId
    ? usePostAdminCrmOrgAccount()
    : usePostAdminCrmAccount();

  const onSave = (shouldAddContact: boolean = false) => {
    const billingAddress = getValues().isBillingSameAs
      ? getValues().officeAddress
      : getValues().billingAddress;
    const shippingAddress = getValues().isShippingSameAs
      ? getValues().shippingSameAs === "OFFICE"
        ? getValues().officeAddress
        : getValues().billingAddress
      : getValues().shippingAddress;
    const data = {
      name: getValues().name,
      ...(!organizationId && {
        type: getValues().type
      }),
      category: getValues().category,
      ownerId: getValues().owner,
      parentId: parentId,
      officeAddress: getValues().officeAddress,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      email: getValues().email,
      phone: getValues().phone,
      fax: getValues().fax,
      phoneType: getValues().phoneType || "MOBILE",
      notes: getValues().notes,
      website: getValues().website,
      links: socialMediaLinks
        .filter((l) => l.name)
        .map((l) => ({
          type: l.name,
          link: l.url,
          altName: l.otherName
        })),
      ...(organizationId
        ? {
            organizationId: organizationId,
            isTaxExempt: getValues().isTaxExempt
          }
        : {
            csmOwnerId: getValues().csmOwner,
            aeOwnerId: getValues().aeOwner,
            bdrOwnerId: getValues().bdrOwner,
            sports: getValues().sportsOffered,
            numberOfAthletes: parseInt(getValues().noOfAtheletes) || 0
          })
    };
    save(
      {
        data: cleanObject(data) as ModelAccountCreateInput
      },
      {
        onSuccess: (resp) => {
          enqueueSnackbar("Account Created successfully!", {
            variant: "success"
          });
          if (shouldAddContact) {
            navigate(`/crm/contacts/create?accountId=${resp.data.accountId}`);
          } else navigate(`/crm/accounts/${resp.data.accountId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Account!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [socialMediaLinks, setSocialMediaLinks] = useState<RelatedLink[]>([
    {
      url: "",
      name: "",
      otherName: ""
    }
  ]);
  const [parentId, setParentId] = useState<string | null>(null);
  return (
    <Container>
      <Toolbar title="Add Account" />
      <AccountDetailsForm
        control={control}
        isBillingSame={isBillingSame}
        isShippingSame={isShippingSame}
        setIsBillingSame={setIsBillingSame}
        setIsShippingSame={setIsShippingSame}
        selectAddressSuggestion={selectAddressSuggestion}
        country={country}
        relatedLinks={socialMediaLinks}
        setRelatedLinks={setSocialMediaLinks}
        mode="add"
        parentId={parentId}
        setParentId={setParentId}
        trigger={trigger}
      />
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        additionalBtns={[
          <Button
            key="Save"
            variant="admin-primary"
            type="button"
            onClick={() => {
              onSave();
            }}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
          >
            Save
          </Button>,
          <Button
            key="Save&AddContact"
            variant="admin-primary"
            type="button"
            onClick={() => {
              onSave(true);
            }}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
          >
            Save & Add Contact
          </Button>
        ]}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/accounts")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
