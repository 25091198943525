import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getAdvertiserCampaigns } from "@services/Network";
import { ModelAdvertiserCampaign } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatDateForDisplay } from "@utils/formatDate";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const Capmaigns = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    add: false
  });
  const [refreshKey] = useState(0);
  const { advertiserId } = useParams();
  const navigate = useNavigate();
  const CAMPAIGNS_COLUMNS: GridColDef<ModelAdvertiserCampaign>[] = [
    {
      headerName: "Campaign Name",
      field: "name",
      minWidth: 200,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => row.name
    },
    {
      headerName: "Sponsorship Level",
      field: "sponsorshipLevel",
      minWidth: 200,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => row.sponsorshipLevel?.name
    },
    {
      headerName: "Start Date",
      field: "startDate",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) =>
        row.startDate ? formatDateForDisplay(new Date(row.startDate)) : "-"
    },
    {
      headerName: "End Date",
      field: "endDate",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) =>
        row.endDate ? formatDateForDisplay(new Date(row.endDate)) : "-"
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => {
        if (row.isActive && row.endDate) {
          const endDate = new Date(row.endDate);
          const currentDate = new Date();
          if (endDate < currentDate) {
            return "Completed";
          }
          return "Active";
        }
        return "Inactive";
      }
    },
    {
      headerName: "Invoice Sent",
      field: "hasInvoice",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => (row.hasInvoiceSent ? "Yes" : "No")
    },
    {
      headerName: "Created on",
      field: "createdOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) =>
        row.createdAt ? formatDateForDisplay(new Date(row.createdAt)) : "-"
    }
  ];

  const getCampaigns = (params, options) => {
    return getAdvertiserCampaigns(advertiserId, params, options);
  };

  const filterConfig = React.useMemo(() => {
    const options = [
      {
        label: "Active",
        value: "ACTIVE"
      }
    ];
    return {
      field: "status",
      placeholderOption: { label: "All", value: "" },
      options: options
    };
  }, []);

  const onAdd = () => {
    navigate("campaign/create");
  };

  const onView = (row) => {
    navigate(`campaign/${row.campaignId}`);
  };

  const onEdit = (row) => {
    navigate(`campaign/${row.campaignId}/edit`);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId as string,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      setPermissionLoading(true);
      const add = await checkPermission("live-streaming.advertisers", "ADD");
      console.log("add", add);
      setPermissions({
        add
      });
      setPermissionLoading(false);
    };
    fetchPermissions();
  }, []);

  console.log("permissions", permissions);
  return (
    <Container>
      <Loader isLoading={permissionLoading}>
        <TableView
          title="Campaigns"
          {...(permissions.add && { onAdd })}
          onView={onView}
          onEdit={onEdit}
          useGet={getCampaigns}
          columns={CAMPAIGNS_COLUMNS}
          getRowId={(row) => row.campaignId}
          filterConfig={filterConfig}
          hideFooter
          refreshKey={refreshKey}
        />
      </Loader>
    </Container>
  );
};
