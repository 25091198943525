import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  useAdminAdvertiserIdGet,
  ModelAdvertiser,
  useAdminAdvertiserIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Advertisements } from "./advertisements/Advertisements";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { Loader } from "@components/crud/Loader";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Capmaigns } from "@pages/campaigns/Campaigns";
import { FSOAdvertiserForm, SelectedAccountContact } from "./FSOAdvertiserForm";
import { AdvertiserInvoice } from "@pages/advertiser-invoice/AdvertiserInvoice";

export const FSOAdvertiserView = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [advertiserToDelete, setAdvertiserToDelete] =
    useState<ModelAdvertiser | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [accountContacts, setAccountContacts] = useState<
    SelectedAccountContact[]
  >([]);
  const organizationId = useRecoilValue(organizationAtom);
  const { advertiserId } = useParams();
  const [tab, setTab] = useState("Advertiser Details");
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };
  const { data: advertiser, isLoading: advertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!);

  const form = useForm({
    mode: "onTouched"
  });

  const { reset } = form;

  useEffect(() => {
    if (advertiser?.data) {
      const contacts = advertiser.data?.contacts?.map((contact) => {
        return {
          contactId: contact.contactId,
          firstName: contact?.contact?.firstName,
          lastName: contact?.contact?.lastName,
          workEmail: contact.contact?.accounts?.[0]?.workEmail,
          workPhone: contact.contact?.accounts?.[0]?.workPhone,
          personalEmail: contact?.contact?.email,
          jobTitle: contact.contact?.accounts?.[0]?.jobTitle,
          allowApproveDecline: contact.isApprovalRequired,
          emailType:
            !contact.sendApprovalEmailsToPersonal &&
            contact.contact?.accounts?.[0]?.workEmail
              ? "WORK_EMAIL"
              : contact?.contact?.email
                ? "PERSONAL_EMAIL"
                : undefined
        };
      });
      reset({
        account: advertiser.data.account?.name,
        accountId: advertiser.data.account?.accountId,
        businessName: advertiser.data.businessName,
        contact: contacts
      });
      setAccountContacts(contacts);
    }
  }, [advertiser]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminAdvertiserIdDelete();

  const onConfirmDelete = async () => {
    if (!advertiserToDelete?.advertiserId) return;
    try {
      await deleteAsync({ advertiserId: advertiserToDelete.advertiserId });
      enqueueSnackbar("Advertiser deleted successfully", {
        variant: "success"
      });
      setAdvertiserToDelete(null);
      navigate("/advertisers");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete advertiser.", {
        variant: "error"
      });
      setAdvertiserToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("live-streaming.advertisers", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (searchParams.has("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, []);

  return (
    <Loader isLoading={advertiserLoading}>
      <Container>
        <Toolbar
          title="View Advertiser"
          {...(tab === "Advertisements" &&
            (organizationId
              ? !!advertiser?.data?.invoices?.[0]?.paidAt
              : true) && {
              addBtnClick: () =>
                navigate(`/advertisers/${advertiserId}/advertisement/create`),
              addBtnLabel: "Add Advertisement"
            })}
          {...(tab === "Advertiser Details" && {
            editBtnClick: () => navigate(`/advertisers/${advertiserId}/edit`)
          })}
          backBtnClick={() => navigate("/advertisers")}
          {...(permissions.delete &&
            tab == "Advertiser Details" && {
              deleteBtnClick: () => setAdvertiserToDelete(advertiser!.data)
            })}
          tabs={{
            tabs: [
              "Advertiser Details",
              ...(organizationId ? ["Campaigns"] : []),
              "Advertisements",
              ...(organizationId ? ["Invoices"] : [])
            ],
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />

        {tab == "Advertiser Details" && (
          <Form>
            <FormProvider {...form}>
              <FSOAdvertiserForm
                mode="VIEW"
                advertiser={advertiser?.data}
                selectedAccountContacts={accountContacts}
              />
            </FormProvider>
          </Form>
        )}
        {tab == "Advertisements" && <Advertisements />}
        {tab === "Campaigns" && (
          <Form style={{ padding: 0 }}>
            <Capmaigns />
          </Form>
        )}
        {tab === "Invoices" && (
          <Form style={{ padding: 0 }}>
            <AdvertiserInvoice />
          </Form>
        )}
      </Container>
      <ConfirmationDialog
        open={!!advertiserToDelete}
        title="Delete Advertiser?"
        body={`Are you sure you want to delete ${advertiserToDelete?.businessName}?`}
        close={() => setAdvertiserToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setAdvertiserToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Loader>
  );
};
