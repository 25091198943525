import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { Close, FilterList, MoreVert } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  MediaPhotosVideosAtom,
  MediaPhotosVideosFilterAtom,
  organizationAtom,
  profileAtom
} from "@recoil/auth";
import { SkeletonFeedCommentLoader } from "@components/SkeletonFeedCommentLoader";
import {
  AdminMediaGetParams,
  ModelAlbum,
  useAdminAlbumAlbumIdDelete,
  useAdminAlbumAlbumIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { getDateRangeBounds } from "../../utils/GetDateRange";
import { GalleryItemType } from "@pages/photos-videos/GalleryItem";
import { PhotosVideoFilterModal } from "@pages/photos-videos/PhotosVideosFilterModal";
import { Gallery } from "@pages/photos-videos/Gallery";
import { AlbumClipContextMenu } from "./AlbumClipContextMenu";
import { EditAlbum } from "@pages/live-stream/clips/EditAlbum";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import ImageIcon from "@assets/icons/imageIcon.svg";
import ProcessingPlaceholder from "@assets/images/processingPlaceholder.png";

export const PAGE_SIZE = 30;
export const AlbumView = () => {
  const navigate = useNavigate();
  const { albumId } = useParams();
  const user = useRecoilValue(profileAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const setMediaPhotosVideosAtom = useSetRecoilState(MediaPhotosVideosAtom);
  const setMediaPhotosVideosFilterAtom = useSetRecoilState(
    MediaPhotosVideosFilterAtom
  );
  const [textSearch, setTextSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openEditAlbumModal, setOpenEditAlbumModal] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [galleryItems, setGalleryItems] = useState<GalleryItemType[]>([]);
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const [openDeleteAlbum, setOpenDeleteAlbum] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [album, setAlbum] = useState<ModelAlbum>();
  const [filterState, setFilterState] = useState<
    | undefined
    | {
        category: string;
        options: { label: string; value: string; details? }[];
        selected: string[];
        queryKey: string;
      }[]
  >();
  const [permissions, setPermissions] = useState({
    editAlbum: false,
    deleteAlbum: false,
    removeFromAlbum: false,
    deleteClip: false
  });

  const {
    data,
    isLoading,
    refetch: albumGetRefetch
  } = useAdminAlbumAlbumIdGet(
    albumId!,
    {
      organizationId: organizationId!,
      pageNo: page,
      pageSize: PAGE_SIZE,
      ...(textSearch && textSearch !== "" ? { textSearch } : {}),
      ...filters
    },
    {
      query: {
        queryKey: [
          page,
          ...Object.entries(filters).flat(),
          albumId,
          textSearch
        ],
        refetchOnWindowFocus: false
      }
    }
  );

  const albumRefetch = () => {
    setSelectedItems([]);
    albumGetRefetch();
  };

  const { mutate: deleteAlbum, isLoading: isDeletingAlbum } =
    useAdminAlbumAlbumIdDelete();

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMore && (!isLoading || page == 0)) {
          setPage((page) => page + 1);
        }
      },
      {
        rootMargin: "150px",
        threshold: 0.5
      }
    )
  );

  const groupedImages = galleryItems.reduce((acc, item) => {
    const date = new Date(item.date!).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {}) as { date: string; items: GalleryItemType[] };

  const handleSelect = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const moreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      if (isLoading || !hasMore) {
        currentObserver.disconnect();
      } else {
        currentObserver.observe(currentElement);
      }
    }
    setMediaPhotosVideosAtom(galleryItems);
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, isLoading, hasMore, galleryItems]);

  useEffect(() => {
    if (!isLoading && data) {
      setAlbum(data?.data?.album);
      if (data.data.media && data.data.media.length < PAGE_SIZE) {
        setHasMore(false);
      }
      if (
        page === 0 &&
        data.data?.media &&
        data.data?.media.length >= PAGE_SIZE
      )
        setHasMore(true);

      const addData =
        data.data.media &&
        (data?.data?.media
          .filter((m) => m.baseUrl && m.path)
          .map((m) => {
            const path = m.baseUrl! + m.path!;
            return {
              id: m.mediaId!,
              type: "VIDEO",
              date: m.createdAt,
              thumbnailSrc:
                m.state === "PUBLISHED"
                  ? path.replace(".m3u8", ".0000001.jpg")
                  : ProcessingPlaceholder,
              ...(m.type === "VIDEO" && {
                duration: Math.round(
                  (m.metadata as { duration: number })?.duration || 0
                )
              }),
              title: (m.metadata as { title: string })?.title,
              mediaDetails: {
                ...m,
                mimeType: "video"
              },
              clipOf: m?.clipOf
            };
          }) as GalleryItemType[]);
      if (addData) {
        if (page !== 0) {
          setGalleryItems((d) => {
            const existingIds = new Set(d.map((item) => item.id));
            const newItems = addData.filter(
              (item) => !existingIds.has(item.id)
            );
            return [...d, ...newItems];
          });
        } else {
          setGalleryItems(addData);
        }
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (filterState) {
      const obj = {} as AdminMediaGetParams;
      filterState
        .filter((f) => f.selected.length)
        .map((f) => {
          if (f.queryKey !== "dtStart") obj[f.queryKey] = [...f.selected];
          else {
            const { dtStart, dtEnd } = getDateRangeBounds(f.selected);
            obj["dtStart"] = dtStart;
            obj["dtEnd"] = dtEnd;
          }
        });
      setPage(0);
      setFilters({ ...obj });
      setMediaPhotosVideosFilterAtom({ ...obj });
    }
  }, [filterState]);

  useEffect(() => {
    if (data?.data) {
      const roles = user?.roles?.filter(
        (role) => role.organizationId === organizationId
      );
      const isAdmin = roles?.some((role) => role.role?.alias === "ADMIN");
      const isContributor = data.data.album?.contributors?.some(
        (c) => c.userId === user?.userId
      );

      const fetchPermissions = async () => {
        const del =
          (data?.data.media?.every(
            (clip) => clip.createdById === user?.userId
          ) as boolean) ||
          (roles?.some((role) => role.role?.alias === "ADMIN") as boolean);
        setPermissions({
          editAlbum: isAdmin || (isContributor as boolean),
          deleteAlbum: isAdmin || (isContributor as boolean),
          removeFromAlbum: isAdmin || (isContributor as boolean),
          deleteClip: del
        });
      };
      fetchPermissions();
    }
  }, [selectedItems, data?.data]);

  const onConfirmDeleteAlbum = () => {
    deleteAlbum(
      {
        albumId: albumId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Album deleted successfully", { variant: "success" });
          setOpenDeleteAlbum(false);
          navigate("/albums");
        },
        onError: () => {
          enqueueSnackbar("Failed to delete album", { variant: "error" });
          setOpenDeleteAlbum(false);
        }
      }
    );
  };
  return (
    <Container>
      <Toolbar
        title="View Album"
        {...(permissions.editAlbum && {
          editBtnClick: () => setOpenEditAlbumModal(true)
        })}
        {...(permissions.deleteAlbum && {
          deleteBtnClick: () => setOpenDeleteAlbum(true)
        })}
        backBtnClick={() => navigate("/albums")}
        backBtnLabel="Back to Albums"
        secondaryTitle={album?.name}
      />

      <Form>
        {selectedItems.length > 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{
              backgroundColor: "#F3F4F7",
              width: "100%",
              height: "48px",
              marginBottom: "20px"
            }}
          >
            <Grid item container alignItems="center" xs={10}>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectedItems([]);
                  }}
                >
                  <Close htmlColor="black" fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <span
                  style={{
                    color: "#0F0F0F",
                    fontWeight: 600,
                    fontSize: "14px"
                  }}
                >
                  {selectedItems.length}{" "}
                  {selectedItems.length === 1 ? "item" : "items"} selected
                </span>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <IconButton onClick={moreOptionsClick}>
                <MoreVert htmlColor="#2B337A" />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" marginBottom="20px" spacing="20px">
            <Grid item width="90%">
              <SearchInput
                placeholder="Search"
                onChange={(e) => {
                  setTextSearch(e.target.value);
                  setPage(0);
                }}
                required={false}
                InputProps={{
                  sx: {
                    border: "1px solid #E5E5E5"
                  }
                }}
              />
            </Grid>
            <Grid item width="10%">
              <Button
                variant="outlined"
                startIcon={<FilterList />}
                onClick={() => {
                  setOpenFilter(true);
                }}
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: "50px",
                  color: "#666666",
                  borderRadius: "6px",
                  border: "1px solid #E5E5E5"
                }}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        )}
        <Loader isLoading={textSearch.length > 0 && isLoading}>
          {Object.entries(groupedImages).map(([date, items]) => (
            <div
              key={date}
              style={{
                marginBottom: "20px"
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#000000"
                }}
                variant="formLabel"
              >
                {date}
              </Typography>
              <Gallery
                items={items as GalleryItemType[]}
                selectedItems={selectedItems}
                onSelect={handleSelect}
                onClick={(id, media) => {
                  if (media?.state === "PUBLISHED") navigate(`media/${id}`);
                }}
              />
            </div>
          ))}
          {data?.data.media && data.data.media.length === 0 && page === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "64px",
                gap: "10px"
              }}
            >
              <img
                src={ImageIcon}
                alt="No media found"
                style={{
                  height: "94px",
                  width: "94px"
                }}
              />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600
                }}
              >
                No Photo / Video Available
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "center"
                }}
              >
                There is no photo or video available <br /> currently for this
                album.
              </Typography>
            </div>
          )}

          {isLoading && hasMore && <SkeletonFeedCommentLoader type="media" />}
        </Loader>
        {openEditAlbumModal && data?.data.album && (
          <EditAlbum
            onClose={() => {
              setOpenEditAlbumModal(false);
              albumGetRefetch();
            }}
            album={data?.data.album}
          />
        )}
        <ConfirmationDialog
          open={openDeleteAlbum}
          title={`Delete Album`}
          body={`Are you sure you want to delete this ${data?.data.album?.name} Album?`}
          onConfirm={onConfirmDeleteAlbum}
          isConfirming={isDeletingAlbum}
          onCancel={() => setOpenDeleteAlbum(false)}
          icon="error"
        />
        <AlbumClipContextMenu
          permissions={permissions}
          handleClose={handleClose}
          anchorEl={anchorEl}
          mediaIds={selectedItems}
          mediaList={galleryItems.map((g) => g.mediaDetails!)}
          refetch={albumRefetch}
          album={data?.data.album}
        />
        <PhotosVideoFilterModal
          open={openFilter}
          handleClose={(filters) => {
            setOpenFilter(false);
            setFilterState(filters);
          }}
          selectedFiterState={filterState}
          excludeFilters={["Videos/Photos"]}
          albumId={albumId}
        />
      </Form>
      <div ref={setLastElement} />
    </Container>
  );
};
