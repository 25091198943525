import React from "react";
import { Activity } from "../activities/Activity";
import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import { Form } from "@components/crud/Form";

export const AccountActivity = ({
  activities,
  onClickAdd,
  account
}: {
  activities;
  account: ModelAccount;
  onClickAdd: (activityType: string) => void;
}) => {
  return (
    <Form sx={{ padding: "0px !important" }}>
      <Activity
        activities={activities}
        onClickAdd={onClickAdd}
        hideRelatedTo
        hideFooter
        account={account}
        useOfflineTable
      />
    </Form>
  );
};
