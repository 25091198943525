import { Button } from "@components/Button";
import { AddIcon } from "@components/Icons";
import Grid from "@mui/material/Unstable_Grid2";

export const Footer = ({
  cancelBtnClick,
  cancelBtnLabel = "Cancel",
  saveBtnClick,
  saveBtnLabel = "Save",
  saveAndNewBtnClick,
  saveAndNewBtnLabel = "Save & New",
  additionalBtns,
  isDisabled,
  isLoading
}: {
  cancelBtnClick?: () => void;
  cancelBtnLabel?: string;
  saveBtnClick?: () => void;
  saveBtnLabel?: string;
  saveAndNewBtnClick?: () => void;
  saveAndNewBtnLabel?: string;
  additionalBtns?: React.ReactNode[];
  isDisabled?: boolean;
  isLoading?: boolean;
}) => (
  <Grid
    container
    className="footer"
    spacing={1}
    justifyContent={"flex-end"}
    alignItems={"center"}
    paddingY={2}
    paddingX={4}
    margin={0}
    sx={{
      borderTop: "1px solid #e2e8f0",
      backgroundColor: "#f8fafc",
      marginTop: "auto"
    }}
  >
    {cancelBtnClick && (
      <Grid>
        <Button
          variant="admin-secondary"
          type="button"
          onClick={cancelBtnClick}
        >
          {cancelBtnLabel}
        </Button>
      </Grid>
    )}
    {saveAndNewBtnClick && (
      <Grid data-testid="FOOTER_SAVE_NEW_BUTTON">
        <Button
          variant="admin-primary"
          type="button"
          onClick={saveAndNewBtnClick}
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
          startIcon={<AddIcon />}
        >
          {saveAndNewBtnLabel}
        </Button>
      </Grid>
    )}
    {saveBtnClick && (
      <Grid data-testid="FOOTER_SAVE_BUTTON">
        <Button
          variant="admin-primary"
          type="button"
          onClick={saveBtnClick}
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
        >
          {saveBtnLabel}
        </Button>
      </Grid>
    )}
    {additionalBtns?.map((btn, index) => (
      <Grid key={index} data-testid="FOOTER_ADDITIONAL_BUTTONS">
        {btn}
      </Grid>
    ))}
  </Grid>
);
