import { FormInput } from "@components/FormInput";
import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Document, Page } from "react-pdf";
import React, { useState, useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";

const StyledBox = styled(Box)`
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  max-width: 900px;
  margin: auto;
  @media (max-width: 576px) {
    border-radius: 0;
  }
`;

export const TaxExemptForm = ({
  file,
  setFile
}: {
  file: File | ModelMedia | undefined;
  setFile: (file: File | undefined) => void;
}) => {
  const { control } = useFormContext();
  const [fileUrl, setFileUrl] = useState<string | null>(
    file
      ? file instanceof File
        ? URL.createObjectURL(file)
        : (file as ModelMedia)?.baseUrl && (file as ModelMedia)?.path
          ? (file as ModelMedia)?.baseUrl + (file as ModelMedia)?.path
          : null
      : null
  );
  const [numPages, setNumPages] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  const onFileChange = (
    file: File | null,
    field: { onChange: (arg0: { target: { value: File | null } }) => void }
  ) => {
    if (!file) {
      setFile(undefined);
      setFileUrl(null);
      setNumPages(null);
      field.onChange({ target: { value: file } });
      return;
    }
    setFile(file);
    setFileUrl(URL.createObjectURL(file));
    field.onChange({ target: { value: file } });
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth - 50);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef, numPages]);

  return (
    <>
      {(!file || file instanceof File) && (
        <StyledBox
          style={{
            marginBottom: "20px"
          }}
        >
          <FormInput
            type="file"
            control={control}
            name="file"
            label="Signed Tax Exempt Form"
            required
            onChange={onFileChange}
            value={file}
            InputProps={{
              accept: ".pdf"
            }}
          />
          <Typography
            variant="body1"
            sx={{ color: "#B3B3B3", fontSize: "13px", marginTop: "10px" }}
          >
            Note: Your order was processed as Tax Exempt. If this is not correct
            please contact your SportsGravy sales associate.
          </Typography>
        </StyledBox>
      )}
      {fileUrl && (
        <StyledBox
          ref={containerRef}
          style={{
            maxHeight: "800px",
            overflow: "auto"
          }}
        >
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={(error) =>
              console.error("Error while loading document:", error)
            }
          >
            {Array.from({ length: numPages || 0 }, (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={containerWidth || 600}
              />
            ))}
          </Document>
        </StyledBox>
      )}
    </>
  );
};
