import { LoadingSpinner } from "@components/LoadingSpinner";
import { Button as MuiButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { BaseSyntheticEvent } from "react";

const StyledTypography = styled(Typography)`
  text-transform: none;
`;

export const Button = (props: {
  onClick:
    | undefined
    | (() => void)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>);
  type: "button" | "submit" | "reset" | undefined;
  children: React.ReactNode | string;
  variant: string | undefined;
  disabled: boolean | undefined;
  isLoading: boolean | undefined;
  startIcon?: React.ReactNode | undefined;
  endIcon?: React.ReactNode | undefined;
  style?: React.CSSProperties;
}) => {
  const className = !(
    props.variant === "primary" ||
    props.variant === "secondary" ||
    props.variant === "cancel" ||
    props.variant === "admin-primary" ||
    props.variant === "admin-secondary" ||
    props.variant === "admin-warning" ||
    props.variant === "admin-error" ||
    props.variant === "admin-success"
  )
    ? "primary"
    : props.variant;
  return (
    <MuiButton
      className={className}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      style={props.style}
    >
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        <StyledTypography variant="button">{props.children}</StyledTypography>
      )}
    </MuiButton>
  );
};

Button.defaultProps = {
  type: "button",
  variant: "primary",
  disabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  isLoading: false
};
