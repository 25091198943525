import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  inviteAtom,
  KidInvite,
  kidInviteAtom,
  passwordAtom,
  personAtom,
  signatureAtom
} from "@recoil/signup";
import { accountPost } from "@services/Network";
import Sentry from "@services/Sentry";
import {
  ModelInvite,
  ModelPerson,
  authSignUpDeclineTermsPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { TermsCopy } from "./TermsCopy";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
  display: flex;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const FullName = styled(Typography)`
  font-weight: bold;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TermsOfUse = () => {
  const person = useRecoilValue<ModelPerson | null>(personAtom);
  const password = useRecoilValue<string>(passwordAtom);
  const setSignature = useSetRecoilState<string>(signatureAtom);
  const kidInvite = useRecoilValue<KidInvite[]>(kidInviteAtom);
  const invite = useRecoilValue<ModelInvite | null>(inviteAtom);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      agreeToTerms: false,
      signature: ""
    }
  });

  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);

  const signature = watch("signature");
  const fullName = useMemo(() => {
    return `${person?.firstName?.trim()} ${person?.lastName?.trim()}`.trim();
  }, []);

  const confirmAccountMutation = accountPost();
  const acceptTerms = async (formValues) => {
    const { signature } = formValues;
    setSignature(signature.trim());

    if (person?.emailPrimary?.verifiedAt && person?.phonePrimary?.verifiedAt) {
      setIsLoading(true);
      await confirmAccountMutation.mutateAsync({
        data: {
          // @ts-ignore - this has to be done due to the dob needing to be only the date and not the time
          kids: kidInvite
            .filter((k) => k.selected)
            .filter(
              (k) =>
                !invite?.person?.children?.find(
                  (c) => c.personId === k.personId
                )?.person?.invitedByUser
            )
            .map((kid) => ({
              personId: kid.personId,
              email: kid.email,
              dob: kid.dob.split("T")[0],
              relationship: kid.relationship,
              consented: true
            })),
          password,
          signature: signature.trim()
        }
      });
      setIsLoading(false);
      navigate("/sign-up/subscription-plans");
    } else {
      navigate("/sign-up/verify-account");
    }
  };

  const declineTerms = async () => {
    setIsLoading(true);
    try {
      await authSignUpDeclineTermsPost();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
    setIsLoading(false);
    navigate("/");
  };

  return (
    <FormContainer data-testid="signup-termOfUse">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Terms of Use
      </Typography>
      <TermsCopy />
      <form onSubmit={handleSubmit(acceptTerms)}>
        <FormRow>
          <FormFieldContainer data-testid="signup-e-sign">
            <FormInput
              name="signature"
              type="text"
              required
              label={`Electronic Signature ( ${fullName} )`}
              control={control}
              rules={{
                required: "Electronic Signature is required",
                validate: {
                  matchesFullName: (value: string) => {
                    return (
                      value.trim() === fullName ||
                      "Electronic Signature must match your full name as displayed above"
                    );
                  }
                }
              }}
            />
            <FullName />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-acceptTerms">
            <FormCheckbox
              control={control}
              name="agreeToTerms"
              disabled={fullName !== signature.trim()}
              label="I have read, understand and agree to the Terms of Use above."
              rules={{
                required: true
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer>
          <FormFieldContainer sx={{ marginRight: "1.5rem" }}>
            <Button
              variant="cancel"
              type="button"
              onClick={() => setIsDeclineDialogOpen(true)}
            >
              Decline
            </Button>
          </FormFieldContainer>
          <FormFieldContainer>
            <Button
              variant="primary"
              disabled={!isValid || isLoading}
              type="submit"
              isLoading={isLoading}
            >
              Accept
            </Button>
          </FormFieldContainer>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Already have an account? <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
      <ConfirmationDialog
        open={isDeclineDialogOpen}
        title="Decline Terms of Use?"
        body="By clicking “Decline” below you will cancel the registration process and your new SportsGravy account will not be created."
        close={() => setIsDeclineDialogOpen(false)}
        onCancel={() => setIsDeclineDialogOpen(false)}
        onConfirm={declineTerms}
        cancelBtnText="Cancel"
        confirmBtnText="Decline"
      />
    </FormContainer>
  );
};
