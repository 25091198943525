import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import { StepProgressLine } from "@components/StepProgressLine";
import { FormProvider, useForm } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { AboutBuisness } from "./components/AboutBuisness";
import { AccountSetup } from "./components/AccountSetup";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { AddBankAccount } from "./components/AddBankAccount";
import { AboutOwner } from "./components/AboutOwner";
import {
  MerchantCreateRequest,
  useAdminMerchantGet,
  useAdminMerchantMerchantIdPatch
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilState, useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Loader } from "@components/crud/Loader";
import { convertStringToDate } from "./MerchantView";
import { getCountries } from "@services/Network";

const formatDateToYYYYMMDD = (date: Date, time?: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  if (time) {
    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}`;
  }
  return `${year}${month}${day}`;
};

const steps = [
  "About the Business",
  "About the Owner",
  "Account Setup",
  "Add Bank Account"
];

type Tabs =
  | "About the Business"
  | "About the Owner"
  | "Account Setup"
  | "Add Bank Account";

export const MerchantEdit = () => {
  const [tab, setTab] = useState<Tabs>("About the Business");
  const navigate = useNavigate();
  const activeStepNumber = steps.findIndex((step) => step === tab);
  const [organizations] = useRecoilState(organizationsAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const currOrg = organizations.find(
    (org) => org.organizationId === organizationId
  );

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const form = useForm({
    mode: "onTouched"
  });
  const {
    trigger,
    handleSubmit,
    formState: { isValid },
    reset
  } = form;

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1] as Tabs);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    navigate("/merchant-account");
    setIsConfirmationDialogOpen(false);
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1] as Tabs);
  };

  const { mutate: save, isLoading: isSaving } =
    useAdminMerchantMerchantIdPatch();

  const { data: merchant, isLoading: isLoading } = useAdminMerchantGet(
    currOrg?.merchantId as string
  );

  const { data: countries } = getCountries({
    staleTime: Infinity
  });

  const countryOptions: {
    value: string;
    label: string;
    altId: string;
  }[] = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId!,
        label: country.name!,
        altId: country.countryAltId!
      }));
    }
    return [];
  }, [countries]);

  useEffect(() => {
    if (merchant?.data) {
      const merchantDetails = merchant?.data;
      reset({
        type: merchantDetails.merchant?.entity?.type?.toString(),
        name: merchantDetails.merchant?.entity?.name,
        dba: merchantDetails.merchant?.dba,
        sdLabel: merchantDetails.merchant?.dba,
        customerPhone: merchantDetails.merchant?.entity?.customerPhone,
        established: convertStringToDate(
          merchantDetails.merchant?.established as string
        ),
        haveEmployees: true,
        globalBusinessId:
          "*****" + merchantDetails.merchant?.entity?.globalBusinessId,
        avgTicket: merchantDetails.merchant?.avgTicket?.toString(),
        acceptCreditCard:
          merchantDetails.merchant?.environment === "cardPresent",
        annualCCSales: merchantDetails.merchant?.annualCCSales?.toString(),
        phone: merchantDetails.merchant?.entity?.phone,
        fax: merchantDetails.merchant?.entity?.fax,
        email: merchantDetails.merchant?.entity?.email,
        website: merchantDetails.merchant?.entity?.website,
        country: countryOptions.find(
          (country) =>
            country.altId === merchantDetails.merchant?.entity?.country
        )?.value,
        address1: merchantDetails.merchant?.entity?.address1,
        address2: merchantDetails.merchant?.entity?.address2,
        merchantType: merchantDetails.merchant?.environment,
        city: merchantDetails.merchant?.entity?.city,
        state: merchantDetails.merchant?.entity?.state,
        zip: merchantDetails.merchant?.entity?.zip,
        ownerDetails: merchantDetails.merchant?.members?.map((owner) => ({
          id: owner.id,
          primary: owner.primary === 1,
          firstName: owner.first,
          lastName: owner.last,
          title: owner.title,
          dob: new Date(new Date().setFullYear(Number(owner.dob))),
          ownership: (owner?.ownership || 0) / 100,
          ssn: "***-**-" + owner.ssn,
          phone: owner.phone,
          email: owner.email,
          country: countryOptions.find(
            (country) => country.altId === owner.country
          )?.value,
          address1: owner.address1,
          address2: owner.address2,
          city: owner.city,
          state: owner.state,
          zip: owner.zip,
          significantResponsibility: owner.significantResponsibility === 1,
          politicallyExposed: owner.politicallyExposed === 1,
          dl: "****" + owner.dl,
          dlstate: owner.dlstate
        })),
        method: merchantDetails.merchant?.accounts?.account?.method,
        routing: merchantDetails.merchant?.accounts?.account?.routing,
        number: "*****" + merchantDetails.merchant?.accounts?.account?.number,
        mcc: merchantDetails.merchant?.mcc,
        status: merchantDetails.merchant?.status?.toString(),
        tnc: true,
        tcAttestation: true,
        tcVersion: merchantDetails.merchant?.entity?.tcVersion,
        tcAcceptDate: convertStringToDate(
          merchantDetails.merchant?.entity?.tcAcceptDate as string
        ),
        tcAcceptTime: convertStringToDate(
          merchantDetails.merchant?.entity?.tcAcceptDate as string
        ),
        tcAcceptIp: merchantDetails.merchant?.entity?.tcAcceptIp
      });
    }
  }, [merchant]);

  const onSaveClick = (values) => {
    const data: MerchantCreateRequest = {
      data: {
        name: values.name || values.dba,
        email: values.email,
        phone: values.phone,
        website: values.website,
        address1: values.address1,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country:
          values.country === "US" || values.country === "USA" ? "USA" : "CAN",
        type: parseInt(values.type),
        sdLabel: values.sdLabel,
        customerPhone: values.customerPhone,
        haveEmployees: values.haveEmployees ? 1 : 0,
        tcAttestation: 1,
        tcAcceptDate: formatDateToYYYYMMDD(
          values.tcAcceptDate,
          values.tcAcceptTime
        ),
        tcAcceptIp: values.tcAcceptIp,
        tcVersion: values.tcVersion,
        ...(values.fax && { fax: values.fax }),
        createLogin: false,
        orgEntities: [],
        publicToken: values.publicToken,
        accountToken: values.accountToken,
        public: values.public ? 1 : 0,
        accounts: [
          {
            primary: 1,
            id: values.publicToken
              ? undefined
              : merchant?.data?.merchant?.accounts?.account?.id,
            ...(values.publicToken && {
              publicToken: values.publicToken,
              accountToken: values.accountToken
            }),
            platform: "PLAID"
          }
        ],
        merchant: {
          dba: values.dba,
          established: formatDateToYYYYMMDD(values.established),
          avgTicket: values.avgTicket,
          annualCCSales: values.annualCCSales,
          mcc: values.mcc,
          ...(values.environment && { environment: values.environment }),
          new: 0,
          status: parseInt(values.status),
          members: values.ownerDetails.map((owner) => ({
            id: owner.id,
            primary:
              values.ownerDetails.length == 1 ? 1 : owner.primary ? 1 : 0,
            first: owner.firstName,
            last: owner.lastName,
            middle: owner.middleName,
            dob: new Date(owner.dob).getFullYear().toString(),
            ssn: owner.ssn.replace(/[*-]/g, ""),
            title: owner.title,
            dl: owner?.dl?.replace(/\*/g, ""),
            dlstate: owner.dlstate,
            significantResponsibility: owner.significantResponsibility ? 1 : 0,
            politicallyExposed: owner.politicallyExposed ? 1 : 0,
            email: owner.email,
            phone: owner.phone || undefined,
            ownership:
              values.ownerDetails.length == 1
                ? 10000
                : parseInt(owner.ownership) * 100,
            ...(owner.hasSameAddress
              ? {
                  address1: values.address1,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                  country: values.country
                }
              : {
                  address1: owner.address1,
                  city: owner.city,
                  state: owner.state,
                  zip: owner.zip,
                  country: owner.countryAltId
                })
          }))
        }
      },
      organizationId: organizationId
    };
    save(
      {
        merchantId: currOrg?.merchantId as string,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Merchant account edited successfully!", {
            variant: "success"
          });
          navigate("/merchant-account");
        },
        onError: (error) => {
          enqueueSnackbar(
            error.response?.data?.details || "Failed to add merchant account!",
            {
              variant: "error"
            }
          );
        }
      }
    );
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  return (
    <Container>
      <Toolbar title="Edit Merchant Account" />
      <Loader isLoading={isLoading}>
        <Form>
          <Grid xs={12}>
            <StepProgressLine
              steps={[
                "About the Business",
                "About the Owner",
                "Account Setup",
                "Add Bank Account"
              ]}
              activeStepNumber={activeStepNumber}
              onEditClick={(active) => {
                setTab(steps[active] as Tabs);
                setTimeout(() => {
                  trigger();
                }, 50);
              }}
            />
          </Grid>
          <Grid sx={{ marginTop: "32px" }}>
            <FormProvider {...form}>
              {tab === "About the Business" && (
                <AboutBuisness disabled={false} mode="EDIT" />
              )}
              {tab === "About the Owner" && (
                <AboutOwner disabled={false} mode="EDIT" />
              )}
              {tab === "Account Setup" && (
                <AccountSetup disabled={false} mode="EDIT" />
              )}
              {tab === "Add Bank Account" && (
                <AddBankAccount disabled={false} mode="EDIT" />
              )}
            </FormProvider>
          </Grid>
        </Form>
        <Footer
          additionalBtns={[
            activeStepNumber > 0 ? (
              <Button
                variant="admin-secondary"
                type="button"
                onClick={onBackClick}
              >
                Back
              </Button>
            ) : null,
            <Button
              key={1}
              variant="admin-secondary"
              type="button"
              onClick={onCancelClick}
            >
              Cancel
            </Button>,
            <Button
              key={2}
              variant="admin-primary"
              type="button"
              onClick={getPrimaryAction()}
              isLoading={isSaving}
              disabled={!isValid || isSaving}
            >
              {activeStepNumber < steps.length - 1 ? "Continue" : "Save"}
            </Button>
          ]}
        />
      </Loader>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
