import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  createCRMActivity,
  getOpportunities,
  getOrgOpportunities
} from "@services/Network";
import {
  ModelOpportunity,
  ModelPerson,
  useAdminCrmOpportunityGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/Language";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, styled, Typography } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import { VirtualMeetingIcon } from "@components/Icons";
import formatFullName from "@utils/formatFullName";
import {
  CRM_ACCOUNT_CATEGORIES,
  CRM_ACCOUNT_TYPES,
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES
} from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { FullName } from "@utils/types";
import { CreateMeetingModal } from "../components/CreateMeetingModal";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { websiteClick } from "@utils/openWebsite";
import { participant } from "../components/SearchParticipants";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const Opportunities = () => {
  dayjs.extend(timezone);
  const { setEmailTo, setContact } = useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();

  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const organizationId = useRecoilValue(organizationAtom);
  const onAdd = () => navigate("/crm/opportunities/create");
  const onEdit = (opportunity) =>
    navigate(`/crm/opportunities/${opportunity.opportunityId}/edit`);
  const onView = (opportunity) =>
    navigate(
      `/crm/opportunities/${opportunity.opportunityId}?tab=Opportunity Details`
    );
  const onAccountDashboardView = (opportunity) =>
    navigate(`${opportunity.opportunityId}`);

  const [showMeeting, setShowMeeting] = useState(false);
  const [selectedMeetingAccount, setSelectedMeetingAccount] = useState<
    string | undefined
  >(undefined);
  const [meetingAutoSuggestOptions, setMeetingAutoSuggestOptions] = useState<
    participant[] | undefined
  >();
  const ACCOUNTS_COLUMNS: GridColDef<ModelOpportunity>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: organizationId ? 225 : 325,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        const contactName = formatFullName(params.row.contact as FullName);
        const accountContact = params.row.contact?.accounts?.find(
          (acc) => acc.accountId === params.row.accountId
        );
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions.view && (
              <IconButton onClick={() => onAccountDashboardView(params.row)}>
                <ToolTip
                  title="Click for Opportunity Dashboard"
                  placement="top-start"
                >
                  <SpaceDashboardIcon style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Opportunity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton
                onClick={() => onEdit(params.row)}
                disabled={
                  params.row.stage === "CLOSED_WON" &&
                  (!!organizationId || !!params.row.orders?.length)
                }
              >
                <ToolTip title="Edit Opportunity" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {!organizationId && (
              <>
                <Loader isLoading={mutation.isLoading}>
                  <IconButton
                    disabled={!params.row.contact?.phone}
                    onClick={() => {
                      setContact({
                        contactId: params.row.contactId,
                        phone: params?.row?.contact?.phone,
                        email: params?.row?.contact?.email,
                        firstName: params?.row?.contact?.firstName,
                        lastName: params?.row.contact?.lastName,
                        location: params.row.contact?.location,
                        accounts: [
                          {
                            accountId: params.row.account?.accountId,
                            jobTitle:
                              params.row.contact?.accounts?.[0].jobTitle,
                            isPrimary:
                              params.row.contact?.accounts?.[0].isPrimary,
                            workPhone:
                              params.row.contact?.accounts?.[0].workPhone,
                            workEmail:
                              params.row.contact?.accounts?.[0].workEmail,
                            account: {
                              ...params.row.account
                            }
                          }
                        ]
                      });
                    }}
                  >
                    <ToolTip
                      title={`Click to Call ${contactName}`}
                      placement="top-start"
                    >
                      <CallIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                </Loader>
                {params.row.contact?.email || accountContact?.workEmail ? (
                  <IconButton
                    onClick={() => {
                      setShowMeeting(true);
                      setSelectedMeetingAccount(params.row.accountId);
                      setMeetingAutoSuggestOptions([
                        ...(accountContact?.workEmail
                          ? [
                              {
                                label: formatFullName(
                                  params.row.contact as ModelPerson
                                ),
                                text: accountContact?.workEmail,
                                value: params.row.contactId!,
                                isInternal: false,
                                emailDetails: {
                                  email: accountContact?.workEmail,
                                  emailInfo: `Work Email`,
                                  isPrimaryStar: accountContact?.isPrimary
                                }
                              }
                            ]
                          : []),
                        ...(params.row.contact?.email
                          ? [
                              {
                                label: formatFullName(
                                  params.row.contact as ModelPerson
                                ),
                                text: params.row.contact.email,
                                value: params.row.contactId!,
                                isInternal: false,
                                emailDetails: {
                                  email: params.row.contact.email,
                                  emailInfo: `Personal Email`,
                                  isPrimaryStar: accountContact?.isPrimary
                                }
                              }
                            ]
                          : [])
                      ]);
                    }}
                  >
                    <ToolTip
                      title={`Click to Connect with ${contactName}`}
                      placement="top-start"
                    >
                      <span style={{ marginTop: "10px" }}>
                        <VirtualMeetingIcon
                          style={{
                            width: "25px",
                            height: "25px"
                          }}
                        />
                      </span>
                    </ToolTip>
                  </IconButton>
                ) : (
                  <></>
                )}
                {params.row.contact?.email || accountContact?.workEmail ? (
                  <IconButton
                    onClick={() => {
                      setEmailTo({
                        to: params.row.contact?.email,
                        name: contactName,
                        relatesTo: "OPPORTUNITY",
                        opportunityId: params.row.opportunityId,
                        accountId: params.row.accountId,
                        accountName: params.row.account?.name,
                        autoSuggestOptions: [
                          ...(accountContact?.workEmail
                            ? [
                                {
                                  label: formatFullName(
                                    params.row.contact as ModelPerson
                                  ),
                                  text: accountContact?.workEmail,
                                  value: params.row.contactId!,
                                  isInternal: false,
                                  emailDetails: {
                                    email: accountContact?.workEmail,
                                    emailInfo: `Work Email`,
                                    isPrimaryStar: accountContact?.isPrimary
                                  }
                                }
                              ]
                            : []),
                          ...(params.row.contact?.email
                            ? [
                                {
                                  label: formatFullName(
                                    params.row.contact as ModelPerson
                                  ),
                                  text: params.row.contact.email,
                                  value: params.row.contactId!,
                                  isInternal: false,
                                  emailDetails: {
                                    email: params.row.contact.email,
                                    emailInfo: `Personal Email`,
                                    isPrimaryStar: accountContact?.isPrimary
                                  }
                                }
                              ]
                            : [])
                        ]
                      });
                    }}
                  >
                    <ToolTip
                      title={`Click to Email ${contactName}`}
                      placement="top-start"
                    >
                      <EmailIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                ) : (
                  <></>
                )}
              </>
            )}
            <IconButton
              onClick={() => websiteClick(params.row.account?.website)}
              disabled={!params.row.account?.website}
            >
              <ToolTip title="Click to Visit Website" placement="top">
                <WebsiteIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 250,
      flex: 1,
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      renderCell: (params) => <TwoLineText>{params.row.name}</TwoLineText>
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_TYPES.find((t) => t.value === row.type)?.label || ""
    },
    {
      headerName: "Estimated Close Date",
      field: "closeDate",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) =>
        row.closeDate ? formatDateForDisplay(row.closeDate) : "-"
    },
    {
      headerName: "Stage",
      field: "stage",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_STAGES.find((t) => t.value === row.stage)?.label || ""
    },
    ...(!organizationId
      ? [
          {
            headerName: "Amount",
            field: "amount",
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) => formatCurrency(params.value)
          }
        ]
      : []),
    {
      headerName: "Account",
      field: "account",
      minWidth: 175,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.account?.parentId) {
          const parent = params.row.account.parent;
          return (
            <ToolTip
              title={
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      navigate(`/crm/accounts/${parent?.accountId}`)
                    }
                  >
                    <Typography color="#007AFF">{parent?.name}</Typography>
                    <IconButton>
                      <LaunchIcon
                        style={{
                          color: "#007AFF",
                          height: "14px",
                          width: "14px"
                        }}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px"
                    }}
                  >
                    <Typography color="#64748B" variant="body2">
                      {`${
                        CRM_ACCOUNT_CATEGORIES.find(
                          (c) => c.value === parent?.category
                        )?.label
                      } • ${
                        CRM_ACCOUNT_TYPES.find((c) => c.value === parent?.type)
                          ?.label
                      }`}
                    </Typography>
                    <Typography color="#64748B" variant="body2">
                      {parent?.officeAddress}
                    </Typography>
                  </div>
                </div>
              }
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span>{params.row.account?.name}</span>
                <span>
                  <img src={GoverningBodyIcon} />
                </span>
              </div>
            </ToolTip>
          );
        }
        return <div>{params.row.account?.name}</div>;
      }
    },
    {
      headerName: "Contact",
      field: "contact",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.contact ? formatFullName(row.contact) : "",
      flex: 1
    },
    {
      headerName: "Opportunity Owner",
      field: "owner",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        row.owner?.person ? formatFullName(row.owner.person) : ""
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.opportunities", "ADD");
      const view = await checkPermission("crm.opportunities", "VIEW");
      const edit = await checkPermission("crm.opportunities", "EDIT");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const { data: opportunities } = useAdminCrmOpportunityGet({
    userOpportunities: true
  });

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "My Opportunities",
        value: "mine"
      },
      ...(opportunities?.data.numRows && opportunities?.data.numRows > 0
        ? [
            {
              label: "My Daily Plan",
              value: "daily_plan"
            }
          ]
        : [])
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Opportunities"
          useGet={organizationId ? getOrgOpportunities : getOpportunities}
          columns={ACCOUNTS_COLUMNS}
          getRowId={(row) => row.opportunityId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
        />
      </Loader>
      {showMeeting && (
        <CreateMeetingModal
          open={showMeeting}
          setOpen={setShowMeeting}
          selectedAccount={selectedMeetingAccount!}
          autoSuggestOptions={meetingAutoSuggestOptions}
        />
      )}
    </>
  );
};
