import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminHelpArticlePostPutRequest,
  ModelHelpCategory,
  ModelMedia,
  mediaGet,
  useAdminHelpArticleArticleIdGet,
  useAdminHelpArticleArticleIdPut,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { HelpArticleForm } from "./helpArticleForm";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { enqueueSnackbar } from "notistack";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";

const containsUrl = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  return urlRegex.test(str);
};

export const HelpArticleEdit = () => {
  const navigate = useNavigate();
  const { articleId } = useParams();
  const form = useForm({
    mode: "onTouched"
  });

  const {
    reset,
    formState: { isDirty, isValid },
    getValues
  } = form;

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useAdminHelpCategoryGet();

  const { data: data, isLoading: isLoading } = useAdminHelpArticleArticleIdGet(
    articleId!
  );

  const [categories, setCategories] = React.useState<ModelHelpCategory[]>([]);

  const { mutate: save, isLoading: isSaving } =
    useAdminHelpArticleArticleIdPut();
  const [isUploadingImage, setUploadingImage] = useState(false);
  const onSave = async () => {
    const content = getValues().content;
    if (getValues().hasMedia) {
      setUploadingImage(true);
      await Promise.all(
        content?.map(async (op, idx) => {
          if (op.insert && Object.keys(op.insert).includes("image")) {
            if (!containsUrl(op.insert.image)) {
              const file = base64ImageToFile(
                op.insert.image,
                getValues().headline + "_" + idx
              );
              const filesPromises = await Promise.all(
                [file].map((file) => {
                  if (file instanceof File) {
                    const promise = uploadMediaUsingPresignedUrl(file);
                    return promise;
                  } else {
                    return (file as ModelMedia).mediaId!;
                  }
                })
              );

              if (filesPromises) {
                const response = await mediaGet({
                  mediaIds: [filesPromises[0]]
                });
                if (
                  response &&
                  response.data.media &&
                  response.data.media.length
                ) {
                  const media = response.data.media[0];
                  if (media.media && media.media.baseUrl && media.media.path) {
                    let path = media.media.path;
                    if (media.media.state === "DRAFT") {
                      path = path.replace("original/", "");
                    }
                    const urlPath = media.media.baseUrl + path;
                    content[idx].insert.image = urlPath;
                  }
                }
              }
            }
          }
        })
      );

      setUploadingImage(false);
    }
    const values = {
      ...getValues(),
      content: content
    };
    save(
      {
        data: values as AdminHelpArticlePostPutRequest,
        articleId: articleId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Article edited successfully!", {
            variant: "success"
          });

          navigate(`/help-articles/${articleId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Article!", {
            variant: "error"
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (data?.data) {
      const article = data.data;
      reset({
        headline: article.headline,
        content: article.content,
        displayChannel: article.displayChannel,
        relatedTo: article.relatedTo,
        categoryId: article.categoryId,
        status: article.status
      });
    }
  }, [data]);
  React.useEffect(() => {
    if (categoriesData?.data) {
      setCategories(categoriesData.data.helpCategory!);
    }
  }, [categoriesData]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Container>
      <Toolbar title="Edit Help Articles" />
      <Loader isLoading={isLoading}>
        <FormProvider {...form}>
          <HelpArticleForm
            categories={categories}
            isLoadingCategories={isLoadingCategories}
          />
        </FormProvider>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isDirty || !isValid || isSaving || isUploadingImage}
        isLoading={isLoading || isSaving || isUploadingImage}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/help-articles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
