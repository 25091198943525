import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelFeature,
  ModelFeatureBuild,
  ModelPerson,
  useAdminBuildBuildIdGet,
  useAdminBuildIdPut,
  useAdminDirectoryGet,
  useAdminFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { Backdrop, Typography } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import { FeatureBar } from "@pages/releases/FeatureBar";
import { LoadingSpinner } from "@components/LoadingSpinner";

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Mobile", value: "MOB" },
  { label: "Web", value: "WEB" }
];

const renderTree = (
  control,
  testerOptions,
  allFeaturesSelected,
  node,
  level,
  selectedPlatform,
  setValue,
  getValues
) => {
  return (
    <div key={node.featureId}>
      <FeatureBar
        key={node.featureId}
        control={control}
        companyDirectory={testerOptions}
        level={level}
        node={node}
        allChecked={allFeaturesSelected}
        showPlatforms={selectedPlatform && [selectedPlatform]}
        setValue={setValue}
        getValues={getValues}
      />
      {node.children &&
        node.children.length > 0 &&
        node.children.map((child) =>
          renderTree(
            control,
            testerOptions,
            allFeaturesSelected,
            child,
            level + 1,
            selectedPlatform,
            setValue,
            getValues
          )
        )}
    </div>
  );
};

export const BuildEdit = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { buildId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState("all");
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [options, setOptions] = useState<ModelFeature[]>([]);
  const [allFeaturesSelected, setAllFeaturesSelected] = useState(false);
  const [allFeaturesIds, setAllFeaturesIds] = useState<string[]>([]);
  const [renderFeature, setRenderFeature] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<undefined | string>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      filter: "all",
      name: "",
      description: "",
      assignAllFeaturesTo: undefined,
      allFeatures: false
    }
  });
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: filter,
    endToEnd: true,
    sortField: "name",
    sortDirection: "asc",
    pageSize: 100
  });
  const { data: companyDirectory, isLoading: companyDirectoryLoading } =
    useAdminDirectoryGet({
      pageSize: 1000
    });

  const { data: build, isLoading: buildLoading } = useAdminBuildBuildIdGet(
    buildId!
  );
  useEffect(() => {
    if (build?.data) {
      const defaultValues = {
        buildNumber: build.data.buildNumber,
        description: build.data.description || "",
        platform: build.data.platform
      };
      setSelectedPlatform(build.data.platform);
      build.data.features!.map((feature: ModelFeatureBuild) => {
        defaultValues[feature.featureId!] = true;
        if (feature.iosTesterId)
          defaultValues[feature.featureId + ".iosTester"] = feature.iosTesterId;
        if (feature.andTesterId)
          defaultValues[feature.featureId + ".andTester"] = feature.andTesterId;
        if (feature.webTesterId)
          defaultValues[feature.featureId + ".webTester"] = feature.webTesterId;
      });
      reset(defaultValues);
      setRenderFeature(true);
    }
  }, [build]);
  const testerOptions = useMemo(
    () =>
      (companyDirectory &&
        companyDirectory.data.persons &&
        companyDirectory.data.persons
          .map((person: ModelPerson) => {
            if (person?.sportsgravyUser) {
              return {
                label: person.firstName + " " + person.lastName,
                value: person.personId
              };
            }
            return null;
          })
          .filter(Boolean)) ||
      [],
    [companyDirectory?.data]
  );
  const getAllFeatureIds = (features) => {
    const result: string[] = [];

    const traverse = (feature: ModelFeature) => {
      result.push(feature.featureId as string);
      if (feature.children) feature.children.forEach(traverse);
    };

    features.forEach(traverse);
    return result;
  };
  useEffect(() => {
    if (data?.data?.features) {
      setOptions(data.data.features);
      setAllFeaturesIds(getAllFeatureIds(data.data.features));
    }
  }, [data]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const create = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "ADD"
    );
    create.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);

  const { mutate: save, isLoading: isSaving } = useAdminBuildIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      const data = {
        buildNumber: values.buildNumber,
        description: values.description,
        platform: values.platform,
        features: (
          Object.entries(values) as [
            string,
            { iosTester?: string; andTester?: string; webTester?: string }
          ][]
        )
          .filter(
            ([key, value]) =>
              key !== "allFeatures" &&
              typeof value === "object" &&
              value !== null
          )
          .map(([featureId, testers]) => ({
            featureId,
            ...(testers &&
              testers.iosTester &&
              selectedPlatform === "IOS" && { iosTesterId: testers.iosTester }),
            ...(testers &&
              testers.andTester &&
              selectedPlatform === "AND" && { andTesterId: testers.andTester }),
            ...(testers &&
              testers.webTester &&
              selectedPlatform === "WEB" && { webTesterId: testers.webTester })
          }))
      };
      const existingFeaturesMap: Map<string, ModelFeatureBuild> = new Map(
        build?.data?.features?.map((f) => [f.featureId, f])
      );
      const addedFeatures: {
        webTesterId?: string | undefined;
        andTesterId?: string | undefined;
        iosTesterId?: string | undefined;
        featureId: string;
      }[] = [];
      const deletedFeatures: ModelFeatureBuild[] = [];
      const editedFeatures: {
        webTesterId?: string | undefined;
        andTesterId?: string | undefined;
        iosTesterId?: string | undefined;
        featureId: string;
      }[] = [];
      for (const feature of data.features) {
        if (!existingFeaturesMap.has(feature.featureId)) {
          addedFeatures.push(feature);
        } else {
          const existingFeature: ModelFeatureBuild | undefined =
            existingFeaturesMap.get(feature.featureId);
          if (
            existingFeature &&
            (feature.iosTesterId != existingFeature.iosTesterId ||
              feature.andTesterId != existingFeature.andTesterId ||
              feature.webTesterId != existingFeature.webTesterId)
          ) {
            editedFeatures.push(feature);
          }
          existingFeaturesMap.delete(feature.featureId);
        }
      }
      deletedFeatures.push(...existingFeaturesMap.values());
      const processedData = {
        buildNumber: data["buildNumber"],
        description: data["description"],
        platform: data["platform"]
      };
      processedData["featuresToAdd"] = addedFeatures;
      processedData["featuresToRemove"] = deletedFeatures;
      processedData["featuresToEdit"] = editedFeatures;
      try {
        save(
          {
            buildId: buildId!,
            data: processedData
          },
          {
            onSuccess: () => {
              setSaveBtnDisabled(false);
              enqueueSnackbar("Build edited successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                navigate(`/builds/${buildId}`);
              }
            },
            onError: () => {
              setSaveBtnDisabled(false);
              enqueueSnackbar("Failed to edit build!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to edit build!", {
          variant: "error"
        });
      }
    };

  const renderedTree = useMemo(() => {
    return (
      <Grid sx={{ padding: "0px 0px" }} xs={6}>
        <Loader isLoading={isLoading || companyDirectoryLoading}>
          {testerOptions &&
            options.map((feature) =>
              renderTree(
                control,
                testerOptions,
                allFeaturesSelected,
                feature,
                0,
                selectedPlatform,
                setValue,
                getValues
              )
            )}
        </Loader>
      </Grid>
    );
  }, [
    isLoading,
    testerOptions,
    options,
    allFeaturesSelected,
    selectedPlatform
  ]);

  const handleAllFeatureSelect = (e) => {
    setLoading(true);
    setTimeout(() => {
      setAllFeaturesSelected(e.target.checked);
      const isChecked = e.target.checked;
      allFeaturesIds.forEach((id) => {
        //@ts-ignore
        setValue(id, isChecked);
        if (isChecked && selectedPlatform)
          [selectedPlatform].forEach((platform) => {
            setValue(
              `${id}.${platform.toLocaleLowerCase()}Tester`,
              getValues("assignAllFeaturesTo")
            );
          });
      });
    }, 1000);
  };

  useEffect(() => {
    setLoading(false);
  }, [renderedTree]);

  return (
    <Container>
      <Backdrop
        sx={{
          overflow: "hidden",
          overflowY: "none",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <LoadingSpinner />
      </Backdrop>
      <Toolbar title="Edit Build" />
      <Loader isLoading={buildLoading}>
        <Form sx={{ paddingBottom: "0 !important" }}>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <FormInput
                control={control}
                name="buildNumber"
                type="text"
                label="Build Number"
                required={true}
                rules={{
                  required: "Build Number is required"
                }}
              />
            </Grid>

            <Grid xs={6}>
              <FormSelect
                control={control}
                name="platform"
                label="Platform"
                onChange={(e) => setSelectedPlatform(e.target.value)}
                options={[
                  {
                    label: "iOS",
                    value: "IOS"
                  },
                  {
                    label: "AND",
                    value: "AND"
                  },
                  {
                    label: "WEB",
                    value: "WEB"
                  }
                ]}
              />
            </Grid>
            <Grid xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="assignAllFeaturesTo"
                label="Assign all Features to"
                options={testerOptions}
                onChange={(e) => {
                  const value = e.target.value;
                  const data = getValues();
                  const filteredData = Object.fromEntries(
                    Object.entries(data).filter(
                      ([key, value]) =>
                        (typeof value === "object" ||
                          typeof value === "boolean") &&
                        key !== "allFeatures"
                    )
                  );
                  if (selectedPlatform)
                    Object.keys(filteredData).forEach((key) => {
                      [selectedPlatform].forEach((platform) => {
                        setValue(
                          `${key}.${platform?.toLocaleLowerCase()}Tester`,
                          value
                        );
                      });
                    });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              background: "#f3f4f7",
              marginTop: "24px",
              padding: "12px 24px",
              margin: "24px -24px 0px -24px"
            }}
          >
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "50px" }}>
                <FormCheckbox
                  control={control}
                  name="allFeatures"
                  onChange={(e) => {
                    setAllFeaturesSelected(e.target.checked);
                    handleAllFeatureSelect(e);
                  }}
                />
              </div>
              <Typography
                style={{
                  marginLeft: "20px",
                  marginRight: "24px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Features
              </Typography>
              <FormSelect
                name="filter"
                options={filterOptions}
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "200px", background: "#fff" }}
              />
            </Grid>
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Platforms / Testers
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ padding: "0px 24px" }} xs={6}>
            <Loader isLoading={isLoading || buildLoading}>
              {renderFeature && renderedTree}
            </Loader>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          setSaveBtnDisabled(true);
          handleSubmit(saveHandler(false))();
        }}
        isDisabled={!isValid || !isDirty || isSaving || saveBtnDisabled}
        isLoading={isSaving || saveBtnDisabled}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/builds")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
