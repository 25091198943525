import { styled } from "@mui/material";

export const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  width: "850px",
  backgroundColor: "white",
  borderRadius: 16,
  paddingBottom: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

export const Header = styled("div")({
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

export const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});
