/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { Control } from "react-hook-form";

export const FeatureBar = ({
  control,
  companyDirectory,
  node,
  level,
  allChecked,
  disabled,
  showPlatforms = ["WEB", "IOS", "AND"],
  setValue,
  getValues
}: {
  control: Control<any, any>;
  companyDirectory: any;
  node: any;
  level: number;
  allChecked?: boolean;
  disabled?: boolean;
  showPlatforms?: string[];
  setValue?: any;
  getValues?: any;
}) => {
  const [featureChecked, setFeatureChecked] = useState(allChecked || false);
  return (
    <div key={node.featureId}>
      <Grid
        container
        sx={{
          borderBottom: "1px solid #E2E8F0",
          padding: "8px 0",
          alignItems: "center",
          height: "70px"
        }}
      >
        <Grid xs={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: `${15 * level}px`
            }}
          >
            <FormCheckbox
              labelVariant="normal"
              label={node.name}
              control={control}
              name={node.featureId}
              disabled={disabled}
              onChange={(e) => {
                setFeatureChecked(!featureChecked);
                if (getValues("assignAllFeaturesTo") && setValue) {
                  if (e.target.checked)
                    showPlatforms.forEach((platform) => {
                      setValue(
                        `${node.featureId}.${platform.toLocaleLowerCase()}Tester`,
                        getValues("assignAllFeaturesTo")
                      );
                    });
                  else setValue(`${node.featureId}`, false);
                }
              }}
            />
          </div>
        </Grid>
        <Grid xs={6}>
          <Grid container spacing={2}>
            {["WEB", "IOS", "AND"].map((platform) => {
              return (
                <Grid
                  key={`${node.featureId}.${platform}`}
                  xs={4}
                  sx={{ width: "33%" }}
                >
                  {node.platforms.includes(platform) &&
                    showPlatforms.includes(platform) && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "1", marginRight: "10px" }}>
                          {platform}
                        </div>
                        {control._formValues[node.featureId] && (
                          <FormSelect
                            control={control}
                            disabled={disabled}
                            name={`${
                              node.featureId
                            }.${platform.toLocaleLowerCase()}Tester`}
                            options={companyDirectory}
                            rules={{ required: "Tester is required" }}
                          />
                        )}
                      </div>
                    )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
