import { Footer } from "@components/crud/Footer";
import { Loader } from "@components/crud/Loader";
import { FormInput } from "@components/FormInput";
import { CloseIcon } from "@components/Icons";
import { Header, HeaderText, MainContainer } from "@components/ModalComponents";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom } from "@recoil/auth";
import {
  useAdminAdvertiserIdGet,
  useAdminCrmOrgContactContactIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { EMAIL_REGEX } from "@utils/validation";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { SelectedAccountContact } from "./FSOAdvertiserForm";

export const SendInvoiceModal = ({
  onClose,
  onSave,
  title,
  advertiserId,
  type,
  contactSelected,
  isLoading
}: {
  onClose: () => void;
  onSave: ({
    contactId,
    email
  }: {
    contactId?: string;
    email?: string;
  }) => void;
  title: string;
  advertiserId?: string;
  type: "SEND" | "RESEND";
  contactSelected?: {
    label?: string;
    value: string;
    email?: string;
    contact?: SelectedAccountContact | undefined;
  };
  isLoading?: boolean;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const {
    control,
    setValue,
    formState: { isValid },
    getValues
  } = useFormContext();
  const [selectedContact, setSelectedContact] = useState<
    | {
        label?: string;
        value: string;
        email?: string;
        contact?: SelectedAccountContact | undefined;
      }
    | undefined
  >(contactSelected);
  const [dirtyEmail, setDirtyEmail] = useState(false);

  const { data: advertiser, isLoading: advertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!, {
      query: {
        queryKey: [advertiserId],
        enabled: type === "RESEND"
      }
    });

  const { mutate: contactUpdate, isLoading: contactUpdating } =
    useAdminCrmOrgContactContactIdPut();

  const saveHandler = async () => {
    const values = getValues();
    contactUpdate(
      {
        contactId: values.contactId,
        data: {
          email: selectedContact?.contact?.personalEmail,
          firstName: selectedContact?.contact?.firstName as string,
          lastName: selectedContact?.contact?.lastName as string,
          organizationId: organizationId!,
          accounts: [
            {
              jobTitle: selectedContact?.contact?.jobTitle as string,
              accountId: selectedContact?.contact?.accountId as string,
              workEmail: values.workEmail
            }
          ]
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Contact updated successfully", {
            variant: "success"
          });
          onSave({ contactId: values.contactId });
        }
      }
    );
  };

  const contactOptions: {
    label: string;
    value: string;
    email: string | undefined;
  }[] = useMemo(
    () =>
      advertiser?.data?.account?.contacts?.map((contact) => ({
        label: `${contact?.contact?.firstName} ${contact?.contact?.lastName}`,
        value: contact?.contactId as string,
        contact: contact,
        email: contact?.workEmail
      })) || [],
    [advertiser]
  );

  return (
    <Modal open>
      <MainContainer style={{ paddingBottom: 0 }}>
        <Header>
          <HeaderText style={{ color: "#fff" }}>{title}</HeaderText>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <div style={{ padding: "24px", minHeight: "100px" }}>
          <Loader isLoading={advertiserLoading && type === "RESEND"}>
            <Grid container spacing={3}>
              {type === "RESEND" && (
                <Grid xs={12}>
                  <StyledFormLabel required style={{ fontWeight: 600 }}>
                    Contact
                  </StyledFormLabel>
                  <Controller
                    control={control}
                    name="contactId"
                    rules={{
                      required: "Contact is required"
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <Autocomplete
                          value={
                            contactOptions.find(
                              (option) =>
                                option.value === selectedContact?.value
                            ) || null
                          }
                          clearOnBlur
                          id="tags-outlined"
                          isOptionEqualToValue={(option, value) =>
                            option.value === value?.value
                          }
                          getOptionLabel={(option) => option?.label || ""}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Typography>{option.label}</Typography>
                            </li>
                          )}
                          filterSelectedOptions
                          renderInput={(params) => {
                            if (
                              Array.isArray(params.InputProps.startAdornment)
                            ) {
                              params.InputProps.startAdornment.splice(
                                0,
                                0,
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              );
                            } else {
                              params.InputProps.startAdornment = (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              );
                            }
                            return <TextField {...params} />;
                          }}
                          noOptionsText="No contacts found"
                          onChange={(e, value) => {
                            field.onChange(value?.value);
                            setValue("workEmail", value?.email || "");
                            setSelectedContact(value || undefined);
                            setDirtyEmail(false);
                          }}
                          onBlur={field.onBlur}
                          options={contactOptions}
                        />
                        {!!fieldState.error && (
                          <FormHelperText error>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />

                  <FormHelperText style={{ color: "#B3B3B3" }}>
                    Note: This contact will be responsible for receiving and
                    paying the invoice related to this campaign.
                  </FormHelperText>
                </Grid>
              )}
              <Grid xs={12}>
                <FormInput
                  control={control}
                  name="workEmail"
                  type="text"
                  label={`Send order to ${selectedContact?.label} Work Email `}
                  required
                  disabled={!!selectedContact?.email?.length}
                  rules={{
                    required: "Email is required",
                    validate: (value) =>
                      EMAIL_REGEX.test(value) || "Invalid email address"
                  }}
                  onChange={(e) => {
                    const value = (e as ChangeEvent<HTMLInputElement>).target
                      .value;
                    setDirtyEmail(value !== selectedContact?.email);
                  }}
                />
              </Grid>
            </Grid>
          </Loader>
        </div>
        <Footer
          cancelBtnClick={onClose}
          saveBtnClick={() => {
            if (dirtyEmail && type === "SEND") saveHandler();
            else
              onSave({
                contactId: getValues("contactId"),
                email: getValues("workEmail")
              });
          }}
          isLoading={contactUpdating || isLoading}
          isDisabled={!isValid || contactUpdating || isLoading}
          saveBtnLabel={`${dirtyEmail ? "Update Contact &" : ""} ${type === "RESEND" && !dirtyEmail ? "Re-Send" : "Send"} Invoice`}
        />
      </MainContainer>
    </Modal>
  );
};
