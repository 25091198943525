import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { hasPermission } from "@services/Casbin";
import {
  ModelFaq,
  useAdminFaqFaqIdDelete,
  useAdminFaqFaqIdGet,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import {
  DISPLAY_CHANNEL_OPTIONS,
  FAQ_STATUS,
  RELATED_TO_OPTIONS
} from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const FaqView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelFaq | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const { faqId } = useParams();
  const { control, reset } = useForm({
    mode: "onTouched"
  });

  const { data: faq, isLoading: faqLoading } = useAdminFaqFaqIdGet(faqId!);
  const { data: categories, isLoading: categoriesLoading } =
    useAdminHelpCategoryGet();
  const categoriesOptions = categories?.data.helpCategory?.map((category) => ({
    label: category.name!,
    value: category.helpCategoryId!
  }));
  useEffect(() => {
    if (faq?.data) {
      reset({
        question: faq.data.question,
        answer: faq.data.answer,
        categoryId: faq.data.categoryId,
        relatedTo: faq.data.relatedTo,
        displayChannel: faq.data.displayChannel,
        status: faq.data.status
      });
    }
  }, [faq]);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminFaqFaqIdDelete();
  const onConfirmDelete = async () => {
    if (!toDelete?.faqId) return;
    try {
      await deleteAsync({ faqId: toDelete.faqId });
      enqueueSnackbar("Faq deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/faqs");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete the faq", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("support.faqs", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View FAQ"
        backBtnClick={() => navigate("/faqs")}
        editBtnClick={() => navigate(`/faqs/${faqId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(faq!.data)
        })}
      />
      <Loader isLoading={faqLoading}>
        <Form>
          <Grid data-testid="faq-view-form" container spacing={3}>
            <Grid data-testid="faq-question" xs={12}>
              <FormInput
                control={control}
                name="question"
                type="text"
                label="Question"
                disabled
                required={true}
                rules={{
                  required: "Question is required"
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: "24px" }}>
            <Grid data-testid="faq-answer" xs={12} md={12}>
              <FormInput
                control={control}
                name="answer"
                type="text"
                label="Answer"
                disabled
                multiline
                rows={4}
                required={true}
                rules={{
                  required: "Answer is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-category" xs={12} md={6}>
              <FormSelect
                options={categoriesOptions || []}
                isLoading={categoriesLoading}
                control={control}
                name="categoryId"
                disabled
                label="Category"
                required={true}
                rules={{
                  required: "Category is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-relatedTo" xs={12} md={6}>
              <FormSelect
                options={RELATED_TO_OPTIONS}
                control={control}
                name="relatedTo"
                disabled
                label="Related To"
                required={true}
                rules={{
                  required: "Related To is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-displayChannel" xs={12} md={6}>
              <FormMultiSelect
                options={DISPLAY_CHANNEL_OPTIONS}
                control={control}
                value="displayChannel"
                name="displayChannel"
                label="Display Channel"
                disabled
                required={true}
                rules={{
                  required: "Display Channels To is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormSelect
                options={FAQ_STATUS}
                control={control}
                name="status"
                label="Status"
                disabled
              />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Faq"
        body={`Are you sure you want to delete ${toDelete?.question}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
