/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button as MuiButton,
  Typography,
  Divider,
  Popover,
  styled,
  Container
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Loader } from "@components/crud/Loader";
import { BarChart } from "@mui/x-charts/BarChart";
import { capitalize } from "@utils/capitalize";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { FSOFilter } from "./FSOFilter";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { TimeFilter } from "./TimeFilter";

const StyledOption = styled("option")({
  fontSize: "13px",
  padding: "5px 10px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#E5E5E5"
  }
});
export const CustomBarChart = ({
  title,
  description,
  releaseId,
  platform,
  testerId,
  featureId,
  useGet,
  setFeatureBreakDown,
  chartId,
  hasFilter,
  defaultTimeFilter
}: {
  title: string;
  description: string;
  isLoading?: boolean;
  releaseId?: string;
  platform?: string;
  testerId?: string;
  featureId?: string;
  useGet: (params, options) => any;
  setFeatureBreakDown?: (value) => void;
  chartId?: string;
  hasFilter?: boolean;
  defaultTimeFilter?: string | undefined;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [chartData, setChartData] = useState<any>(undefined);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const query = React.useMemo(() => {
    const buildQuery = {} as {
      releaseId?: string;
      testerId?: string;
      platform?: string;
      parentId?: string;
      dtStart?: string;
      dtEnd?: string;
      keys?: any;
      organizationId?: string;
    };

    if (chartId !== "") {
      buildQuery.keys = [{ key: chartId }];
    }
    if (organizationId) {
      buildQuery.organizationId = organizationId;
    }

    if (selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.keys[0].dtStart = selectedTimeRange[0];
      buildQuery.keys[0].dtEnd = selectedTimeRange[1];
    }
    return buildQuery;
  }, [
    releaseId,
    testerId,
    platform,
    featureId,
    selectedTimeRange,
    chartId,
    organizationId
  ]);
  const { isFetching, data } = useGet(
    {
      keys: JSON.stringify(query.keys),
      ...(query.organizationId && { organizationId: query.organizationId })
    },
    {
      query: {
        queryKey: [
          releaseId,
          testerId,
          platform,
          featureId,
          refreshKey,
          chartId,
          organizationId
        ],
        refetchOnWindowFocus: false
      }
    }
  );

  const calculateChartHeight = (yAxisLength, dataKeyLength) => {
    const baseHeight = dataKeyLength == 1 ? 100 : 150;
    const totalHeight =
      baseHeight +
      (30 + dataKeyLength) * yAxisLength * dataKeyLength +
      10 * yAxisLength;

    return totalHeight;
  };
  useEffect(() => {
    if (data) {
      if (data.featureBreakdown && setFeatureBreakDown)
        setFeatureBreakDown(data.featureBreakdown);
      if (hasFilter && data[0]?.data?.[0]?.filters.length > 0)
        setSelectedFilter(data[0]?.data?.[0]?.filters[0]);
      if (!hasFilter || data[0]?.data?.[0]?.filters.length === 0)
        setChartData(data?.[0]?.data?.[0]?.value);
    }
  }, [data]);
  useEffect(() => {
    if (
      selectedFilter != "" &&
      data?.[0]?.data?.[0]?.filterValues[selectedFilter]
    )
      setChartData(data?.[0]?.data?.[0]?.filterValues[selectedFilter]);
  }, [selectedFilter]);

  const splitTextIntoLines = (text, maxWidth) => {
    const words = text.split(" ");
    const lines = [""];
    let currentLine = 0;

    for (let i = 0; i < words.length; i++) {
      const testLine =
        lines[currentLine] + (lines[currentLine] === "" ? "" : " ") + words[i];
      const testWidth = testLine.length * 10;
      if (testWidth > maxWidth) {
        lines.push(words[i]);
        currentLine++;
      } else {
        lines[currentLine] = testLine;
      }
    }

    return lines;
  };

  const generateDataKey = (data) => {
    if (!data) return [];
    const keys = Object.keys(data);
    return keys
      .filter((k) => k != "date")
      .map((k) => {
        return {
          dataKey: k,
          ...(keys.length > 1 && { label: capitalize(k) })
        };
      });
  };

  const generateYaxisLabel = (data) => {
    if (!data) return [];
    const keys = Object.keys(data).filter((k) => k !== "date");
    return Object.keys(data[keys[0]]);
  };

  const generateDataSet = (data) => {
    if (!data) return [];
    const dataSet: Array<Record<string, any>> = [];
    const keys = Object.keys(data).filter((k) => k !== "date");
    const keys1 = Object.keys(data[keys[0]]);
    keys1.map((k) => {
      const obj = {};
      keys.map((k1) => {
        obj[k1] = data[k1][k];
      });
      dataSet.push(obj);
    });
    if (dataSet.length === 0) setNoDataFound(true);
    return dataSet;
  };

  const renderXAxisLabel = (value) => {
    if (value >= 1000) {
      return `${value / 1000}k`;
    }
    return value;
  };

  window.addEventListener("scroll", handleClose);

  return (
    <Grid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid #bfbfbf",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "32px",
        minHeight: "300px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          {!organizationId &&
            hasFilter &&
            data[0]?.data?.[0]?.filters.length > 0 && (
              <MuiButton
                endIcon={
                  anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
                variant="outlined"
                style={{
                  color: "#64748B",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "none",
                  marginRight: "16px"
                }}
                onClick={(event) => {
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
              >
                {selectedFilter}
              </MuiButton>
            )}
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            defaultTimeFilter={defaultTimeFilter}
          />
        </Grid>
      </Grid>
      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isFetching}>
        {chartData && data[0]?.data?.[0]?.value && !noDataFound && (
          <>
            <BarChart
              skipAnimation
              dataset={generateDataSet(chartData)}
              xAxis={[
                {
                  classes: {
                    tickLabel: "xAxisTickLabel"
                  }
                }
              ]}
              yAxis={[
                {
                  scaleType: "band",
                  data: generateYaxisLabel(chartData),
                  tickPlacement: "middle",
                  tickLabelPlacement: "middle",
                  classes: {
                    tickLabel: "yAxisTickLabel"
                  },
                  valueFormatter: (value, context) => {
                    if (context.location == "tooltip") {
                      if (value.startsWith("https")) {
                        const sportName = value.split(" ")[1] || "";
                        return (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                width="20px"
                                src={value.split(" ")[0] || ""}
                                alt={sportName}
                              />
                              <span style={{ marginLeft: "12px" }}>
                                {sportName}
                              </span>
                            </div>
                          </>
                        );
                      }
                      return value;
                    }
                  }
                }
              ]}
              series={generateDataKey(chartData)}
              colors={["#1ABC9C", "#095A4A", "#FF9F0A", "#0270E8", "#A11212"]}
              layout="horizontal"
              margin={{
                top: 50,
                left: 170,
                bottom: !generateDataKey(chartData)[0]?.label ? 50 : 100
              }}
              height={calculateChartHeight(
                generateYaxisLabel(chartData).length,
                generateDataKey(chartData).length
              )}
              slots={{
                axisTickLabel(props) {
                  if (props.text.startsWith("https")) {
                    return (
                      <>
                        <text x={props.x} fontWeight="400" textAnchor="middle">
                          <tspan
                            x={props.x - 80}
                            y={Number(props.y) - 10}
                            dy="1.2em"
                          >
                            {props.text.split(" ")[1]}
                          </tspan>
                        </text>
                        <image
                          href={props.text.split(" ")[0] || ""}
                          x={Number(props.x) - 30}
                          y={Number(props.y) - 15}
                          width="30"
                          height="30"
                        />
                      </>
                    );
                  }
                  if (props.className?.includes("yAxisTickLabel")) {
                    const lines = splitTextIntoLines(props.text, 200);
                    return (
                      <text fontWeight="400" textAnchor="end">
                        <tspan dy="0.3em" x={props.x}>
                          {lines[0]}
                        </tspan>
                        {lines[1] && (
                          <tspan x={props.x} dy="1.2em">
                            {lines[1]}
                          </tspan>
                        )}
                      </text>
                    );
                  }
                  if (props.className?.includes("xAxisTickLabel ")) {
                    const value = Number(props.text.replace(/,/g, ""));
                    return (
                      <text
                        x={props.x}
                        y={props.y}
                        fontWeight="400"
                        textAnchor="middle"
                      >
                        <tspan x={props.x} dy="1.2em">
                          {renderXAxisLabel(value)}
                        </tspan>
                      </text>
                    );
                  }
                }
              }}
              slotProps={{
                legend: {
                  direction: "row",
                  position: { vertical: "bottom", horizontal: "middle" },
                  padding: 20
                },
                bar: {
                  style: {
                    height: 30
                  }
                }
              }}
            />
            {organizationId && hasFilter && (
              <FSOFilter data={data} setChartData={setChartData} />
            )}
          </>
        )}
        {(!chartData || !data[0]?.data?.[0]?.value || noDataFound) && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        )}
      </Loader>
      {hasFilter && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Container style={{ padding: 0 }}>
            {data[0]?.data?.[0]?.filters.map((option) => (
              <StyledOption
                onClick={(e) => {
                  //@ts-ignore
                  setSelectedFilter(e.target.value);
                  setAnchorEl(null);
                }}
                key={option}
                value={option}
              >
                {option}
              </StyledOption>
            ))}
          </Container>
        </Popover>
      )}
    </Grid>
  );
};
