import { Button } from "@components/Button";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  IconButton,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { formatCurrency } from "@utils/formatCurrency";
import React, { useEffect, useState } from "react";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import {
  AdvertiserInvoiceSchema,
  AdvertiserInvoiceSchemaStatus,
  ModelMedia,
  useAdminAdvertiserCampaignCampaignIdDelete,
  useAdminAdvertiserInvoiceInvoiceIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import DownloadIconWhite from "@assets/icons/white-download.svg";
import { CloseIcon, DownloadIcon } from "@components/Icons";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import InfoIcon from "@assets/icons/info.svg";
import { ToolTip } from "@components/ToolTip";
import { RenderTableView } from "@components/RenderTableView";
import { Visibility } from "@mui/icons-material";
import { formatDateForDisplay } from "@utils/formatDate";
import { InvoicePDF, InvoicePDFDetailsProps } from "@components/InvoicePDF";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { usePDF } from "@react-pdf/renderer";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { InvoiceReceipt } from "@components/InvoiceReceipt";
import { Header, MainContainer } from "@components/ModalComponents";
import { Document, Page } from "react-pdf";
import { TaxFee } from "@pages/settings/crm/CRMOrderTaxFess";

const data = [
  {
    attachmentId: 1,
    name: "Attachment Name",
    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    createdAt: "2021-09-01T00:00:00.000Z"
  }
];

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #1e293b;
  line-height: 30px;
`;
const TitleText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
`;
const DescriptionText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 24px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
`;

const formatDate = (isoString: string | Date | undefined): string => {
  if (!isoString) return "";
  const date = new Date(isoString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const AdvertiserInvoiceView = () => {
  const navigate = useNavigate();
  const [invoiceInstance, setInvoiceInstance] = usePDF();
  const [receiptInstance, setReceiptInstance] = usePDF();
  const [attachmentLink, setAttachmentLink] = useState<string | null>(null);
  const { advertiserId } = useParams();
  const [tab, setTab] = useState("Invoice Details");
  const [invoiceDetails, setInvoiceDetails] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [invoiceToDelete, setInvoiceToDelete] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [attachment, setAttachment] = useState<ModelMedia[]>([]);
  const { invoiceId } = useParams();

  const { data: advertiserInvoice, isLoading: isLoading } =
    useAdminAdvertiserInvoiceInvoiceIdGet(advertiserId!, invoiceId!);

  const { mutateAsync, isLoading: isDeleteing } =
    useAdminAdvertiserCampaignCampaignIdDelete();

  const onConfirmDelete = async () => {
    if (!invoiceToDelete?.campaignId) return;
    try {
      await mutateAsync({
        advertiserId: advertiserId!,
        campaignId: invoiceToDelete.campaignId
      });
      enqueueSnackbar("Invoice deleted successfully", { variant: "success" });
      setInvoiceDetails(null);
      navigate(`/advertisers/${advertiserId}?tab=Invoices`);
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete invoice", {
        variant: "error"
      });
      setInvoiceDetails(null);
    }
  };

  useEffect(() => {
    const advertiserInvoiceData = advertiserInvoice?.data;
    if (advertiserInvoiceData) {
      setInvoiceDetails(advertiserInvoiceData);

      const props: InvoicePDFDetailsProps = {
        from: {
          name: advertiserInvoiceData.advertiser?.organization?.name as string,
          address:
            advertiserInvoiceData.advertiser?.organization?.lines?.[0] +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.locality +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.province +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.country
        },
        invoiceNumber: advertiserInvoiceData.invoiceId as string,
        orderDate: advertiserInvoiceData.createdAt as Date,
        paymentDate: advertiserInvoiceData?.paidAt,
        invoiceDate: advertiserInvoiceData.createdAt as Date,
        startDate: advertiserInvoiceData.campaign?.startDate as Date,
        endDate: advertiserInvoiceData.campaign?.endDate as Date,
        paymentMode: advertiserInvoiceData.paymentType
          ? `${
              CRM_ORDER_PAYMENT_TYPES.find(
                (c) => c.value === advertiserInvoiceData.paymentType
              )?.label
            } - ${advertiserInvoiceData?.transactionInfo?.payment?.number}`
          : "",
        terms: "Due Upon Receipt",
        paymentStatus:
          advertiserInvoiceData.status as AdvertiserInvoiceSchemaStatus,
        billTo: {
          to: advertiserInvoiceData.advertiser?.businessName ?? "",
          name:
            advertiserInvoiceData.campaign?.billingContact?.firstName +
            " " +
            advertiserInvoiceData.campaign?.billingContact?.lastName,
          email:
            advertiserInvoiceData?.campaign?.billingContact?.accounts?.find(
              (a) =>
                a.accountId === advertiserInvoiceData?.advertiser?.accountId
            )?.workEmail ?? "",
          phone: formatPhoneWithCountryCode(
            advertiserInvoiceData?.campaign?.billingContact?.accounts?.find(
              (a) =>
                a.accountId === advertiserInvoiceData?.advertiser?.accountId
            )?.workPhone ??
              advertiserInvoiceData?.campaign?.billingContact?.phone
          )
        },
        items: [
          {
            name: `${advertiserInvoiceData.campaign?.sponsorshipLevel?.name} Sponsorship`,
            amount: advertiserInvoiceData.amountPreTax as number,
            quantity: 1,
            unitPrice: advertiserInvoiceData.amountPreTax as number,
            desciption: [
              {
                name: "Ad Placements",
                subDescription: [
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.sponsoredByEnabled && {
                    name: "Sponsored By Ad - Live Stream Event Feed Posts",
                    isBold: true
                  },
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.outStreamEnabled && {
                    name: "Out-Stream Ad - View Live Stream Screen",
                    isBold: true
                  },
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.inStreamEnabled && {
                    name: "In-Stream Ad - Live Streaming Intermissions",
                    isBold: true
                  }
                ].filter(Boolean)
              },
              {
                name: `Ads will start when this invoice is paid and will run for 1 year`
              },
              {
                name: "Unlimited Impressions"
              }
            ]
          }
        ],
        taxDetails: {
          taxAmount: advertiserInvoiceData?.taxAmount ?? 0,
          taxRate: advertiserInvoiceData?.taxRate ?? 0
        },
        feeDetails: {
          feeAmount: advertiserInvoiceData?.feeAmount ?? 0,
          feeRate: advertiserInvoiceData?.feeRate ?? 0
        }
      };
      setInvoiceInstance(
        <InvoicePDF
          invoiceFor="ADVERTISER"
          hasFooter={true}
          hasHeader={true}
          invoiceDetails={props}
          showSGLogo={false}
        />
      );
      setReceiptInstance(
        <InvoiceReceipt
          hasFooter={false}
          hasHeader={false}
          receiptDetails={{
            to: advertiserInvoiceData?.advertiser?.organization?.name as string,
            amount:
              (advertiserInvoiceData.amountPreTax ?? 0) +
              (advertiserInvoiceData.feeAmount ?? 0) +
              (advertiserInvoiceData.taxAmount ?? 0),
            invoiceNumber: advertiserInvoiceData.invoiceId as string,
            invoiceAmount: advertiserInvoiceData.amountPreTax ?? 0,
            status: advertiserInvoiceData.status === "PAID" ? "PAID" : "FAILED",
            paymentMethod: advertiserInvoiceData.paymentType
              ? `${
                  CRM_ORDER_PAYMENT_TYPES.find(
                    (c) => c.value === advertiserInvoiceData.paymentType
                  )?.label
                } - ${advertiserInvoiceData?.transactionInfo?.payment?.number}`
              : "",
            transactionId: advertiserInvoiceData?.transactionId || "",
            from: {
              name: advertiserInvoiceData?.advertiser?.organization
                ?.name as string,
              email: advertiserInvoiceData?.advertiser?.organization
                ?.email as string,
              phone: formatPhoneWithCountryCode(
                advertiserInvoiceData?.advertiser?.organization?.phone
              ),
              address:
                advertiserInvoiceData?.advertiser?.organization?.lines?.[0] +
                ", " +
                advertiserInvoiceData?.advertiser?.organization?.locality +
                ", " +
                advertiserInvoiceData?.advertiser?.organization?.province +
                ", " +
                advertiserInvoiceData?.advertiser?.organization?.country
            }
          }}
        />
      );
      if (advertiserInvoiceData?.campaign?.taxExemptForm) {
        setAttachment([advertiserInvoiceData.campaign.taxExemptForm]);
      }
    }
  }, [advertiserInvoice]);

  const ATTACHMENT_COLUMNS = [
    {
      headerName: "Actions",
      field: "action",
      sortable: false,
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ padding: "20px 0px", display: "flex", fontSize: "12px" }}
          >
            <IconButton
              onClick={() =>
                setAttachmentLink(params.row.baseUrl + params.row.path)
              }
            >
              <Visibility />
            </IconButton>
            <IconButton
              onClick={() =>
                downloadPDFFromLink(
                  params.row.baseUrl + params.row.path,
                  "Tax Exempt Form.pdf"
                )
              }
            >
              <DownloadIcon
                sx={{
                  fill: "#fff",
                  path: {
                    stroke: "#666",
                    strokeWidth: 2
                  }
                }}
              />
            </IconButton>
          </div>
        );
      }
    },
    {
      headerName: "Attachment Name",
      field: "name",
      maxWidth: 400,
      sortable: true,
      flex: 1,
      renderCell: () => "Signed Tax Exempt Form"
    },
    {
      headerName: "Date Uploaded",
      field: "createdAt",
      minWidth: 250,
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => formatDateForDisplay(new Date(row.createdAt))
    }
  ];

  const calculateTaxAmount = (item: TaxFee, amountPreTax: number) => {
    if (item.calculationMethod === "PERCENTAGE") {
      return ((amountPreTax ?? 1) * (item.rate / 100)).toFixed(2);
    }
    return item.rate;
  };

  return (
    <Container>
      <Loader isLoading={isLoading}>
        <Toolbar
          title="View Invoice"
          {...(tab === "Invoice Details" && {
            additionalBtns: [
              ...(invoiceDetails?.status === "PAID"
                ? [
                    {
                      button: (
                        <Button
                          type="button"
                          variant="admin-primary"
                          startIcon={<img src={DownloadIconWhite} />}
                          onClick={() =>
                            downloadPDFFromLink(
                              receiptInstance.url,
                              `${invoiceDetails?.invoiceId} Receipt.pdf`
                            )
                          }
                        >
                          Receipt
                        </Button>
                      )
                    },
                    {
                      button: (
                        <Button
                          type="button"
                          variant="admin-primary"
                          startIcon={<img src={DownloadIconWhite} />}
                          onClick={() =>
                            downloadPDFFromLink(
                              invoiceInstance.url,
                              `${invoiceDetails?.invoiceId}.pdf`
                            )
                          }
                        >
                          Invoice
                        </Button>
                      )
                    }
                  ]
                : [
                    {
                      button: (
                        <Button
                          type="button"
                          variant="admin-error"
                          startIcon={<CloseIcon />}
                          onClick={() => setInvoiceToDelete(invoiceDetails)}
                        >
                          Cancel
                        </Button>
                      )
                    }
                  ])
            ]
          })}
          backBtnClick={() =>
            navigate(`/advertisers/${advertiserId}?tab=Invoices`)
          }
          tabs={{
            tabs: ["Invoice Details", ...(data.length ? ["Attachments"] : [])],
            activeTab: tab,
            onTabChange: (e, value) => setTab(value)
          }}
        />
        {tab === "Invoice Details" && (
          <Form>
            <FlexBox>
              <HeaderText>
                {invoiceDetails?.advertiser?.organization?.name}
              </HeaderText>
              <HeaderText>Invoice</HeaderText>
            </FlexBox>
            <FlexBox style={{ marginTop: "5px" }}>
              <DescriptionText>
                {
                  invoiceDetails?.advertiser?.organization?.billingAddress?.split(
                    "|"
                  )[0]
                }
                <br />
                {invoiceDetails?.advertiser?.organization?.billingAddress
                  ?.split("|")
                  .slice(1)
                  .filter((line) => line)
                  .join(", ")}
              </DescriptionText>
              <HeaderText>
                Payment Status:{" "}
                <span
                  style={{
                    color:
                      invoiceDetails?.status === "PAID" ? "#1ABC9C" : "#E82C2C"
                  }}
                >
                  {invoiceDetails?.status === "PAID" ? "PAID" : "NOT PAID"}
                </span>
              </HeaderText>
            </FlexBox>
            <FlexBox style={{ marginTop: "32px" }}>
              <FlexBox style={{ flexDirection: "column", gap: "4px" }}>
                <TitleText>Bill To:</TitleText>
                <DescriptionText>
                  {invoiceDetails?.advertiser?.businessName}
                </DescriptionText>
                <DescriptionText>
                  {(invoiceDetails?.campaign?.billingContact?.firstName ?? "") +
                    " " +
                    (invoiceDetails?.campaign?.billingContact?.lastName ?? "")}
                </DescriptionText>
                {(() => {
                  const phone =
                    invoiceDetails?.campaign?.billingContact?.accounts?.find(
                      (a) =>
                        a.accountId === invoiceDetails?.advertiser?.accountId
                    )?.workPhone ||
                    invoiceDetails?.campaign?.billingContact?.phone;
                  if (!phone || !phone.length) return null;
                  return (
                    <DescriptionText>
                      {formatPhoneWithCountryCode(phone)}
                    </DescriptionText>
                  );
                })()}

                <DescriptionText>
                  {
                    invoiceDetails?.campaign?.billingContact?.accounts?.find(
                      (a) =>
                        a.accountId === invoiceDetails?.advertiser?.accountId
                    )?.workEmail
                  }
                </DescriptionText>
              </FlexBox>
              <FlexBox style={{ flexDirection: "column" }}>
                <FlexBox style={{ gap: "96px" }}>
                  <TitleText>Invoice No.</TitleText>
                  <DescriptionText>{invoiceDetails?.invoiceId}</DescriptionText>
                </FlexBox>
                <FlexBox style={{ alignItems: "center" }}>
                  <TitleText>Date</TitleText>
                  <DescriptionText>
                    {invoiceDetails?.createdAt
                      ? formatDate(invoiceDetails.createdAt)
                      : ""}
                  </DescriptionText>
                </FlexBox>
                <FlexBox>
                  <TitleText>Due Date</TitleText>
                  <DescriptionText>
                    {invoiceDetails?.campaign?.startDate
                      ? formatDate(invoiceDetails?.campaign?.startDate)
                      : ""}
                  </DescriptionText>
                </FlexBox>
                <FlexBox style={{ gap: "20px" }}>
                  <TitleText>Terms</TitleText>
                  <DescriptionText>Due Upon Receipt</DescriptionText>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <TableContainer
              style={{ marginTop: "32px", border: "1px solid #B3B3B3" }}
            >
              <Table>
                <TableHead
                  style={{ backgroundColor: "#F3F4F7", height: "40px" }}
                >
                  <TableRow>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      QTY
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "70%" }}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ textAlign: "center", paddingLeft: "150px" }}
                    >
                      Amount
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    style={{
                      verticalAlign: "baseline"
                    }}
                  >
                    <StyledTableCell
                      style={{
                        borderBottom: "1px solid #E5E5E5",
                        textAlign: "center"
                      }}
                    >
                      1
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                    >
                      <FlexBox style={{ flexDirection: "column" }}>
                        <TitleText>
                          {invoiceDetails?.campaign?.sponsorshipLevel?.name}{" "}
                          Sponsorship
                        </TitleText>
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            <TitleText>Ad Placements</TitleText>
                            <ul
                              style={{
                                marginLeft: "30px",
                                listStyleType: "disc"
                              }}
                            >
                              {invoiceDetails?.campaign?.sponsorshipLevel
                                ?.sponsoredByEnabled && (
                                <li style={{ fontWeight: 600 }}>
                                  Sponsored By Ad - Live Stream Event Feed Posts
                                </li>
                              )}
                              {invoiceDetails?.campaign?.sponsorshipLevel
                                ?.outStreamEnabled && (
                                <li style={{ fontWeight: 600 }}>
                                  Out-Stream Ad - View Live Stream Screen
                                </li>
                              )}
                              {invoiceDetails?.campaign?.sponsorshipLevel
                                ?.inStreamEnabled && (
                                <li style={{ fontWeight: 600 }}>
                                  In-Stream Ad - Live Streaming Intermissions
                                </li>
                              )}
                            </ul>
                          </li>
                          <li>
                            <TitleText>
                              Ads will start when this invoice is paid and will
                              run for 1 year
                            </TitleText>
                          </li>
                          <li>
                            <TitleText>Unlimited Impressions</TitleText>
                          </li>
                        </ul>
                      </FlexBox>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderBottom: "1px solid #E5E5E5",
                        textAlign: "right",
                        width: "20%",
                        paddingLeft: "50px"
                      }}
                    >
                      {formatCurrency(invoiceDetails?.amountPreTax as number)}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <FlexBox style={{ justifyContent: "flex-end" }}>
              <FlexBox style={{ flexDirection: "column" }}>
                <TitleText style={{ padding: "8px 12px" }}>Sub Total</TitleText>
                <TitleText
                  style={{
                    padding: "8px 12px",
                    display: "flex",
                    gap: "10px"
                  }}
                >
                  <span
                    style={{
                      width: "120px"
                    }}
                  >
                    Tax ({invoiceDetails?.taxRate ?? 0}%)
                  </span>
                  {(invoiceDetails?.taxInfo as TaxFee[])?.length > 0 && (
                    <ToolTip
                      title={
                        <div>
                          {(invoiceDetails?.taxInfo as TaxFee[])?.map(
                            (item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "40px"
                                }}
                              >
                                <span>{item.name}</span>
                                <span style={{ fontWeight: 600 }}>
                                  $
                                  {item.calculationMethod === "PERCENTAGE"
                                    ? calculateTaxAmount(
                                        item,
                                        invoiceDetails?.amountPreTax ?? 1
                                      )
                                    : item.rate}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      }
                    >
                      <img src={InfoIcon} />
                    </ToolTip>
                  )}
                </TitleText>
                <TitleText
                  style={{
                    padding: "8px 12px",
                    display: "flex",
                    gap: "10px"
                  }}
                >
                  <span
                    style={{
                      width: "120px"
                    }}
                  >
                    {" "}
                    Fees ({invoiceDetails?.feeRate ?? 0}%)
                  </span>
                  {(invoiceDetails?.feeInfo as TaxFee[])?.length > 0 && (
                    <ToolTip
                      title={
                        <div>
                          {(invoiceDetails?.feeInfo as TaxFee[])?.map(
                            (item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "40px"
                                }}
                              >
                                <span>{item.name}</span>
                                <span style={{ fontWeight: 600 }}>
                                  $
                                  {item.calculationMethod === "PERCENTAGE"
                                    ? calculateTaxAmount(
                                        item,
                                        invoiceDetails?.amountPreTax ?? 1
                                      )
                                    : item.rate}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      }
                    >
                      <img src={InfoIcon} />
                    </ToolTip>
                  )}
                </TitleText>
                <TitleText style={{ fontWeight: 600, padding: "8px 12px" }}>
                  Total
                </TitleText>
              </FlexBox>
              <FlexBox
                style={{
                  flexDirection: "column",
                  border: "2px solid #E5E5E5",
                  borderTop: "none",
                  minWidth: "140px",
                  textAlign: "right"
                }}
              >
                <TitleText style={{ padding: "8px 12px" }}>
                  {formatCurrency(invoiceDetails?.amountPreTax as number)}
                </TitleText>
                <TitleText style={{ padding: "8px 12px" }}>
                  {formatCurrency(invoiceDetails?.taxAmount as number)}
                </TitleText>
                <TitleText style={{ padding: "8px 12px" }}>
                  {formatCurrency(invoiceDetails?.feeAmount as number)}
                </TitleText>
                <TitleText
                  style={{
                    fontWeight: 600,
                    padding: "8px 12px",
                    borderTop: "2px solid #E5E5E5"
                  }}
                >
                  {formatCurrency(
                    (invoiceDetails?.amountPreTax as number) +
                      (invoiceDetails?.taxAmount as number) +
                      (invoiceDetails?.feeAmount as number)
                  )}
                </TitleText>
              </FlexBox>
            </FlexBox>
          </Form>
        )}
        {tab === "Attachments" && (
          <Form style={{ padding: 0 }}>
            <RenderTableView
              title=""
              hideToolbar
              columns={ATTACHMENT_COLUMNS}
              rows={attachment}
              hideFooter
              hasActionColumn={false}
              getRowId={(row) => row.mediaId}
            />
          </Form>
        )}
        {attachmentLink && (
          <Modal open={true}>
            <MainContainer>
              <Header>
                <HeaderText style={{ color: "#fff" }}>
                  Signed Tax Exempt Form
                </HeaderText>
                <IconButton
                  onClick={() => {
                    setAttachmentLink(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Header>
              <div style={{ height: "65vh", overflow: "auto" }}>
                <Document
                  file={attachmentLink}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  {Array.from({ length: numPages || 0 }, (_, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={800}
                    />
                  ))}
                </Document>
              </div>
            </MainContainer>
          </Modal>
        )}
        <ConfirmationDialog
          open={!!invoiceToDelete}
          title="Cancel Invoice"
          body={`Are you sure you want to cancel this invoice and delete the associated campaign ${invoiceToDelete?.advertiser?.businessName}?`}
          close={() => setInvoiceToDelete(null)}
          onConfirm={onConfirmDelete}
          onCancel={() => setInvoiceToDelete(null)}
          isConfirming={isDeleteing}
          confirmBtnVariant="admin-error"
          icon="error"
        />
      </Loader>
    </Container>
  );
};
