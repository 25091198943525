import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminHelpArticlePostPutRequest,
  ModelHelpCategory,
  ModelMedia,
  mediaGet,
  useAdminHelpArticlePost,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { HelpArticleForm } from "./helpArticleForm";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";

export const HelpArticleCreate = () => {
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const form = useForm({
    mode: "onTouched",
    defaultValues: {
      status: "DRAFT",
      content: [],
      hasMedia: false,
      headline: "",
      categoryId: "",
      displayChannel: [],
      relatedTo: ""
    }
  });

  const {
    register,
    getValues,
    reset,
    formState: { isValid }
  } = form;

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useAdminHelpCategoryGet();
  const [categories, setCategories] = React.useState<ModelHelpCategory[]>([]);

  React.useEffect(() => {
    if (categoriesData?.data) {
      setCategories(categoriesData.data.helpCategory!);
    }
  }, [categoriesData]);

  React.useEffect(() => {
    register("content", { required: true });
  }, [register]);

  const { mutate: save, isLoading: isSaving } = useAdminHelpArticlePost();
  const [isUploadingImage, setUploadingImage] = useState(false);
  const onSave = async (saveAndNew: boolean) => {
    const content = getValues().content as {
      insert: {
        image?: string;
      };
    }[];
    if (getValues().hasMedia) {
      setUploadingImage(true);
      await Promise.all(
        content?.map(async (op, idx) => {
          if (op.insert && Object.keys(op.insert).includes("image")) {
            const file = base64ImageToFile(
              op.insert.image,
              getValues().headline + "_" + idx
            );
            const filesPromises = await Promise.all(
              [file].map((file) => {
                if (file instanceof File) {
                  const promise = uploadMediaUsingPresignedUrl(file);
                  return promise;
                } else {
                  return (file as ModelMedia).mediaId!;
                }
              })
            );

            if (filesPromises) {
              const response = await mediaGet({
                mediaIds: [filesPromises[0]]
              });
              if (
                response &&
                response.data.media &&
                response.data.media.length
              ) {
                const media = response.data.media[0];
                if (media.media && media.media.baseUrl && media.media.path) {
                  let path = media.media.path;
                  if (media.media.state !== "PUBLISHED") {
                    path = path.replace("original/", "");
                  }
                  const urlPath = media.media.baseUrl + path;
                  content[idx].insert.image = urlPath;
                }
              }
            }
          }
        })
      );

      setUploadingImage(false);
    }
    const values = {
      ...getValues(),
      content: content
    };
    save(
      {
        data: values as AdminHelpArticlePostPutRequest
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Article Created successfully!", {
            variant: "success"
          });

          if (saveAndNew) {
            reset();
            setRefreshKey(refreshKey + 1);
          } else {
            navigate("/help-articles");
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Article!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Container>
      <Toolbar title="Add Help Articles" />
      <FormProvider {...form}>
        <HelpArticleForm
          key={refreshKey}
          categories={categories}
          isLoadingCategories={isLoadingCategories}
        />
      </FormProvider>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave(false);
        }}
        saveAndNewBtnClick={() => {
          onSave(true);
        }}
        isDisabled={!isValid || isSaving || isUploadingImage}
        isLoading={isSaving || isUploadingImage}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/help-articles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
