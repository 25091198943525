import { TableView } from "@components/TableView";
import { VerticalListTooltip } from "@components/VerticalListTooltip";
import { GridColDef } from "@mui/x-data-grid";
import { getQaUserSearch } from "@services/Network";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import React from "react";

const LIST_COLUMNS: GridColDef<ModelPerson>[] = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.firstName + " " + row.lastName
  },
  {
    headerName: "Email",
    field: "email",
    minWidth: 250,
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => row.email || "-",
    renderCell: ({ row }) => {
      return (
        <VerticalListTooltip
          text={row.emails?.[0]?.email || "-"}
          tooltipList={row.emails?.slice(1).map((email) => email?.email)}
        />
      );
    }
  },
  {
    headerName: "Phone",
    field: "phone",
    minWidth: 150,
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => row.phone || "-",
    renderCell: ({ row }) => {
      return (
        <VerticalListTooltip
          text={formatPhoneWithCountryCode(row.phones?.[0]?.phone) || "-"}
          tooltipList={row.phones
            ?.slice(1)
            .map((phone) => formatPhoneWithCountryCode(phone?.phone))}
        />
      );
    }
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => (row.userId ? "Active" : "Inactive")
  }
];

export const QAUserSearch = () => {
  const [refreshKey] = React.useState(0);

  return (
    <>
      <TableView
        title="QA User Search"
        useGet={getQaUserSearch}
        columns={LIST_COLUMNS}
        hasActionColumn={false}
        hideFilter
        hideLabelContainer
        hideFooter
        hideFooterPagination
        getRowId={(row) => row.personId}
        refreshKey={refreshKey}
        sortingMode="client"
        textSearchReplaceRegex={/[^a-zA-Z0-9@.+_$/]/g}
      />
    </>
  );
};
