import {
  Autocomplete,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
  styled
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { getCrmAccounts, getCrmOrgAccounts } from "../../../services/Network";
import { Add } from "@mui/icons-material";
import {
  ModelAccount,
  ModelOrganizationAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";

type Account = {
  accountId: string;
  name: string;
  parent?: string;
  category: string;
  type: string;
  officeAddress: string;
  email?: string;
  website?: string;
  sportsOffered?: string[];
  numberOfAthletes?: string;
  aeOwner?: string;
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledAccountOption = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const AccountOption = ({
  account,
  onClick,
  ...props
}: {
  account: ModelAccount;
  onClick: () => void;
}) => {
  const { name, officeAddress } = account;
  return (
    <StyledAccountOption
      container
      direction="column"
      padding="10px 16px 10px 16px"
      {...props}
      onClick={onClick}
    >
      <Grid item>
        <Typography
          style={{
            color: "#000",
            fontSize: "13px",
            fontWeight: 500
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            color: "#666666",
            fontSize: "13px",
            fontWeight: 400
          }}
        >
          {officeAddress}
        </Typography>
      </Grid>
    </StyledAccountOption>
  );
};

const AddAccountOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      container
      padding="10px 16px 10px 16px"
      style={{ backgroundColor: "#E5E5E5" }}
      xs={12}
      direction="column"
      data-testid="ADD_NEW_PERSON_OPTION"
    >
      <Grid item>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: "600",
            fontSize: "13px"
          }}
        >
          Not finding who you're looking for?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          style={{
            color: "#007AFF",
            textTransform: "capitalize",
            fontSize: "14px"
          }}
          onClick={onClick}
          startIcon={<Add />}
        >
          Add New Account
        </Button>
      </Grid>
    </Grid>
  );
};

export const SearchAddAccount = ({
  accountSelected,
  showAddAccountOption,
  required,
  label,
  disabled,
  searchValue,
  parentId,
  onClear,
  organizationId,
  filterOption = () => true
}: {
  accountSelected: (
    selectedAccount,
    accountSelected,
    newAccountSelected
  ) => void;
  showAddAccountOption?: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  searchValue?: string;
  parentId?: string | null;
  onClear?: () => void;
  organizationId?: string;
  filterOption?: (option: ModelOrganizationAccount) => boolean;
}) => {
  const [blurred, setBlurred] = useState(false);
  const [valueSelected, setValueSelected] = useState<
    "SELECTED" | "NEW" | undefined
  >(undefined);
  const [accountSearch, setAccountSearch] = useState("");
  useEffect(() => {
    if (searchValue) setAccountSearch(searchValue);
  }, [searchValue]);
  const [addAccountMenuOpen, setAddAccountMenuOpen] = useState(false);
  const defaultPerson: Account = {
    accountId: "",
    name: "",
    category: "",
    type: "",
    officeAddress: "",
    email: "",
    website: "",
    sportsOffered: [],
    numberOfAthletes: "",
    aeOwner: ""
  };
  const onAddAccountSearchChange = (event) => {
    setAccountSearch(event.target.value);
    setValueSelected(undefined);
    if (!event.target.value && onClear) onClear();
  };
  const { data: addAccountResults, isFetching: isLoading } = organizationId
    ? getCrmOrgAccounts(
        {
          textSearch: accountSearch,
          organizationId: organizationId,
          ...(parentId && {
            parentId: parentId
          })
        },
        { staleTime: Infinity, enabled: !!accountSearch?.length }
      )
    : getCrmAccounts(
        {
          textSearch: accountSearch,
          ...(parentId && {
            parentId: parentId
          })
        },
        { staleTime: Infinity, enabled: !!accountSearch?.length }
      );
  const addAccountOptions = [...addAccountResults];
  if (showAddAccountOption) addAccountOptions.push(null);
  return (
    <Grid item container direction="row" spacing="24px">
      <Grid item xs={12}>
        <StyledFormLabel required={required}>
          <Typography display="inline" variant="formLabel">
            {label || "Account Name"}
          </Typography>
        </StyledFormLabel>
        <Autocomplete
          open={addAccountMenuOpen}
          clearOnBlur={false}
          freeSolo
          disabled={disabled}
          onOpen={() => setAddAccountMenuOpen(true)}
          onClose={() => setAddAccountMenuOpen(false)}
          // value={secondaryContact}
          getOptionLabel={(option) => (option ? option.name : "")}
          isOptionEqualToValue={(option, value) =>
            option?.accountId === value?.accountId
          }
          inputValue={accountSearch}
          renderInput={(params) => (
            <SearchInput
              data-testid="ADD_ACCOUNT_SEARCH_PERSON_INPUT"
              {...params}
              value={accountSearch}
              onChange={onAddAccountSearchChange}
            />
          )}
          filterOptions={(options) =>
            addAccountMenuOpen && !isLoading ? options : []
          }
          renderOption={(props, option: ModelAccount, state) => {
            if (
              state.index === addAccountOptions.length - 1 &&
              showAddAccountOption
            ) {
              return (
                <AddAccountOption
                  key={state.index}
                  onClick={() => {
                    setValueSelected("NEW");
                    accountSelected(defaultPerson, false, true);
                    setAddAccountMenuOpen(false);
                  }}
                />
              );
            }
            if (!filterOption(option)) return null;
            return (
              <AccountOption
                key={option?.accountId}
                account={option}
                onClick={() => {
                  accountSelected(
                    {
                      account: option?.name,
                      accountId: option?.accountId,
                      workLocation: option?.officeAddress,
                      parent: option?.parent?.name,
                      category: option?.category,
                      type: option?.type,
                      workPhoneType: option?.phoneType,
                      workPhone: option?.phone,
                      workEmail: option?.email,
                      website: option?.website,
                      sportsOffered:
                        option?.sports?.map((s) => s.sportId) || [],
                      numberOfAthletes: option?.numberOfAthletes,
                      aeOwner: formatFullName(
                        organizationId
                          ? option?.owner?.person
                          : option?.aeOwner?.person
                      ),
                      contacts: option?.contacts
                    },
                    true,
                    false
                  );
                  setAccountSearch(option!.name!);
                  setAddAccountMenuOpen(false);
                  setValueSelected("SELECTED");
                }}
              />
            );
          }}
          options={addAccountOptions || []}
          loading={isLoading}
          loadingText="Loading..."
          onBlur={() => setBlurred(true)}
          noOptionsText="No accounts found"
        />
        {blurred && required && !valueSelected && (
          <FormHelperText error>Account is required</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};
