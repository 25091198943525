import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "theme/colors";
import { useForm } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { Footer } from "@components/crud/Footer";
import { FormInput } from "./FormInput";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom, profileAtom } from "@recoil/auth";
import {
  mediaPermissionGet,
  ModelMedia,
  ModelPost,
  ModelRole,
  ModelTeam,
  ModelTrainingProgram,
  ModelUser,
  useAdminFeedPost,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { FormSelect } from "./FormSelect";
import { PostIcon } from "./Icons";
import { StyledFormLabel } from "./StyledFormLabel";
import { Search } from "@mui/icons-material";
import { Loader } from "./crud/Loader";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { FormMultiSelect } from "./FormMultiSelect";

interface Connection {
  modelType:
    | "TEAM"
    | "TRAINING_PROGRAM"
    | "ORGANIZATION"
    | "CONNECTION_GROUP"
    | "USER_ROLE";
  organization?: {
    organizationId: string;
    name: string;
    avatar: ModelMedia;
  };
  name?: string;
  organizationId?: string;
  teamId?: string;
  groupId?: string;
  programId?: string;
  team: ModelTeam;
  program: ModelTrainingProgram;
  role?: ModelRole;
  userId?: string;
  user?: ModelUser;
}

const generateAttachmentText = (attachments: ModelMedia[]): string => {
  let photoCount = 0;
  let videoCount = 0;

  attachments.forEach((attachment) => {
    if (attachment?.mimeType && attachment?.mimeType.startsWith("image")) {
      photoCount++;
    } else if (
      attachment?.mimeType &&
      attachment?.mimeType.startsWith("video")
    ) {
      videoCount++;
    }
  });

  const photoText = photoCount === 1 ? "1 Photo" : `${photoCount} Photos`;
  const videoText = videoCount === 1 ? "1 Video" : `${videoCount} Videos`;
  if (photoCount > 0 && videoCount > 0) {
    return `${videoText} and ${photoText} Attached To This Post`;
  } else if (photoCount > 0) {
    return `${photoText} Attached To This Post`;
  } else if (videoCount > 0) {
    return `${videoText} Attached To This Post`;
  } else {
    return "";
  }
};

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  maxWidth: "1000px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .footer": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const PostToFeed = ({
  onClose,
  media,
  sportId,
  title = "Post to Feed",
  onSave,
  showOn = "ALBUMS"
}: {
  onClose: () => void;
  sportId?: string;
  media: ModelMedia[];
  title?: string;
  onSave?: (post: ModelPost) => void;
  showOn?: "FEED" | "ALBUMS";
}) => {
  const [tagsOpen, setTagsOpen] = useState(false);
  const user = useRecoilValue(profileAtom);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const organizationId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currOrg = orgs.find((o) => o.organizationId === organizationId);
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { isValid }
  } = useForm({ mode: "onTouched" });

  const { data: sports, isLoading: sportOptionsLoading } = useAdminSportGet({
    organizationId: organizationId!
  });

  const { data: connections, isLoading: connectionLoading } = useQuery(
    [organizationId, user?.userId],
    () => mediaPermissionGet(),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60
    }
  );
  const { mutate, isLoading: postSaving } = useAdminFeedPost();

  const saveHandler = async () => {
    const values = getValues();
    mutate(
      {
        data: {
          organizationId: organizationId!,
          body: values.body,
          mediaIds: media?.map((m) => m.mediaId) as string[],
          sports: showOn === "FEED" ? values.sport : [values.sport],
          shareTo: values.shareTo.map((v) => v.value)
        }
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar("Post created successfully", { variant: "success" });
          onSave && onSave(data.data);
          onClose();
        },
        onError: () => {
          enqueueSnackbar("Failed to create post", { variant: "error" });
          onClose();
        }
      }
    );
  };

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId!
      })) || [],
    [sports]
  );

  useEffect(() => {
    if (sportId) {
      setValue("sport", sportId);
    }
  }, [sportId]);

  const shareToOptions = useMemo(() => {
    if (!connections?.data?.recentConnections) return [];
    const orgConnections = connections?.data?.recentConnections as Connection[];
    const organization = orgConnections.filter(
      (c) =>
        c.modelType === "ORGANIZATION" && c?.organizationId === organizationId
    );
    const orgTeam = orgConnections.filter(
      (c) =>
        c.modelType === "TEAM" &&
        c?.organization?.organizationId === organizationId
    );
    const orgTp = orgConnections.filter(
      (c) =>
        c.modelType === "TRAINING_PROGRAM" &&
        c?.organization?.organizationId === organizationId
    );
    const roleOrder = {
      ADMIN: 1,
      COACH: 2,
      MANAGER: 3,
      PARENT: 4,
      PLAYER: 5
    };
    const users = orgConnections
      .filter(
        (c) =>
          c.modelType === "USER_ROLE" &&
          c?.role?.organizationId === organizationId
      )
      .sort((a, b) => {
        return roleOrder[a.role?.alias || ""] - roleOrder[b?.role?.alias || ""];
      })
      .filter(
        (() => {
          const seen = new Set();
          return (user: Connection) => {
            if (seen.has(user.userId!)) {
              return false;
            } else {
              seen.add(user.userId!);
              return true;
            }
          };
        })()
      );
    const personGroups = orgConnections
      .filter((c) => c.modelType === "CONNECTION_GROUP")
      .map((c) => {
        if (c.teamId && orgTeam.some((t) => t.teamId === c.teamId)) {
          return {
            add: true,
            group: c,
            team: orgTeam.find((t) => t.teamId === c.teamId)
          };
        } else if (
          c.programId &&
          orgTp.some((tp) => tp.programId === c.programId)
        ) {
          return {
            add: true,
            group: c,
            program: orgTp.find((tp) => tp.programId === c.programId)
          };
        }
        return null;
      })
      .filter(Boolean);
    return [
      ...organization.map((o) => ({
        value: o.organizationId,
        label: o.name,
        type: "ORGANIZATION",
        role: "Organization",
        colorCode: undefined,
        avatar: undefined
      })),
      ...orgTeam.map((t) => ({
        value: t.teamId,
        label: t.name,
        type: "TEAM",
        role: "Team",
        colorCode: undefined,
        avatar: undefined
      })),
      ...orgTp.map((tp) => ({
        value: tp.programId,
        label: tp.name,
        type: "PROGRAM",
        role: "Training Program",
        colorCode: undefined,
        avatar: undefined
      })),
      ...personGroups.map((pg) => ({
        value: pg.group.groupId,
        label: pg.group.name,
        type: "GROUP",
        role: `Person Group - ${pg.team ? pg.team.name : pg.program?.name}`,
        colorCode: undefined,
        avatar: undefined
      })),
      ...users.map((u) => ({
        value: u?.userId,
        label: `${u?.user?.person?.firstName} ${u?.user?.person?.lastName}`,
        role: u?.role?.name,
        type: "USER",
        colorCode: u.user?.person?.colorCode,
        avatar:
          u?.user?.person?.avatar &&
          u?.user?.person?.avatar.baseUrl &&
          u?.user?.person?.avatar.path
            ? u?.user?.person?.avatar.baseUrl + u?.user?.person?.avatar.path
            : undefined
      }))
    ];
  }, [connections]);

  const shareTo = watch("shareTo");

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenCancelDialog(true)}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Grid container spacing={3} padding="24px" zIndex="1000">
            <Loader isLoading={connectionLoading}>
              <Grid xs={12}>
                <FormInput
                  label="From"
                  control={control}
                  name="from"
                  disabled
                  type="text"
                  required
                  enteredValue={
                    user?.person?.firstName + " " + user?.person?.lastName
                  }
                />
              </Grid>

              <Grid xs={12}>
                <StyledFormLabel>
                  <Typography display="inline" variant="formLabel">
                    To
                  </Typography>
                </StyledFormLabel>
                <Autocomplete
                  open={tagsOpen}
                  disableClearable={true}
                  multiple
                  options={shareToOptions}
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{ display: "flex", gap: "8px" }}
                        key={option.value}
                      >
                        {option.type === "USER" ? (
                          <>
                            {option.avatar ? (
                              <Avatar
                                variant="square"
                                src={option.avatar}
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "8px"
                                }}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: "#" + option.colorCode,
                                  color: "#fff !important",
                                  padding: "11px",
                                  fontSize: "11px",
                                  fontWeight: 700,
                                  lineHeight: "20px",
                                  textAlign: "center",
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "8px"
                                }}
                                variant="square"
                              >
                                {option
                                  .label!.split(" ")
                                  .map((name) => name[0])
                                  .join("")
                                  .toUpperCase()}
                              </Avatar>
                            )}
                          </>
                        ) : (
                          <Avatar
                            variant="square"
                            src={
                              currOrg?.avatar?.path &&
                              currOrg.avatar.baseUrl &&
                              currOrg.avatar.baseUrl + currOrg.avatar.path
                            }
                            style={{
                              height: "32px",
                              width: "32px",
                              borderRadius: "8px"
                            }}
                          />
                        )}

                        <div>
                          <Typography lineHeight="20px">
                            {option.label}
                          </Typography>
                          <Typography color="#64748B" lineHeight="20px">
                            {option.role}
                          </Typography>
                        </div>
                      </li>
                    );
                  }}
                  getOptionLabel={(option) =>
                    typeof option === "object" ? option.label! : ""
                  }
                  filterSelectedOptions
                  renderInput={(params) => {
                    if (Array.isArray(params.InputProps.startAdornment)) {
                      params.InputProps.startAdornment.splice(
                        0,
                        0,
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    } else {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    }
                    return (
                      <Loader isLoading={false}>
                        <TextField {...params} />
                      </Loader>
                    );
                  }}
                  onChange={(e, value) => {
                    setValue(
                      "shareTo",
                      value?.map((v) => v)
                    );
                  }}
                  slotProps={{
                    paper: {
                      style: {
                        borderRadius: "8px",
                        border: "1px solid #E5E5E5",
                        marginTop: "8px",
                        boxShadow: "0px 1px 2px -1px #0000001A"
                      }
                    }
                  }}
                  onOpen={() => setTagsOpen(true)}
                  onBlur={() => setTagsOpen(false)}
                  noOptionsText="No results found"
                />
              </Grid>

              <Grid xs={12}>
                {showOn === "FEED" ? (
                  <FormMultiSelect
                    label="Select Sport"
                    control={control}
                    name="sport"
                    required
                    options={sportOptions}
                    isLoading={sportOptionsLoading}
                    disabled={!!sportId}
                    rules={{
                      required: "Sport is required"
                    }}
                  />
                ) : (
                  <FormSelect
                    label="Select Sport"
                    control={control}
                    name="sport"
                    required
                    options={sportOptions}
                    isLoading={sportOptionsLoading}
                    disabled={!!sportId}
                    rules={{
                      required: "Sport is required"
                    }}
                  />
                )}
              </Grid>
              <Grid xs={12}>
                <FormInput
                  label="Message"
                  control={control}
                  name="body"
                  type="text"
                  multiline
                  rows={4}
                  required
                  rules={{
                    required: "Message is required"
                  }}
                />
              </Grid>
              {media && media.length > 0 && (
                <Grid xs={12}>
                  <div
                    style={{
                      background: "#F3F4F7",
                      padding: "12px 24px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <PostIcon />
                    <Typography color="1E2941">
                      {generateAttachmentText(media || [])}
                    </Typography>
                  </div>
                </Grid>
              )}
            </Loader>
          </Grid>
          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            saveBtnClick={saveHandler}
            saveBtnLabel="Post"
            isDisabled={
              !isValid || !shareTo || shareTo.length === 0 || postSaving
            }
            isLoading={postSaving}
          />
        </StyledBox>
      </Container>
      <ConfirmationDialog
        open={openCancelDialog}
        title="Confirm"
        body="Are you sure you want to delete this post draft?"
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={onClose}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Backdrop>
  );
};
