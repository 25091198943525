import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { Box, FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminAdvertiserIdGet,
  useSponsorshipLevelGet,
  useAdminAdvertiserBusinessCategoryGet,
  ModelAdvertiser,
  useAdminAdvertiserIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, SyntheticEvent, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import { FormCheckbox } from "@components/FormCheckbox";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Advertisements } from "./advertisements/Advertisements";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { Loader } from "@components/crud/Loader";
import styled from "styled-components";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { formatCurrency } from "@utils/formatCurrency";
import { Capmaigns } from "@pages/campaigns/Campaigns";

const StyledFormLabel = styled(FormLabel)(() => ({
  marginBottom: "0.25rem"
}));

export const FSGOAdvertiserView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [advertiserToDelete, setAdvertiserToDelete] =
    useState<ModelAdvertiser | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useRecoilValue(organizationAtom);
  const { advertiserId } = useParams();
  const [hasSecondaryContact, setHasSecondaryContact] = useState(false);
  const [tab, setTab] = useState("Advertiser Details");
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  const { data: advertiser, isLoading: advertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!);
  const { data: levels, isLoading: levelLoading } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });
  const { data: businessCategory, isLoading: businessCategoryLoading } =
    useAdminAdvertiserBusinessCategoryGet();
  const levelsOptions = useMemo(
    () =>
      levels?.data?.sponsorshipLevels.map((level) => ({
        label: `${level.name!} - ${formatCurrency(level.amount)}`,
        value: level.sponsorshipId
      })) || [],
    [levels]
  );
  const businessCategoryOptions = useMemo(
    () =>
      businessCategory?.data.map((category) => ({
        label: (category as { label: string; value: string }).label,
        value: (category as { label: string; value: string }).value
      })) || [],
    [businessCategory]
  );
  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];

  const { control, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      icon: "",
      status: "INACTIVE",
      primaryContact: true
    }
  });
  useEffect(() => {
    if (advertiser?.data) {
      const defaultValues = {
        name: advertiser.data.businessName,
        status: advertiser.data.status,
        category: advertiser.data.businessCategoryId,
        level: advertiser.data.sponsorshipId,
        ...(organizationId
          ? {
              contact: advertiser.data.contacts?.map((c) => ({
                contactId: c.contactId!,
                firstName: c.contact?.firstName || "",
                lastName: c.contact?.lastName || "",
                workPhone: c.contact?.phone || "",
                personalPhone: "",
                personalEmail: c.contact?.email || "",
                isApprovalRequired: c.isApprovalRequired
              }))
            }
          : {
              primaryContact: {
                firstName: advertiser.data.primaryContact?.firstName,
                lastName: advertiser.data.primaryContact?.lastName,
                workPhone: advertiser.data.primaryContact?.workPhone,
                mobilePhone:
                  advertiser.data.primaryContact?.personalPhone || "",
                Workemail: advertiser.data.primaryContact?.workEmail,
                personalEmail:
                  advertiser.data.primaryContact?.personalEmail || "",
                hasPermission: advertiser.data.isPrimaryApprovalRequired
              }
            })
      };
      //@ts-ignore
      if (advertiser.data.secondaryContact) {
        setHasSecondaryContact(true);
        defaultValues["secondaryContact"] = {
          firstName: advertiser.data.secondaryContact?.firstName,
          lastName: advertiser.data.secondaryContact?.lastName,
          workPhone: advertiser.data.secondaryContact?.workPhone,
          mobilePhone: advertiser.data.secondaryContact?.personalPhone || "",
          Workemail: advertiser.data.secondaryContact?.workEmail,
          personalEmail: advertiser.data.secondaryContact?.personalEmail || "",
          hasPermission: advertiser.data.isSecondaryApprovalRequired
        };
      }
      reset(defaultValues);
    }
  }, [advertiser]);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminAdvertiserIdDelete();

  const onConfirmDelete = async () => {
    if (!advertiserToDelete?.advertiserId) return;
    try {
      await deleteAsync({ advertiserId: advertiserToDelete.advertiserId });
      enqueueSnackbar("Advertiser deleted successfully", {
        variant: "success"
      });
      setAdvertiserToDelete(null);
      navigate("/advertisers");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete advertiser.", {
        variant: "error"
      });
      setAdvertiserToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("live-streaming.advertisers", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (searchParams.has("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, []);

  const crmContactDetails = (index: number) => {
    return (
      <>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].firstName`}>
            <FormInput
              name={`contact[${index}].firstName`}
              control={control}
              rules={{ required: "First Name is required" }}
              label="First Name"
              type="text"
              required
              disabled
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].lastName`}>
            <FormInput
              name={`contact[${index}].lastName`}
              control={control}
              rules={{ required: "Last Name is required" }}
              label="Last Name"
              type="text"
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].workPhone`}>
            <FormInput
              name={`contact[${index}].workPhone`}
              required
              control={control}
              label="Work Phone"
              disabled
              type="tel"
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].personalPhone`}>
            <FormInput
              name={`contact[${index}].personalPhone`}
              control={control}
              disabled
              label="Mobile Phone"
              type="tel"
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].personalEmail`}>
            <FormInput
              name={`contact[${index}].personalEmail`}
              control={control}
              label="Personal Email"
              type="text"
              disabled
            />
          </Grid>
          <Grid xs={12} data-testid={`contact[${index}].hasPermission`}>
            <FormCheckbox
              name={`contact[${index}].isApprovalRequired`}
              control={control}
              disabled
              label="Permission to approve and decline advertisements"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const contactDetails = (type: string) => {
    return (
      <>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_firstName`}>
            <FormInput
              name={`${type}.firstName`}
              control={control}
              label="First Name"
              type="text"
              disabled
              required
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_lastName`}>
            <FormInput
              name={`${type}.lastName`}
              control={control}
              label="Last Name"
              type="text"
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_workPhone`}>
            <FormInput
              name={`${type}.workPhone`}
              required
              control={control}
              label="Work Phone"
              type="tel"
              disabled
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_mobilePhone`}>
            <FormInput
              name={`${type}.mobilePhone`}
              control={control}
              label="Mobile Phone"
              type="tel"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_workEmail`}>
            <FormInput
              name={`${type}.Workemail`}
              control={control}
              label="Work Email"
              type="text"
              required
              disabled
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_personalEmail`}>
            <FormInput
              name={`${type}.personalEmail`}
              control={control}
              label="Personal Email"
              type="text"
              disabled
            />
          </Grid>
          <Grid xs={12} data-testid={`${type}_hasPermission`}>
            <FormCheckbox
              name={`${type}.hasPermission`}
              control={control}
              disabled
              label="Permission to approve and decline advertisements"
            />
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Loader isLoading={advertiserLoading}>
      <Container>
        <Toolbar
          title={
            tab === "Advertisements" && !organizationId
              ? "View Advertisements"
              : "View Advertiser"
          }
          {...(tab === "Advertisements" &&
            (organizationId
              ? !!advertiser?.data?.invoices?.[0]?.paidAt
              : true) && {
              addBtnClick: () =>
                navigate(`/advertisers/${advertiserId}/advertisement/create`),
              addBtnLabel: "Add Advertisement"
            })}
          {...(tab === "Advertiser Details" && {
            backBtnClick: () => navigate("/advertisers"),
            editBtnClick: () => navigate(`/advertisers/${advertiserId}/edit`)
          })}
          {...(permissions.delete &&
            tab == "Advertiser Details" && {
              deleteBtnClick: () => setAdvertiserToDelete(advertiser!.data)
            })}
          tabs={{
            tabs: [
              "Advertiser Details",
              ...(organizationId ? ["Campaigns"] : []),
              "Advertisements",
              ...(organizationId ? ["Invoices"] : [])
            ],
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />

        {tab == "Advertiser Details" && (
          <Form>
            <Grid data-testid="advertiser-view-form" container spacing={3}>
              <Grid xs={12} md={12}>
                {advertiser?.data.logo && (
                  <>
                    <Grid xs={12} md={12} sx={{ paddingLeft: "0px" }}>
                      <StyledFormLabel>
                        <Typography display="inline" variant="formLabel">
                          {"Advertiser Logo"}
                        </Typography>
                      </StyledFormLabel>
                    </Grid>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        <img
                          src={
                            advertiser?.data?.logo?.baseUrl
                              ? advertiser?.data?.logo?.baseUrl +
                                advertiser?.data?.logo?.path
                              : ""
                          }
                          style={{
                            width: "96px",
                            height: "96px"
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                xs={12}
                md={organizationId ? 12 : 6}
                data-testid="advertiser-name"
              >
                <FormInput
                  control={control}
                  name="name"
                  type="text"
                  label={organizationId ? "Organization Name" : "Business Name"}
                  required={true}
                  disabled
                />
              </Grid>
              <Grid
                container={!!organizationId}
                xs={organizationId ? 12 : 6}
                md={organizationId ? 12 : 6}
                direction="row"
              >
                <Grid xs={12} md={6} data-testid="advertiser-category">
                  <FormSelect
                    control={control}
                    name="category"
                    label="Business Category"
                    required={true}
                    disabled
                    isLoading={businessCategoryLoading}
                    options={businessCategoryOptions}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} md={6} data-testid="advertiser-level">
                <FormSelect
                  control={control}
                  name="level"
                  label="Sponsorship Level"
                  required={true}
                  options={levelsOptions}
                  disabled
                  isLoading={levelLoading}
                />
              </Grid>
              <Grid xs={12} md={6} data-testid="advertiser-status">
                <FormSelect
                  control={control}
                  required
                  name="status"
                  label="Status"
                  options={statusoptions}
                  disabled={true}
                />
              </Grid>
            </Grid>

            {organizationId ? (
              <>
                {advertiser?.data?.contacts?.map((c, idx) => (
                  <>
                    <Grid
                      container
                      direction="row"
                      marginTop={idx === 0 ? "18px" : "26px"}
                    >
                      <Grid xs={9} container>
                        <Grid>
                          <FormCheckbox
                            name="primaryContact"
                            control={control}
                            disabled
                          />
                        </Grid>
                        <Grid marginLeft={"5px"}>
                          <FormLabel
                            style={{
                              color: "#000",
                              fontWeight: 400,
                              fontSize: "12px",
                              letterSpacing: "1.2px",
                              opacity: "0.5"
                            }}
                          >
                            {" "}
                            PRIMARY CONTACT
                          </FormLabel>
                        </Grid>
                      </Grid>
                      <HeaderUnderLine />
                    </Grid>
                    <Box key={c.contactId} marginTop={"24px"}>
                      {crmContactDetails(idx)}
                    </Box>
                  </>
                ))}
              </>
            ) : (
              <>
                <Grid container direction="row" marginTop={"10px"}>
                  <Grid xs={9} container sx={{ marginTop: "10px" }}>
                    <Grid>
                      <FormCheckbox
                        name="primaryContact"
                        control={control}
                        disabled
                      />
                    </Grid>
                    <Grid marginLeft={"5px"}>
                      <FormLabel
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "12px",
                          letterSpacing: "1.2px",
                          opacity: "0.5"
                        }}
                      >
                        {" "}
                        PRIMARY CONTACT
                      </FormLabel>
                    </Grid>
                  </Grid>
                </Grid>
                <HeaderUnderLine width="100%" />
                <Box marginTop={"24px"}>{contactDetails("primaryContact")}</Box>
              </>
            )}

            {!organizationId && hasSecondaryContact && (
              <Grid container direction="row" marginTop={"29px"}>
                <Grid xs={9} container>
                  <Grid>
                    <FormCheckbox
                      name="secondaryContact"
                      control={control}
                      disabled
                      onChange={(e) => {
                        setHasSecondaryContact(e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid marginLeft={"5px"}>
                    <FormLabel
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "1.2px",
                        opacity: "0.5"
                      }}
                    >
                      {" "}
                      SECONDARY CONTACT
                    </FormLabel>
                  </Grid>
                </Grid>
                <HeaderUnderLine width="100%" />
              </Grid>
            )}
            {!organizationId && hasSecondaryContact && (
              <Box marginTop={"24px"}>{contactDetails("secondaryContact")}</Box>
            )}
          </Form>
        )}
        {tab == "Advertisements" && <Advertisements />}
        {tab === "Campaigns" && <Capmaigns />}
      </Container>
      <ConfirmationDialog
        open={!!advertiserToDelete}
        title="Delete Advertiser?"
        body={`Are you sure you want to delete ${advertiserToDelete?.businessName}?`}
        close={() => setAdvertiserToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setAdvertiserToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Loader>
  );
};
