import { organizationAtom } from "@recoil/auth";
import React from "react";
import { useRecoilValue } from "recoil";
import { FSGOAdvertiserEdit } from "./FSGOAdvertiserEdit";
import { FSOAdvertiserEdit } from "./FSOAdvertiserEdit";

export const AdvertiserEdit = () => {
  const orgId = useRecoilValue(organizationAtom);
  return !orgId ? <FSGOAdvertiserEdit /> : <FSOAdvertiserEdit />;
};
