export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_REGEX_SPECIAL_CHAR = /[^\d\w]|_/;
export const PASSWORD_REGEX_NUMBER = /\d/;
export const PASSWORD_REGEX_LOWER = /[a-z]/;
export const PASSWORD_REGEX_UPPER = /[A-Z]/;
export const PASSWORD_REGEX_VALIDATE =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\d\w]|_)[\w\W]{8,}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\d{10}$/;
export const WEBSITE_REGEX =
  /^(https?:\/\/|www\.)[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,63}(\/.*)?$/;
export const WEBSITE_REGEX_MANDATORY_HTTPS =
  /^https:\/\/[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,63}(\/.*)?$/;
