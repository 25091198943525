/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useEffect, useState } from "react";
import { FullscreenMediaViewer } from "./FullscreenMediaViewer";
import IconButton from "@mui/material/IconButton";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Fullscreen,
  MoreVert
} from "@mui/icons-material";
import { Form } from "@components/crud/Form";
import {
  MediaContextMenu,
  MoreOption
} from "../../components/MediaContextMenu";
import React from "react";
import ReactPlayer from "react-player";
import { PostToFeed } from "@components/PostToFeed";
import { ShareModal } from "@components/ShareModal";
import { MediaDetails } from "./MediaDetails";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  MediaPhotosVideosAtom,
  MediaPhotosVideosFilterAtom,
  organizationAtom
} from "@recoil/auth";
import {
  ModelMedia,
  useAdminMediaGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { PAGE_SIZE } from "./PhotosVideos";
import { GalleryItemType } from "./GalleryItem";
import { Box } from "@mui/material";

export const PhotoVideoView = () => {
  const { mediaId } = useParams();
  const mediaList = useRecoilValue(MediaPhotosVideosAtom);
  console.log(mediaList);
  const filters = useRecoilValue(MediaPhotosVideosFilterAtom);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Handler for more options click
  const moreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    setAnchorEl(event.currentTarget);
  };

  // Handler to close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [media, setMedia] = useState<ModelMedia>();
  const [mediaIndex, setMediaIndex] = useState<number>();
  const [scrollableMediaId, setScrollableMediaId] = useState(mediaId);
  const [pageNo, setPageNo] = useState(Math.ceil(mediaList.length / PAGE_SIZE));
  const organizationId = useRecoilValue(organizationAtom);
  const setMediaPhotosVideosAtom = useSetRecoilState(MediaPhotosVideosAtom);
  const { data: photosVideos, isLoading: isLoading } = useAdminMediaGet(
    {
      organizationId: organizationId!,
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      ...filters
    },
    {
      query: {
        queryKey: [
          pageNo,
          ...(filters ? [Object.entries(filters).flat()] : [])
        ],
        refetchOnWindowFocus: false
      }
    }
  );
  useEffect(() => {
    const index = mediaList.findIndex((m) => m.id === scrollableMediaId);
    setMedia(mediaList[index]?.mediaDetails);
    setMediaIndex(index);
    if (index >= mediaList.length - 3 && mediaList.length % PAGE_SIZE === 0)
      setPageNo(Math.ceil(mediaList.length / PAGE_SIZE));
  }, [mediaList, scrollableMediaId]);

  useEffect(() => {
    if (photosVideos?.data && !isLoading) {
      const addData = photosVideos.data.media
        ?.filter((m) => m.baseUrl && m.path)
        .map((m) => {
          const path = m.baseUrl! + m.path!;
          return {
            id: m.mediaId!,
            type: m.type! as "IMAGE" | "VIDEO",
            date: m.createdAt,
            thumbnailSrc:
              m.type === "IMAGE" ? path : path.replace(".m3u8", ".0000001.jpg"),
            ...(m.type === "VIDEO" && {
              duration: Math.round(
                (m.metadata as { duration?: number })?.duration || 0
              )
            }),
            mediaDetails: m
          };
        }) as GalleryItemType[];
      setMediaPhotosVideosAtom([
        ...mediaList,
        ...addData.filter((a) => !mediaList.find((m) => m.id === a.id))
      ]);
    }
  }, [photosVideos, isLoading]);

  const [openPostToFeed, setOpenPostToFeed] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // You can change the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      enqueueSnackbar("Error downloading the file");
    }
  };
  return media ? (
    isFullScreen ? (
      <FullscreenMediaViewer
        media={media}
        onClose={() => {
          setIsFullScreen(false);
        }}
        currentIndex={mediaIndex || 0}
        isLastIndex={mediaIndex === mediaList.length - 1}
        onNavigate={(val) => {
          if (val === "prev")
            setScrollableMediaId(mediaList[mediaIndex! - 1]!.id!);
          else setScrollableMediaId(mediaList[mediaIndex! + 1]!.id!);
        }}
      />
    ) : (
      <Container>
        <Toolbar
          title="View Media"
          backBtnLabel="Back to Photos & Videos"
          backBtnClick={() => window.history.back()}
        />
        <Form>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                borderRadius: "12px",
                width: "100%",
                height: "542px",
                position: "relative",
                backgroundColor: "rgba(0,0,0)",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {media.type === "IMAGE" ? (
                <img
                  src={media?.baseUrl ? media?.baseUrl + media?.path : ""}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%"
                  }}
                />
              ) : (
                <Box
                  className="video-player-wrapper"
                  sx={{
                    width: "100%",
                    height: "100%",
                    video: {
                      borderRadius: "12px"
                    }
                  }}
                >
                  <ReactPlayer
                    playing={false}
                    url={media.baseUrl! + media.path!}
                    width="100%"
                    height="100%"
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload noplaybackrate",
                          disablePictureInPicture: true
                        }
                      }
                    }}
                  />
                </Box>
              )}
              {/* Fullscreen Button */}
              <IconButton
                onClick={() => {
                  setIsFullScreen(true);
                }}
                style={{
                  position: "absolute",
                  bottom: media.type === "IMAGE" ? "10px" : "30px",
                  right: media.type === "IMAGE" ? "10px" : "70px",
                  color: "white",
                  zIndex: 1000
                }}
              >
                <Fullscreen
                  htmlColor="white"
                  style={{ height: "35px", width: "35px" }}
                />
              </IconButton>
              {/* More Options Button */}
              <IconButton
                onClick={moreOptionsClick}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                  height: "50px",
                  width: "50px",
                  zIndex: 1000
                }}
              >
                <MoreVert
                  htmlColor="white"
                  style={{ height: "35px", width: "35px" }}
                />
              </IconButton>

              {/* Previous Arrow Button */}
              {mediaIndex !== 0 && (
                <IconButton
                  onClick={() => {
                    setScrollableMediaId(mediaList[mediaIndex! - 1]!.id!);
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1000
                  }}
                >
                  <ArrowBackIos
                    htmlColor="rgba(255,255,255,0.5)"
                    fontSize="large"
                  />
                </IconButton>
              )}

              {/* Next Arrow Button */}
              {mediaIndex !== mediaList.length - 1 && !isLoading && (
                <IconButton
                  onClick={() => {
                    setScrollableMediaId(mediaList[mediaIndex! + 1]!.id!);
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1000
                  }}
                >
                  <ArrowForwardIos
                    htmlColor="rgba(255,255,255,0.5)"
                    fontSize="large"
                  />
                </IconButton>
              )}
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <MediaDetails media={media} />
          </div>
          {/* Media Context Menu */}
          <MediaContextMenu
            id={id}
            open={open}
            handleClose={handleClose}
            anchorEl={anchorEl}
            items={[
              <MoreOption
                key="1"
                onClick={() => {
                  handleClose();
                  setOpenPostToFeed(true);
                }}
              >
                Post to Feed
              </MoreOption>,
              <MoreOption
                key="2"
                onClick={() => {
                  handleClose();
                  downloadFile(media.baseUrl! + media.path!, media.mediaId!);
                }}
              >
                Download
              </MoreOption>,
              <MoreOption
                key="3"
                onClick={() => {
                  handleClose();
                  setOpenShare(true);
                }}
              >
                Share Outside of SportsGravy
              </MoreOption>
            ]}
          />
          {openPostToFeed && (
            <PostToFeed
              onClose={() => {
                setOpenPostToFeed(false);
              }}
              media={[media]}
            />
          )}
          {openShare && (
            <ShareModal
              onClose={() => setOpenShare(false)}
              mediaIds={[media.mediaId!]}
            />
          )}
        </Form>
      </Container>
    )
  ) : (
    <></>
  );
};
