import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { FormProvider, useForm } from "react-hook-form";
import { FSOAdvertiserForm, SelectedAccountContact } from "./FSOAdvertiserForm";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import {
  useAdminAdvertiserCampaignPost,
  useAdminAdvertiserPost,
  useAdminCrmOrgContactContactIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { SendInvoiceModal } from "./SendInvoiceModal";
import colors from "theme/colors";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@utils/capitalize";
import { CampaignForm } from "@pages/campaigns/CampaignForm";

export const FSOAdvertiserCreate = () => {
  const form = useForm({ mode: "onTouched" });
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const [tab, setTab] = useState<"ADVERTISER" | "CAMPAIGN">("ADVERTISER");
  const [isLoading, setLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);

  const [accountContacts, setAccountContacts] = useState<
    SelectedAccountContact[]
  >([]);
  const [logo, setLogo] = useState<File | undefined>(undefined);
  const {
    formState: { isValid },
    getValues,
    setValue,
    watch,
    clearErrors,
    trigger
  } = form;

  const campaignContactId = watch("contactId");

  const { mutate: createAdvertiser, isLoading: isSaving } =
    useAdminAdvertiserPost();

  const { mutate: createCampaign, isLoading: isCreatingCampaign } =
    useAdminAdvertiserCampaignPost();

  const { mutate: contactUpdate, isLoading: contactUpdating } =
    useAdminCrmOrgContactContactIdPut();

  const saveHandler = async () => {
    const values = getValues();

    setLoading(true);
    const filesPromises = await Promise.all(
      [logo].map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          return promise;
        }
      })
    );
    setLoading(false);
    if (filesPromises[0]) values["logoId"] = filesPromises[0];

    const contacts = (values.contact as SelectedAccountContact[]).map(
      (contact) => {
        return {
          contactId: contact.contactId,
          isApprovalRequired: contact.allowApproveDecline || false,
          sendApprovalEmailsToPersonal:
            contact.emailType === "PERSONAL_EMAIL" || false
        };
      }
    );
    const updatedContacts = accountContacts
      .map((contact) => {
        const updatedContact = (
          values.contact as SelectedAccountContact[]
        ).find((c) => c.contactId === contact.contactId);
        if (
          updatedContact &&
          (updatedContact.workEmail !== contact.workEmail ||
            updatedContact.personalEmail !== contact.personalEmail)
        ) {
          return updatedContact;
        }
        return null;
      })
      .filter((contact) => contact !== null);

    createAdvertiser(
      {
        data: {
          organizationId: orgId,
          businessName: values.businessName,
          logoId: values.logoId,
          accountId: values.accountId,
          contacts
        }
      },
      {
        onSuccess: (data) => {
          createCampaign(
            {
              advertiserId: data.data.advertiserId,
              data: {
                name: values.name,
                startDate: values.startDate,
                endDate: values.endDate,
                sponsorshipId: values.sponsorshipId,
                isTaxExempt: values.taxExempt || false,
                billingContactId: values.contactId,
                sendInvoice: values.sendInvoice || false
              }
            },
            {
              onSuccess: () => {
                enqueueSnackbar("Campaign created successfully", {
                  variant: "success"
                });
                if (values.sendInvoice)
                  enqueueSnackbar("Invoice sent successfully", {
                    variant: "success"
                  });
                navigate(`/advertisers`);
              },
              onError: () => {
                enqueueSnackbar("Failed to create campaign", {});
              }
            }
          );
          updatedContacts.forEach((contact) => {
            contactUpdate({
              contactId: contact.contactId,
              data: {
                email: contact.personalEmail,
                firstName: contact.firstName,
                lastName: contact.lastName,
                organizationId: orgId!,
                accounts: [
                  {
                    jobTitle: contact.jobTitle,
                    accountId: values.accountId,
                    workEmail: contact.workEmail
                  }
                ]
              }
            });
          });
          enqueueSnackbar("Advertiser created successfully", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to create advertiser", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title={`Add ${capitalize(tab)}`} />
      <Form>
        <FormProvider {...form}>
          {tab === "ADVERTISER" && (
            <FSOAdvertiserForm
              mode="CREATE"
              setLogo={setLogo}
              setAccountContacts={setAccountContacts}
            />
          )}
          {tab === "CAMPAIGN" && (
            <CampaignForm
              mode="CREATE"
              contactOptions={(
                getValues("contact") as SelectedAccountContact[]
              ).map((contact) => {
                return {
                  label: `${contact.firstName} ${contact.lastName}`,
                  value: contact.contactId,
                  email: contact.workEmail
                };
              })}
            />
          )}
        </FormProvider>
      </Form>
      {tab === "ADVERTISER" && (
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={() => {
            const date = new Date();
            setTab("CAMPAIGN");
            setValue(
              "name",
              `${date.getFullYear()} - ${date.getFullYear() + 1} Campaign`
            );
          }}
          isDisabled={
            !isValid ||
            isSaving ||
            isLoading ||
            !accountContacts.some((contact) => contact.allowApproveDecline) ||
            contactUpdating
          }
          isLoading={isSaving || isLoading || contactUpdating}
          saveBtnLabel="Save & Add Campaign"
        />
      )}
      {tab === "CAMPAIGN" && (
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          additionalBtns={[
            <Button
              key="save"
              variant="admin-secondary"
              type="button"
              style={{
                border: `2px solid ${isValid ? colors.primary.main : colors.grey.main}`
              }}
              disabled={
                isSaving ||
                isLoading ||
                contactUpdating ||
                !isValid ||
                isCreatingCampaign
              }
              isLoading={
                isSaving || isLoading || contactUpdating || isCreatingCampaign
              }
              onClick={saveHandler}
            >
              Save
            </Button>,
            <Button
              key="save&send"
              variant="admin-primary"
              type="button"
              disabled={
                isSaving ||
                isLoading ||
                contactUpdating ||
                !isValid ||
                isCreatingCampaign
              }
              isLoading={
                isSaving || isLoading || contactUpdating || isCreatingCampaign
              }
              onClick={() => {
                setValue(
                  "workEmail",
                  (getValues("contact") as SelectedAccountContact[]).find(
                    (contact) => contact.contactId === campaignContactId
                  )?.workEmail
                );
                setShowSendDialog(true);
              }}
            >
              Save & Send Invoice
            </Button>
          ]}
        />
      )}
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/advertisers`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {showSendDialog && (
        <FormProvider {...form}>
          <SendInvoiceModal
            onClose={() => {
              setShowSendDialog(false);
              clearErrors("workEmail");
              trigger();
            }}
            onSave={(contact) => {
              setValue("contactId", contact.contactId);
              setValue("sendInvoice", true);
              setShowSendDialog(false);
              saveHandler();
            }}
            title="Send Invoice"
            type="SEND"
            contactSelected={{
              value: campaignContactId,
              email: (getValues("contact") as SelectedAccountContact[]).find(
                (contact) => contact.contactId === campaignContactId
              )?.workEmail,
              label:
                (getValues("contact") as SelectedAccountContact[]).find(
                  (contact) => contact.contactId === campaignContactId
                )?.firstName +
                " " +
                (getValues("contact") as SelectedAccountContact[]).find(
                  (contact) => contact.contactId === campaignContactId
                )?.lastName,
              contact: (getValues("contact") as SelectedAccountContact[]).find(
                (contact) => contact.contactId === campaignContactId
              )
            }}
          />
        </FormProvider>
      )}
    </Container>
  );
};
