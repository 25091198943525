import { TableViewInfinite } from "@components/TableViewInfinite";
import { getReleases } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { RELEASE_STATUS } from "@utils/constants";

export const Releases = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onAdd = () => {
    navigate(`/releases/create`);
  };

  const onEdit = (release) => {
    navigate(`/releases/${release.releaseId}/edit`);
  };
  const onView = (release) => {
    navigate(`/releases/${release.releaseId}`);
  };
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      const del = await checkPermission("tech.test-cases", "VIEW");
      setPermissions({
        create,
        edit,
        view: del
      });
    };
    fetchPermissions();
  }, [organizationId]);
  const filterConfig = {
    field: "filter",
    defaultValue: "IN_PROGRESS",
    options: RELEASE_STATUS
  };
  const LIST_COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      minWidth: 250
    },
    {
      headerName: "Related Release",
      field: "relatedRelease",
      minWidth: 250,
      valueGetter: ({ row }) => row.duplicateOf?.name || "-",
      sortable: false
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 250,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 190,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.releaseStatus == "IN_PROGRESS") {
          const testcaseStatus = params.row.testcaseStatus;
          return (
            <div style={{ padding: "20px 0" }}>
              <div>
                Total Features - {testcaseStatus.totalFeatures.toLocaleString()}
              </div>
              <div>
                Features Passed -{" "}
                {testcaseStatus.totalFeaturesPassed.toLocaleString()}
              </div>
              <div>
                Total Test Cases -{" "}
                {(
                  testcaseStatus.testcasePassed +
                  testcaseStatus.testcaseNotTested +
                  testcaseStatus.testcaseFailed +
                  testcaseStatus.testcaseHold +
                  testcaseStatus.testcaseBlocked
                ).toLocaleString()}
              </div>
              <div>
                Test Cases Passed -{" "}
                {testcaseStatus.testcasePassed.toLocaleString()}
              </div>
              <div>
                Test Cases Not Tested -{" "}
                {testcaseStatus.testcaseNotTested.toLocaleString()}
              </div>
              <div>
                Test Cases Failed -{" "}
                {testcaseStatus.testcaseFailed.toLocaleString()}
              </div>
              <div>
                Test Cases Onhold -{" "}
                {testcaseStatus.testcaseHold.toLocaleString()}
              </div>
              <div>
                Test Cases Blocked -{" "}
                {testcaseStatus.testcaseBlocked.toLocaleString()}
              </div>
            </div>
          );
        }
        if (params.row.releaseStatus === "RELEASED") {
          const dateFormat = (date) => {
            const options = {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
              timeZoneName: "short"
            };
            //@ts-ignore
            return new Intl.DateTimeFormat("en-US", options).format(
              new Date(date)
            );
          };
          return (
            <div style={{ padding: "20px 0" }}>
              <div>Released On </div>
              <div>{dateFormat(params.row.updatedAt)}</div>
            </div>
          );
        }

        if (params.row.releaseStatus === "READY_TO_RELEASE") {
          return (
            <div style={{ padding: "20px 0" }}>
              <div>Ready To Release </div>
            </div>
          );
        }
      }
    }
  ];
  return (
    <>
      <TableViewInfinite
        title="Releases"
        useGet={getReleases}
        isDeleteDisabled={() => true}
        columns={LIST_COLUMNS}
        searchable={true}
        getRowId={(row) => row.releaseId}
        getRowHeight={() => "auto"}
        filterConfig={filterConfig}
        addBtnText="Add Release"
        onAdd={permissions.create ? onAdd : undefined}
        onEdit={onEdit}
        onView={onView}
        hidePlatformFilter={true}
        isEditDisabled={() => permissions.edit}
        hideFooter
      />
    </>
  );
};
