import { Container } from "@components/crud/Container";
import { RenderTableView } from "@components/RenderTableView";
import { ToolTip } from "@components/ToolTip";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { Button } from "@components/Button";
import { SearchInput } from "@components/SearchInput";
import { useEffect, useState } from "react";
import { NoRecords } from "@components/NoRecords";
import { useNavigate } from "react-router-dom";
import { LEAD_SOURCES, LEAD_STATUS } from "@utils/constants";
import { Form } from "@components/crud/Form";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AccountLeads = ({
  leads,
  onClickAdd
}: {
  leads;
  onClickAdd: () => void;
}) => {
  const [textSearch, setTextSearch] = useState("");
  const [rows, setRows] = useState(leads);

  useEffect(() => {
    const filteredRows = leads.filter((row) => {
      return (
        row.name && row.name.toLowerCase().includes(textSearch.toLowerCase())
      );
    });
    setRows(filteredRows);
  }, [textSearch, leads]);

  const navigate = useNavigate();
  const LEADS_COLUMNS = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0px", display: "flex" }}>
            <IconButton
              onClick={() => {
                navigate(`/crm/leads/${params.row.leadId}`);
              }}
            >
              <ToolTip title="View Lead" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`/crm/leads/${params.row.leadId}/edit`);
              }}
            >
              <ToolTip title="Edit Lead" placement="top">
                <Edit style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 350,
      valueGetter: ({ row }) => row.name,
      renderCell: (params) => <TwoLineText>{params.row.name}</TwoLineText>
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) =>
        LEAD_SOURCES.find((l) => l.value === row.source?.type)?.label
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) =>
        LEAD_STATUS.find((l) => l.value === row.status)?.label
    },
    {
      field: "opportunities",
      headerName: "Opportunities",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        if (params.row.opportunities?.[0]?.opportunityId) {
          return (
            <div
              onClick={() => {
                navigate(
                  `/crm/opportunities/${params.row.opportunities?.[0]?.opportunityId}`
                );
              }}
            >
              <Typography style={{ color: "#007AFF", cursor: "pointer" }}>
                {params.row.opportunities?.[0]?.name || ""}
              </Typography>
            </div>
          );
        } else return <></>;
      }
    }
  ];

  return (
    <Container>
      <Form sx={{ padding: "0px !important" }}>
        <StyledBox>
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 300,
                fontSize: "32px"
              }}
            >
              Leads
            </Typography>
            <Button
              variant="admin-primary"
              startIcon={<Add style={{ height: "27px", width: "27px" }} />}
              onClick={onClickAdd}
            >
              Add
            </Button>
          </div>
          <SearchInput
            required={false}
            placeholder="Search"
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </StyledBox>
        <RenderTableView
          title=""
          hideToolbar
          rows={rows}
          columns={LEADS_COLUMNS}
          getRowId={(row) => row.leadId}
          hasActionColumn={false}
          hideFooter
          sortingMode="client"
          getRowHeight={() => "auto"}
          noRecordsFoundElemet={
            <NoRecords
              title="The list is empty"
              description="Add lead related to this account"
              buttonClick={onClickAdd}
              buttonText="Add Lead"
              buttonIcon={<Add />}
            />
          }
        />
      </Form>
    </Container>
  );
};
