import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect, useMemo, useState } from "react";
import { CampaignForm } from "./CampaignForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAdminAdvertiserCampaignCampaignIdGet,
  useAdminAdvertiserCampaignCampaignIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { Button } from "@components/Button";
import colors from "theme/colors";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { SendInvoiceModal } from "@pages/advertiser/SendInvoiceModal";
import { enqueueSnackbar } from "notistack";

export const CampaignEdit = () => {
  const [editableFields, setEditableFields] = useState<string[]>([]);
  const form = useForm({ mode: "onBlur" });
  const {
    reset,
    getValues,
    formState: { isValid, isDirty },
    watch,
    setValue,
    clearErrors,
    trigger
  } = form;
  const { advertiserId, campaignId } = useParams();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);

  const { data: campaign, isLoading: isCampaignLoading } =
    useAdminAdvertiserCampaignCampaignIdGet(advertiserId!, campaignId!);

  const { mutate: editCampaign, isLoading: isEditingCampaign } =
    useAdminAdvertiserCampaignCampaignIdPut();

  const contactId = watch("contactId");

  useEffect(() => {
    if (campaign?.data) {
      const campaignData = campaign?.data;
      reset({
        account: campaignData.advertiser?.account?.name,
        advertiser: campaignData.advertiser?.account?.name,
        name: campaignData.name,
        startDate: campaignData.startDate
          ? new Date(campaignData.startDate)
          : undefined,
        endDate: campaignData.endDate
          ? new Date(campaignData.endDate)
          : undefined,
        sponsorshipId: campaignData.sponsorshipId,
        contactId: campaignData.billingContactId,
        taxExempt: campaignData.isTaxExempt
      });
      if (campaignData.hasInvoiceSent) {
        if (campaignData.invoice?.status === "PAID") {
          const isStartDateReached =
            new Date() > new Date(campaignData.startDate as Date);
          if (isStartDateReached) setEditableFields(["name"]);
          else setEditableFields(["name", "startDate"]);
        } else setEditableFields(["name", "startDate"]);
      } else
        setEditableFields([
          "name",
          "startDate",
          "sponsorshipId",
          "contactId",
          "taxExempt"
        ]);
    }
  }, [campaign]);

  const contactOptions = useMemo(
    () =>
      campaign?.data?.advertiser?.account?.contacts?.map((contact) => ({
        label: `${contact?.contact?.firstName} ${contact?.contact?.lastName}`,
        value: contact?.contactId,
        email: contact?.workEmail,
        contact: contact
      })) || [],
    [campaign]
  );

  const saveHandler = async () => {
    const values = getValues();
    editCampaign(
      {
        campaignId: campaignId!,
        advertiserId: advertiserId!,
        data: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
          isTaxExempt: values.taxExempt,
          billingContactId: values.contactId,
          sponsorshipId: values.sponsorshipId,
          sendInvoice: values.sendInvoice || false
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Campaign edited successfully", {
            variant: "success"
          });
          if (values.sendInvoice)
            enqueueSnackbar("Invoice sent successfully", {
              variant: "success"
            });
          navigate(`/advertisers/${advertiserId}/campaign/${campaignId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit campaign", {
            variant: "error"
          });
        }
      }
    );
  };

  const contact = useMemo(() => {
    return contactOptions.find((contact) => contact.value === contactId);
  }, [contactId]);

  return (
    <Container>
      <Toolbar title="Edit Campaign" />
      <Loader isLoading={isCampaignLoading}>
        <Form>
          <FormProvider {...form}>
            <CampaignForm
              contactOptions={contactOptions}
              mode="EDIT"
              editableFields={editableFields}
            />
          </FormProvider>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          additionalBtns={[
            <Button
              key="save"
              variant="admin-secondary"
              type="button"
              style={{
                border: `2px solid ${colors.primary.main}`
              }}
              onClick={saveHandler}
              isLoading={isEditingCampaign}
              disabled={isEditingCampaign || !isValid || !isDirty}
            >
              Save
            </Button>,
            ...(!campaign?.data.hasInvoiceSent
              ? [
                  <Button
                    key="save&send"
                    variant="admin-primary"
                    type="button"
                    isLoading={isEditingCampaign}
                    disabled={isEditingCampaign || !isValid || !isDirty}
                    onClick={() => {
                      setValue(
                        "workEmail",
                        contactOptions.find(
                          (contact) => contact.value === contactId
                        )?.email
                      );
                      setShowSendDialog(true);
                    }}
                  >
                    Save & Send Invoice
                  </Button>
                ]
              : [])
          ]}
        />
        {showSendDialog && (
          <FormProvider {...form}>
            <SendInvoiceModal
              onClose={() => {
                clearErrors("workEmail");
                trigger();
                setShowSendDialog(false);
              }}
              title="Send Invoice"
              advertiserId={advertiserId!}
              type="SEND"
              contactSelected={{
                value: contactId,
                email: contactOptions.find(
                  (contact) => contact.value === contactId
                )?.email,
                label: contactOptions.find(
                  (contact) => contact.value === contactId
                )?.label,
                contact: {
                  firstName: contact?.contact?.contact?.firstName as string,
                  lastName: contact?.contact?.contact?.lastName as string,
                  personalEmail: contact?.contact?.contact?.email as string,
                  workEmail: contact?.contact?.workEmail as string,
                  accountId: contact?.contact?.accountId as string,
                  contactId: contactId,
                  jobTitle: contact?.contact?.jobTitle as string
                }
              }}
              onSave={(contact) => {
                setValue("contactId", contact.contactId);
                setValue("sendInvoice", true);
                setShowSendDialog(false);
                saveHandler();
              }}
            />
          </FormProvider>
        )}
      </Loader>

      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/advertisers/${advertiserId}?tab=Campaigns`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
