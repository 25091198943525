import { Loader } from "@components/crud/Loader";
import { hasPermission } from "@services/Casbin";
import {
  ModelOrder,
  useAdminCrmOrderIdResendEmail
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, styled, Typography } from "@mui/material";
import {
  Add,
  ContentCopyOutlined,
  Edit,
  InfoOutlined,
  Visibility
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import formatFullName from "@utils/formatFullName";
import { formatCurrency } from "@utils/formatCurrency";
import { FullName } from "@utils/types";
import { enqueueSnackbar } from "notistack";
import EmailResendIcon from "@assets/icons/emailResendIcon.svg";
import { RenderTableView } from "@components/RenderTableView";
import { Container } from "@components/crud/Container";
import { Button } from "@components/Button";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { NoRecords } from "@components/NoRecords";
import { Form } from "@components/crud/Form";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const AccountOrders = ({
  orders,
  onClickAdd
}: {
  orders: ModelOrder[];
  onClickAdd: () => void;
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<"PENDING" | "APPROVED" | "ALL">("ALL");
  const [rows, setRows] = useState(orders);
  const [textSearch, setTextSearch] = useState("");

  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false,
    approval: false
  });
  const onEdit = (order) => navigate(`/crm/orders/${order.orderId}/edit`);
  const onView = (order) => navigate(`/crm/orders/${order.orderId}`);

  const mutation = useAdminCrmOrderIdResendEmail();

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.orders", "ADD");
      const edit = await checkPermission("crm.orders", "EDIT");
      const view = await checkPermission("crm.orders", "VIEW");
      const approval = await checkPermission(
        "crm.orders-resend-approval",
        "ON"
      );
      const permission = {
        create,
        edit,
        view,
        approval
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const copyToClipboard = (text, type) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        enqueueSnackbar(`${type} copied to clipboard`, {
          variant: "success"
        });
      });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (successful)
          enqueueSnackbar(`${type} copied to clipboard`, {
            variant: "success"
          });
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  const ORDERS_COLUMNS: GridColDef<ModelOrder>[] = [
    {
      headerName: "Action",
      field: "action",
      maxWidth: 150,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Action</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Order" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Order" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {params.row.status === "PENDING" && permissions.approval && (
              <Loader
                isLoading={
                  mutation.isLoading &&
                  mutation.variables?.orderId === params.row.orderId
                }
              >
                <IconButton
                  onClick={() => {
                    mutation.mutate(
                      { orderId: params.row.orderId },
                      {
                        onSuccess: () =>
                          enqueueSnackbar("Approval Email Resent", {
                            variant: "success"
                          }),
                        onError: () =>
                          enqueueSnackbar("Failed to Resend Approval Email", {
                            variant: "error"
                          })
                      }
                    );
                  }}
                >
                  <ToolTip
                    title="Click to Resend Approval Email"
                    placement="top"
                  >
                    <img src={EmailResendIcon} style={IconStyle} />
                  </ToolTip>
                </IconButton>
              </Loader>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Order ID",
      field: "orderId",
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <div style={{ paddingLeft: "3px", fontWeight: 700 }}>Order ID</div>
      ),
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span style={{ minWidth: "210px" }}>{params.row.orderId}</span>
            <ToolTip title="Copy Order ID to Clipboard" placement="top">
              <IconButton
                onClick={() => copyToClipboard(params.row.orderId, "Order Id")}
              >
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
            </ToolTip>
          </div>
        );
      }
    },
    {
      headerName: "Order Date",
      field: "orderDate",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatDateForDisplay(row.date)
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        [
          {
            label: "Approved",
            value: "APPROVED"
          },
          {
            label: "Pending",
            value: "PENDING"
          }
        ].find((t) => t.value === row.status)?.label || ""
    },
    {
      headerName: "Estimated Amount",
      field: "amount",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.totalAmount,
      renderCell: (params) => {
        return (
          <Grid container spacing="10px">
            <Grid item width="50%">
              {formatCurrency(params.row.totalAmount!)}
            </Grid>
            <Grid item>
              <ToolTip
                title={
                  <>
                    <span>
                      No. of Yearly Athlete Registrations:
                      {params.row.initialNumberOfAthletes}
                    </span>
                    <br />
                    <span>{`$ ${params.row.ratePerAthlete} per Athlete, per Registration`}</span>
                  </>
                }
                placement="top"
              >
                <InfoOutlined />
              </ToolTip>
            </Grid>
          </Grid>
        );
      }
    },

    {
      headerName: "Contact",
      field: "contact",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.contact ? formatFullName(row.contact as FullName) : "",
      flex: 1
    },
    {
      headerName: "Order Submitted By",
      field: "orderSubmittedBy",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) =>
        row.submittedBy?.person ? formatFullName(row.submittedBy.person) : ""
    }
  ];

  useEffect(() => {
    if (filter === "ALL") {
      setRows(orders);
    }
    if (filter === "APPROVED") {
      setRows(orders.filter((order) => order.status === "APPROVED"));
    }
    if (filter === "PENDING") {
      setRows(orders.filter((order) => order.status === "PENDING"));
    }
  }, [filter, orders]);

  useEffect(() => {
    if (orders && textSearch !== "") {
      const filteredRows = orders.filter((row) => {
        return (
          row?.orderId &&
          row?.orderId.toLowerCase().includes(textSearch.toLowerCase())
        );
      });
      setRows(filteredRows);
    }
    if (orders && textSearch === "") {
      setRows(orders);
    }
  }, [textSearch, orders]);

  return (
    <Container>
      <Form sx={{ padding: "0px !important" }}>
        <StyledBox>
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 300,
                fontSize: "32px"
              }}
            >
              Orders
            </Typography>
            {permissions.create && (
              <Button
                variant="admin-primary"
                startIcon={<Add style={{ height: "27px", width: "27px" }} />}
                onClick={onClickAdd}
              >
                Add
              </Button>
            )}
          </div>
          <Grid container>
            <Grid xs={4} gap="8px">
              <Typography>View</Typography>
              <FormSelect
                sx={{ width: "95%", marginRight: "24px" }}
                name=""
                required={false}
                options={[
                  {
                    label: "All",
                    value: "ALL"
                  },
                  {
                    label: "Orders Approved",
                    value: "APPROVED"
                  },
                  {
                    label: "Orders Pending Approval",
                    value: "PENDING"
                  }
                ]}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
            </Grid>
            <Grid xs={8}>
              <SearchInput
                required={false}
                placeholder="Search"
                onChange={(e) => setTextSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        </StyledBox>
        <Loader isLoading={isLoadingPermissions}>
          <RenderTableView
            title=""
            rows={rows}
            hideToolbar
            hideFooter
            columns={ORDERS_COLUMNS}
            getRowId={(row) => row.orderId}
            isDeleteDisabled={() => true}
            getRowHeight={() => "auto"}
            hasActionColumn={false}
            sortingMode="client"
            noRecordsFoundElemet={
              <NoRecords
                title="The list is empty"
                description="Add order related to this account"
                buttonClick={onClickAdd}
                buttonText="Add Order"
                buttonIcon={<Add />}
              />
            }
          />
        </Loader>
      </Form>
    </Container>
  );
};
