import { Button } from "@components/Button";
import { InvoiceReceipt } from "@components/InvoiceReceipt";
import { styled, Typography } from "@mui/material";
import ReactPDF, { usePDF } from "@react-pdf/renderer";
import { AdvertiserInvoiceSchemaPaymentType } from "@sportsgravyengineering/sg-api-react-sdk";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { formatCurrency } from "@utils/formatCurrency";
import React from "react";

const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 100%;
  max-width: 650px;
  padding: 40px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
`;

const HeaderText = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
`;

const TitleText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
  @media (max-width: 567px) {
    font-size: 14px;
  }
`;

const DescriptionText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
  @media (max-width: 567px) {
    font-size: 14px;
  }
`;

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export type PaymentConfirmationDialogProps = {
  organization: {
    name: string;
    phone: string;
    address: string;
    email: string;
  };
  to: string;
  paymentDetails: {
    type: AdvertiserInvoiceSchemaPaymentType;
    number: string;
    transactionId: string;
  };
  paymentDate: string | Date | undefined;
  amount: number;
  total: number;
  invoiceId: string;
};

const formatDate = (isoDate: string | Date | undefined): string => {
  if (!isoDate) return "";
  const date = new Date(isoDate);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric"
  });
};

const PaymentConfirmation = ({
  confirmationDetails,
  invoiceInstance,
  confirmationType
}: {
  confirmationDetails: PaymentConfirmationDialogProps;
  invoiceInstance: ReactPDF.UsePDFInstance;
  confirmationType: "SUCCESS" | "FAIL";
}) => {
  if (!confirmationDetails) return null;
  const [receiptInstance] = usePDF({
    document: (
      <InvoiceReceipt
        hasHeader={false}
        hasFooter={false}
        receiptDetails={{
          amount: confirmationDetails.total,
          invoiceAmount: confirmationDetails.amount,
          invoiceNumber: confirmationDetails.invoiceId,
          to: confirmationDetails.to,
          status: "PAID",
          paymentMethod:
            `${
              CRM_ORDER_PAYMENT_TYPES.find(
                (c) => c.value === confirmationDetails.paymentDetails.type
              )?.label
            } - ${confirmationDetails.paymentDetails.number}` || "",
          transactionId: confirmationDetails.paymentDetails?.transactionId,
          from: {
            name: confirmationDetails.organization.name,
            email: confirmationDetails.organization.email,
            address: confirmationDetails.organization.address,
            phone: confirmationDetails.organization.phone
          }
        }}
      />
    )
  });
  return (
    <>
      <StyledDiv
        style={{
          borderTop: `8px solid ${confirmationType === "SUCCESS" ? "#1abc9c" : "#E82C2C"}`
        }}
      >
        <FlexBox style={{ justifyContent: "center" }}>
          <HeaderText
            style={{
              color: confirmationType === "SUCCESS" ? "#1abc9c" : "#E82C2C"
            }}
          >
            Payment {confirmationType === "SUCCESS" ? "Success" : "Failed"}!
          </HeaderText>
        </FlexBox>
        <FlexBox
          style={{ marginTop: "32px", flexDirection: "column", gap: "16px" }}
        >
          <FlexBox>
            <TitleText>Transaction ID</TitleText>
            <DescriptionText>
              {confirmationDetails.paymentDetails.transactionId?.slice(-10)}
            </DescriptionText>
          </FlexBox>
          <FlexBox>
            <TitleText>Invoice Amount</TitleText>
            <DescriptionText>
              {formatCurrency(confirmationDetails.amount)}
            </DescriptionText>
          </FlexBox>
          <FlexBox>
            <TitleText>Total</TitleText>
            <TitleText style={{ fontWeight: 600 }}>
              {formatCurrency(confirmationDetails.total)}
            </TitleText>
          </FlexBox>
          <FlexBox>
            <TitleText>Payment Method</TitleText>
            <DescriptionText style={{ textAlign: "end" }}>
              {
                CRM_ORDER_PAYMENT_TYPES.find(
                  (c) => c.value === confirmationDetails.paymentDetails.type
                )?.label
              }
              {" - "}
              {confirmationDetails.paymentDetails.number}
            </DescriptionText>
          </FlexBox>

          <FlexBox>
            <TitleText>Payment Date</TitleText>
            <DescriptionText>
              {formatDate(confirmationDetails.paymentDate)}
            </DescriptionText>
          </FlexBox>

          <FlexBox>
            <TitleText>Merchant</TitleText>
            <DescriptionText style={{ textAlign: "end" }}>
              {confirmationDetails.organization.name}
            </DescriptionText>
          </FlexBox>
        </FlexBox>
        {confirmationType === "SUCCESS" && (
          <FlexBox
            style={{ marginTop: "32px", flexDirection: "column", gap: "12px" }}
          >
            <Button
              variant="admin-primary"
              onClick={() =>
                downloadPDFFromLink(
                  receiptInstance.url,
                  `${confirmationDetails.invoiceId}-receipt.pdf`
                )
              }
            >
              Download Receipt
            </Button>
            <Button
              variant="admin-secondary"
              onClick={() =>
                downloadPDFFromLink(
                  invoiceInstance.url,
                  `${confirmationDetails.invoiceId}.pdf`
                )
              }
            >
              Download Invoice
            </Button>
          </FlexBox>
        )}
        <FlexBox style={{ justifyContent: "center" }}>
          <Typography style={{ fontSize: "12px", color: "#666" }}>
            &copy; {new Date().getFullYear()}. SportsGravy. All rights reserved.
          </Typography>
        </FlexBox>
      </StyledDiv>
    </>
  );
};

export default PaymentConfirmation;
