import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect, useMemo, useState } from "react";
import { CampaignForm } from "./CampaignForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  ModelAdvertiserCampaign,
  useAdminAdvertiserCampaignCampaignIdDelete,
  useAdminAdvertiserCampaignCampaignIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";

export const CampaignView = () => {
  const form = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const { reset } = form;
  const [campaignToDelete, setCampaignToDelete] = useState<
    ModelAdvertiserCampaign | undefined
  >(undefined);
  const { advertiserId, campaignId } = useParams();

  const { data: campaign, isLoading: isCampaignLoading } =
    useAdminAdvertiserCampaignCampaignIdGet(advertiserId!, campaignId!);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminAdvertiserCampaignCampaignIdDelete();

  useEffect(() => {
    if (campaign?.data) {
      const campaignData = campaign?.data;
      reset({
        account: campaignData.advertiser?.account?.name,
        name: campaignData.name,
        startDate: campaignData.startDate
          ? new Date(campaignData.startDate)
          : undefined,
        endDate: campaignData.endDate
          ? new Date(campaignData.endDate)
          : undefined,
        sponsorshipId: campaignData.sponsorshipId,
        contactId: campaignData.billingContactId,
        taxExempt: campaignData.isTaxExempt,
        advertiser: campaignData.advertiser?.account?.name
      });
    }
  }, [campaign]);

  const contactOptions = useMemo(() => {
    const contact = campaign?.data.billingContact;
    if (contact) {
      return [
        {
          label: contact.firstName + " " + contact.lastName,
          value: contact.contactId
        }
      ];
    } else {
      return [];
    }
  }, [campaign]);

  const onConfirmDelete = async () => {
    if (!campaignToDelete?.campaignId) return;
    try {
      await deleteAsync({
        advertiserId: advertiserId!,
        campaignId: campaignToDelete?.campaignId
      });
      enqueueSnackbar("Campaign deleted successfully", { variant: "success" });
      setCampaignToDelete(undefined);
      navigate(`/advertisers/${advertiserId}?tab=Campaigns`);
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete campaign.", {
        variant: "error"
      });
      setCampaignToDelete(undefined);
    }
  };

  return (
    <Container>
      <Loader isLoading={isCampaignLoading}>
        <Toolbar
          title="View Campaign"
          backBtnClick={() =>
            navigate(`/advertisers/${advertiserId}?tab=Campaigns`)
          }
          editBtnClick={() => navigate(`edit`)}
          {...(!campaign?.data.hasInvoiceSent && {
            deleteBtnClick: () => setCampaignToDelete(campaign?.data)
          })}
        />
        <Form>
          <FormProvider {...form}>
            <CampaignForm contactOptions={contactOptions} mode="VIEW" />
          </FormProvider>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!campaignToDelete}
        title="Delete Campaign?"
        body={`Are you sure you want to delete ${campaignToDelete?.name}?`}
        close={() => setCampaignToDelete(undefined)}
        onConfirm={onConfirmDelete}
        onCancel={() => setCampaignToDelete(undefined)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />{" "}
    </Container>
  );
};
