import { Box } from "@mui/system";
import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import { IconButton, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Header, HeaderText, MainContainer } from "@components/ModalComponents";
import { CloseIcon } from "@components/Icons";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  min-height: 320px;
  .document {
    padding-right: 33px;
  }
`;

const StyledPage = styled(Page)`
  &.pdf-page {
    background-color: #efefef !important;
    canvas {
      width: 300px !important;
      height: 430px !important;
      border-radius: 5px;
    }
  }
  &.modal {
    background-color: #fff !important;
    canvas {
      width: 100% !important;
      height: 60vh !important;
    }
  }
`;

const PageCount = styled(Box)`
  z-index: 1;
  font-weight: 400;
  font-size: 11px;
  padding: 10px 0;
`;

const CurrentPage = styled(Box)`
  padding: 10px;
`;

const StyledPageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: auto;
  width: calc(100% + 24px);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FeedPDFViewer = ({ src }: { src: ModelMedia }) => {
  const [numPages, setNumPages] = useState<number>();
  const [openPDFModal, setOpenPDFModal] = useState<boolean>(false);
  const [currPage, setCurrPage] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          position: "relative",
          left: "-15px",
          width: "calc(100% + 45px)",
          backgroundColor: "#EFEFEF"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px"
          }}
        >
          <PageCount>
            <Grid container>
              <Grid>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px"
                  }}
                >
                  {src?.metadata?.documentName}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    marginLeft: "8px",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#666"
                  }}
                >
                  {numPages}
                  {numPages !== undefined && numPages > 1 ? " Pages" : " Page"}
                </Typography>
              </Grid>
            </Grid>
          </PageCount>
          <CurrentPage>
            <Typography
              style={{ fontWeight: 400, fontSize: "10px", color: "#666" }}
            >
              Page 1
            </Typography>
          </CurrentPage>
        </div>
        <StyledBox
          style={{
            display: !numPages ? "flex" : "block",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Document
            className={"document"}
            file={src?.baseUrl && src?.path ? src?.baseUrl + src?.path : ""}
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<LoadingSpinner />}
            onClick={() => setOpenPDFModal(true)}
          >
            <StyledPageContainer
              style={{
                justifyContent:
                  numPages && numPages < 2 ? "center" : "flex-start"
              }}
            >
              {[...Array(numPages)].map((_, index) => (
                <StyledPage
                  loading={<LoadingSpinner />}
                  key={index}
                  className={"pdf-page"}
                  scale={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  pageNumber={index + 1}
                  width={300}
                  height={300}
                  devicePixelRatio={5}
                />
              ))}
            </StyledPageContainer>
          </Document>
        </StyledBox>
      </div>
      {openPDFModal && numPages && (
        <Modal open>
          <MainContainer
            style={{ paddingBottom: 0, width: "70vw", maxWidth: "960px" }}
          >
            <Header>
              <HeaderText style={{ color: "#fff" }}></HeaderText>
              <IconButton onClick={() => setOpenPDFModal(false)}>
                <CloseIcon />
              </IconButton>
            </Header>
            <div
              style={{
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
              }}
            >
              {currPage > 1 && (
                <IconButton
                  onClick={() => setCurrPage((prev) => Math.max(1, prev - 1))}
                  style={{
                    position: "absolute",
                    left: "16px",
                    zIndex: 10,
                    backgroundColor: "rgba(0, 0, 0, 0.3)"
                  }}
                >
                  <ChevronLeft
                    style={{ width: "36px", height: "36px", color: "#fff" }}
                  />
                </IconButton>
              )}

              <Document
                file={src?.baseUrl && src?.path ? src?.baseUrl + src?.path : ""}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<LoadingSpinner />}
              >
                <StyledPage
                  key={currPage}
                  className={"pdf-page modal"}
                  scale={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  pageNumber={currPage}
                  width={300}
                  height={300}
                  devicePixelRatio={5}
                />
              </Document>

              {currPage < numPages && (
                <IconButton
                  onClick={() =>
                    setCurrPage((prev) => Math.min(numPages, prev + 1))
                  }
                  style={{
                    position: "absolute",
                    right: "16px",
                    zIndex: 10,
                    backgroundColor: "rgba(0, 0, 0, 0.3)"
                  }}
                >
                  <ChevronRight
                    style={{ width: "36px", height: "36px", color: "#fff" }}
                  />
                </IconButton>
              )}
            </div>
          </MainContainer>
        </Modal>
      )}
    </>
  );
};

export default memo(FeedPDFViewer);
