import { Content, Root } from "@mui-treasury/layout";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { personAtom, signupTokenAtom } from "@recoil/signup";
import { authSignUpPersonalGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { setRecoil } from "recoil-nexus";
import sc from "styled-components";
import FootBallPlayerImage from "../assets/images/footballPlayerImage.png";
import FootBallPlayerImage1 from "../assets/images/footballPlayerImage1.png";
import BaseballPlayerImage from "../assets/images/baseballPlayerImage.png";
import BaseballPlayerImage1 from "../assets/images/baseballPlayerImage1.png";
import BasketballPlayerImage from "../assets/images/basketballPlayerImage.png";
import GolfPlayerImage from "../assets/images/golfPlayerImage.png";
import IceHockeyPlayerImage from "../assets/images/iceHockeyPlayerImage.png";
import LacrossePlayerImage from "../assets/images/lacrossePlayerImage.png";
import TennisPlayerImage from "../assets/images/tennisPlayerImage.png";
import VolleyballPlayerImage from "../assets/images/volleyballPlayerImage.png";
import SGLogo from "../assets/images/sg-logo.png";

const PageContainer = styled(Box)`
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.default};
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding: 0rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 4rem;
  }
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.palette.white.main};
  position: relative;
  z-index: 10;
  ${(props) => props.theme.breakpoints.up("xs")} {
    width: 100%;
    padding: 1.5rem;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 3rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 660px;
  }
`;

const ContentHeader = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("xs")} {
    width: 260px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 300px;
  }
`;

const GradientLine = sc.hr`
    margin: 30px 0;
    height: 2px;
    width: 100%;
    border: none;
    background: -webkit-gradient(
      linear,
      0 0,
      100% 0,
      from(#6b6b6b),
      to(transparent),
      color-stop(50%, #6b6b6b)
    );
  `;

const PlayerImageContainer = styled(Box)`
  position: absolute;
  bottom: -4rem;
  left: 93%;
  width: 350px;
  z-index: 100;
  ${(props) => props.theme.breakpoints.up("xs")} {
    display: none;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    display: flex;
  }
`;

const CopyrightContainer = styled(Box)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("xs")} {
    position: static;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    bottom: -40px;
  }
`;

export function UnauthenticatedTemplate(): JSX.Element {
  useEffect(() => {
    // check for path to contain /sign-up
    if (window.location.pathname.includes("/sign-up")) {
      // check for token in query string
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      if (token) {
        // set token to recoil state signupTokenAtom and remove from query string then set the personAtom
        // to the person object returned from the signupToken
        setRecoil(signupTokenAtom, token);

        authSignUpPersonalGet().then((response) => {
          if (response.data) {
            setRecoil(personAtom, response.data);
          }
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        });
      }
    }
  }, []);
  const [randomPlayerImage, setRandomPlayerImage] = useState();

  useEffect(() => {
    const playerImages = [
      FootBallPlayerImage,
      FootBallPlayerImage1,
      BaseballPlayerImage,
      BaseballPlayerImage1,
      BasketballPlayerImage,
      GolfPlayerImage,
      IceHockeyPlayerImage,
      LacrossePlayerImage,
      TennisPlayerImage,
      VolleyballPlayerImage
    ];
    const randomIndex = Math.floor(Math.random() * playerImages.length);
    setRandomPlayerImage(playerImages[randomIndex]);
  }, []);
  return (
    <Root scheme={{}}>
      <Content sx={{ width: "100%", padding: 0 }}>
        <PageContainer>
          <ContentContainer>
            <ContentHeader>
              <img src={SGLogo} width="100%" />
            </ContentHeader>
            <GradientLine />
            <Outlet />
            <PlayerImageContainer>
              <img src={randomPlayerImage} width="100%" />
            </PlayerImageContainer>
          </ContentContainer>
          <CopyrightContainer>
            <Typography variant="caption" color="text.general.primary">
              SportsGravy &copy; {new Date().getFullYear()}. All Rights
              Reserved. Version {import.meta.env.VITE_APP_VERSION}
            </Typography>
          </CopyrightContainer>
          <svg
            style={{ position: "absolute", inset: 0, pointerEvents: "none" }}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
            viewBox="0 0 1440 750"
          >
            <g style={{ opacity: 0.25 }} fill="">
              <polygon
                points="0,0 75,0 785,750 0, 750"
                fill="#8F8F8F"
                style={{ opacity: 0.75 }}
              />
              <polygon
                points="0,0 300,0 1010,750 0, 750"
                fill="#8F8F8F"
                style={{ opacity: 0.5 }}
              />
              <polygon
                points="720,750 1440,125 1440,750 "
                fill="#8F8F8F"
                style={{ opacity: 0.75 }}
              />
            </g>
            <defs>
              <mask id="SvgjsMask1001">
                <rect width="1440" height="560" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </PageContainer>
      </Content>
    </Root>
  );
}
