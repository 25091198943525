export const formatNumber = (
  value: string | number | undefined,
  options?: { maxDecimalDigits?: number }
) => {
  if (!value) return "";

  value = value.toString();
  const number = parseFloat(value.replace(/,/g, ""));
  if (isNaN(number)) {
    return "";
  }
  const maxDecimalDigits = options?.maxDecimalDigits ?? 0;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimalDigits,
    useGrouping: true
  }).format(number);
};
