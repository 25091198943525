import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos, ArrowForwardIos, MoreVert } from "@mui/icons-material";
import { Form } from "@components/crud/Form";
import React from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  MediaPhotosVideosAtom,
  MediaPhotosVideosFilterAtom,
  organizationAtom,
  profileAtom
} from "@recoil/auth";
import {
  ModelMedia,
  ModelStream,
  useAdminAlbumAlbumIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { GalleryItemType } from "@pages/photos-videos/GalleryItem";
import { PAGE_SIZE } from "./AlbumView";
import { FullscreenMediaViewer } from "@pages/photos-videos/FullscreenMediaViewer";
import { ClipMediaDetails } from "./ClipMediaDetails";
import { AlbumClipContextMenu } from "./AlbumClipContextMenu";
import { CompletedLivestreamWatch } from "@pages/live-stream/CompletedLivestreamWatch";

export const AlbumClipView = () => {
  const user = useRecoilValue(profileAtom);

  const { albumId, mediaId } = useParams();
  const mediaList = useRecoilValue(MediaPhotosVideosAtom);
  const setMediaList = useSetRecoilState(MediaPhotosVideosAtom);
  const filters = useRecoilValue(MediaPhotosVideosFilterAtom);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const moreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [media, setMedia] = useState<ModelMedia>();
  const [mediaIndex, setMediaIndex] = useState<number>();
  const [scrollableMediaId, setScrollableMediaId] = useState(mediaId);
  const [pageNo, setPageNo] = useState(Math.ceil(mediaList.length / PAGE_SIZE));
  const organizationId = useRecoilValue(organizationAtom);
  const setMediaPhotosVideosAtom = useSetRecoilState(MediaPhotosVideosAtom);
  const [cropClip, setCropClip] = useState(false);
  const [permissions, setPermissions] = useState({
    removeFromAlbum: false,
    deleteClip: false,
    deleteAlbum: false,
    editAlbum: false
  });
  const { data, isLoading } = useAdminAlbumAlbumIdGet(
    albumId!,
    {
      organizationId: organizationId!,
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      ...filters
    },
    {
      query: {
        queryKey: [
          pageNo,
          ...(filters ? [Object.entries(filters).flat()] : [])
        ],
        refetchOnWindowFocus: false
      }
    }
  );

  useEffect(() => {
    const index = mediaList.findIndex((m) => m.id === scrollableMediaId);
    setMedia(mediaList[index]?.mediaDetails);
    setMediaIndex(index);
    if (index >= mediaList.length - 3 && mediaList.length % PAGE_SIZE === 0)
      setPageNo(Math.ceil(mediaList.length / PAGE_SIZE));
  }, [mediaList, scrollableMediaId]);

  useEffect(() => {
    if (data?.data && !isLoading) {
      const addData = data.data.media
        ?.filter((m) => m.baseUrl && m.path)
        .map((m) => {
          const path = m.baseUrl! + m.path!;
          return {
            id: m.mediaId!,
            type: m.type! as "IMAGE" | "VIDEO",
            date: m.createdAt,
            thumbnailSrc:
              m.type === "IMAGE" ? path : path.replace(".m3u8", ".0000001.jpg"),
            ...(m.type === "VIDEO" && {
              duration: Math.round(
                (m.metadata as { duration: number })?.duration || 0
              )
            }),
            mediaDetails: m,
            clipOf: m.clipOf
          };
        }) as GalleryItemType[];
      setMediaPhotosVideosAtom([
        ...mediaList,
        ...addData.filter((a) => !mediaList.find((m) => m.id === a.id))
      ]);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (data?.data) {
      const roles = user?.roles?.filter(
        (role) => role.organizationId === organizationId
      );
      const isAdmin = roles?.some((role) => role.role?.alias === "ADMIN");
      const isContributor = data.data.album?.contributors?.some(
        (c) => c.userId === user?.userId
      );

      const fetchPermissions = async () => {
        const del =
          media?.createdById === user?.userId ||
          (roles?.some((role) => role.role?.alias === "ADMIN") as boolean);
        setPermissions({
          removeFromAlbum: isAdmin || (isContributor as boolean),
          deleteClip: del,
          deleteAlbum: false,
          editAlbum: false
        });
      };
      fetchPermissions();
    }
  }, [media, data?.data]);

  return media ? (
    isFullScreen ? (
      <FullscreenMediaViewer
        media={media}
        onClose={() => {
          setIsFullScreen(false);
        }}
        currentIndex={mediaIndex || 0}
        isLastIndex={!!(mediaIndex && mediaIndex >= mediaList.length - 1)}
        onNavigate={(val) => {
          if (val === "prev")
            setScrollableMediaId(mediaList[mediaIndex! - 1]!.id!);
          else setScrollableMediaId(mediaList[mediaIndex! + 1]!.id!);
        }}
      />
    ) : (
      <Container>
        <Toolbar
          title="View Clip"
          backBtnClick={() => window.history.back()}
          backBtnLabel="Back to Album"
        />
        <Form>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                borderRadius: "12px",
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CompletedLivestreamWatch
                liveStream={media.clipOf as ModelStream}
                defaultClip={media}
                showClipsList={false}
                setCropClip={setCropClip}
              />
              {!cropClip && (
                <>
                  <IconButton
                    onClick={moreOptionsClick}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "white",
                      height: "50px",
                      width: "50px",
                      zIndex: 1000
                    }}
                  >
                    <MoreVert
                      htmlColor="white"
                      style={{ height: "35px", width: "35px" }}
                    />
                  </IconButton>

                  {mediaIndex !== 0 && (
                    <IconButton
                      onClick={() => {
                        setScrollableMediaId(mediaList[mediaIndex! - 1]!.id!);
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        transform: "translateY(-50%)",
                        zIndex: 1000
                      }}
                    >
                      <ArrowBackIos
                        htmlColor="rgba(255,255,255,0.5)"
                        fontSize="large"
                      />
                    </IconButton>
                  )}

                  {mediaIndex !== mediaList.length - 1 && !isLoading && (
                    <IconButton
                      onClick={() => {
                        setScrollableMediaId(mediaList[mediaIndex! + 1]!.id!);
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        zIndex: 1000
                      }}
                    >
                      <ArrowForwardIos
                        htmlColor="rgba(255,255,255,0.5)"
                        fontSize="large"
                      />
                    </IconButton>
                  )}
                </>
              )}
            </div>
          </div>
          {!cropClip && (
            <div style={{ marginTop: "20px" }}>
              <ClipMediaDetails media={media} />
            </div>
          )}
          <AlbumClipContextMenu
            permissions={permissions}
            handleClose={handleClose}
            anchorEl={anchorEl}
            mediaIds={[media.mediaId!]}
            mediaList={mediaList.map((m) => m.mediaDetails!)}
            album={data?.data.album}
            onDeleteClip={() => {
              const index = mediaList.findIndex((m) => m.id === mediaId);
              setMediaList(mediaList.filter((m) => m.id !== mediaId));
              if (index !== 0) {
                setScrollableMediaId(mediaList[index - 1].id!);
              } else {
                setScrollableMediaId(mediaList[index + 1].id!);
              }
            }}
          />
        </Form>
      </Container>
    )
  ) : (
    <></>
  );
};
