import { organizationAtom } from "@recoil/auth";
import React from "react";
import { useRecoilValue } from "recoil";
import { FSGOAdvertiserView } from "./FSGOAdvertiserView";
import { FSOAdvertiserView } from "./FSOAdvertiserView";

export const AdvertiserView = () => {
  const orgId = useRecoilValue(organizationAtom);
  return !orgId ? <FSGOAdvertiserView /> : <FSOAdvertiserView />;
};
