import React, { useEffect, useState } from "react";
import { Container } from "@components/crud/Container";
import Grid from "@mui/system/Unstable_Grid";
import { PlaidLink } from "react-plaid-link";
import { useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import { Button } from "@components/Button";
import { useAdminMerchantLinkTokenPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";
import { CRM_ORDER_BANK_ACH_TYPES } from "@utils/constants";
import { FormInput } from "@components/FormInput";

export const AddBankAccount = ({
  disabled,
  mode
}: {
  disabled: boolean;
  mode: "CREATE" | "EDIT" | "VIEW";
}) => {
  const { setValue, register, unregister, trigger, control } = useFormContext();
  const [linkToken, setLinkToken] = useState<string | null>(null);

  const { mutate: getToken, isLoading: generatingToken } =
    useAdminMerchantLinkTokenPost();

  const handleOpen = () => {
    getToken(void 0, {
      onSuccess: (data) => {
        setLinkToken(data.data.linkToken as string);
      },
      onError: () => {
        enqueueSnackbar("Failed to generate link token", { variant: "error" });
      }
    });
  };
  useEffect(() => {
    handleOpen();
    if (mode === "CREATE") {
      register("publicToken", { required: true });
      register("accountToken", { required: true });

      return () => {
        unregister("publicToken", { keepValue: true });
        unregister("accountToken", { keepValue: true });
      };
    }
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        {mode !== "CREATE" && (
          <>
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600"
                }}
              >
                Bank Account Details
              </Typography>
            </Grid>
            <Grid xs={4}>
              <FormSelect
                control={control}
                options={CRM_ORDER_BANK_ACH_TYPES}
                name="method"
                label="Account Type"
                disabled
              />
            </Grid>
            <Grid xs={4}>
              <FormInput
                control={control}
                name="number"
                label="Account Number"
                disabled
                type="text"
              />
            </Grid>
            <Grid xs={4}>
              <FormInput
                control={control}
                name="routing"
                label="Routing Number"
                disabled
                type="text"
              />
            </Grid>
          </>
        )}
        {mode !== "VIEW" && (
          <>
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600"
                }}
              >
                Connect Your Primary Bank Account with Plaid
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#666666"
                }}
              >
                Securely link your primary bank account in seconds using Plaid
                to send all revenue (Advertisements within Live Streams)
                generated from SportsGravy to your bank account.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <PlaidLink
                style={{ border: "none" }}
                token={linkToken}
                onSuccess={(public_token, metadata) => {
                  setValue("publicToken", public_token);
                  setValue("accountToken", metadata.accounts[0].id);
                  trigger("publicToken");
                  trigger("accountToken");
                }}
              >
                <Button
                  variant="admin-primary"
                  disabled={disabled || generatingToken}
                  isLoading={generatingToken}
                >
                  Add your Bank Account with Plaid
                </Button>
              </PlaidLink>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
