import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { FormSelect } from "@components/FormSelect";
import {
  ACTIVITY_OUTCOME,
  ACTIVITY_RELATED_TO,
  ActivityStatusTypes,
  CRM_ACTIVITY_NEXT_ACTIONS,
  CRM_CALL_DIRECTIONS
} from "@utils/constants";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SearchAddAccount } from "../components/SearchAddAccount";
import Grid from "@mui/material/Unstable_Grid2";
import { FormLabel, styled, Typography } from "@mui/material";

import { Footer } from "@components/crud/Footer";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormTimePicker } from "@components/FormTimePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Editor } from "@components/Editor";
import {
  participant,
  SearchPartipants
} from "../components/SearchParticipants";
import { QuickAddContactModal } from "../contacts/components/QuickAddContactForm";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchAddContact } from "../contacts/components/SearchAddContact";
import { SearchAddLead } from "../components/SearchAddLead";
import { SearchAddOpportunity } from "../components/SearchAddOpportunity";
import { organizationAtom, profileAtom } from "@recoil/auth";
import formatFullName from "@utils/formatFullName";
import { useRecoilValue } from "recoil";
import { removeNullValues } from "@utils/cleanObject";
import {
  ActivityCreateInputRelatesTo,
  adminCrmOpportunityOpportunityIdGet,
  adminCrmOrgOpportunityOpportunityIdGet,
  getAdminCrmAccountAccountId,
  getAdminCrmOrgAccountAccountId,
  mediaGet
  // usePostAdminCrmActivity
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { createCRMActivity, createCRMOrgActivity } from "@services/Network";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  CallMadeLoggedIcon,
  EmailSentLoggedIcon,
  MeetingLoggedIcon,
  TaskLoggedIcon
} from "@components/Icons";
import { hasPermission } from "@services/Casbin";
import { capitalize } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const ActivityType = styled("div")`
  padding: 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  .icon,
  svg {
    height: 26px !important;
    width: 26px !important;
  }

  &.selected {
    background-color: #2b337a;
    color: #fff;

    svg {
      color: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
`;
const IconStyle = {
  color: "#666666",
  height: "24px",
  width: "24px"
};
const ACTIVITY_TYPES = [
  {
    label: "Call",
    value: "CALL",
    icon: <CallMadeLoggedIcon style={IconStyle} />
  },
  {
    label: "Email",
    value: "EMAIL",
    type: "LOGGED",
    icon: <EmailSentLoggedIcon style={IconStyle} />
  },
  {
    label: "Meeting",
    value: "MEETING",
    type: "LOGGED",
    icon: <MeetingLoggedIcon style={IconStyle} />
  },
  {
    label: "Task",
    value: "TASK",
    type: "LOGGED",
    icon: <TaskLoggedIcon style={IconStyle} />
  }
];

export const ActivityAdd = () => {
  const navigate = useNavigate();
  dayjs.extend(timezone);
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const organizationId = useRecoilValue(organizationAtom);
  const opportunityId = searchParams.get("opportunityId");
  const {
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      ccEnabled: false,
      hasMedia: false,
      date: new Date(),
      time: new Date(),
      callParticipants: [],
      to: [],
      cc: [],
      bcc: [],
      meetingParticipants: [],
      timeZone: dayjs.tz.guess(),
      relatedField: "",
      relatedTo: "",
      description: "",
      nextAction: "",
      nextActionDate: new Date(),
      nextActionTime: new Date(),
      outcome: "",
      notes: "",
      emailContent: "",
      subject: "",
      callDirection: "",
      headline: "",
      status: "COMPLETED",
      reason: ""
    }
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.activities", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [addContactTo, setAddContactTo] = useState<string>();
  const [selectedActivity, setSelectedActivity] = useState<
    "CALL" | "EMAIL" | "MEETING" | "TASK" | undefined
  >(undefined);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [descValid, setDescValid] = useState(false);
  const [hasCC, setHasCC] = useState(false);

  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });

  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };
  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<string>();
  const [selectedLead, setSelectedLead] = useState<string>();
  const [selectedOpportunity, setSelectedOpportunity] = useState<string>();
  const [participants, setParticipants] = useState<participant[]>();
  const [to, setTo] = useState<participant[]>([]);
  const [cc, setCC] = useState<participant[]>([]);
  const [bcc, setBCC] = useState<participant[]>([]);
  const [relatedTo, setRelatedTo] = useState<string>();
  const [showOnHoldReason, setShowOnHoldReason] = useState(false);

  const userName = formatFullName(
    useRecoilValue(profileAtom)?.person as {
      firstName: string;
      lastName: string;
      middleName: string | undefined;
      suffix: string | undefined;
    } | null
  );
  const userId = useRecoilValue(profileAtom)?.userId || "";

  useEffect(() => {
    setValue("callParticipants", [
      //@ts-ignore
      {
        label: userName,
        text: "",
        value: userId,
        isInternal: true
      }
    ]);
  }, [userId, userName]);
  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const data = organizationId
          ? await getAdminCrmOrgAccountAccountId(accountId)
          : await getAdminCrmAccountAccountId(accountId);
        if (data.data.accountId) {
          const account = data.data;
          setSelectedAccount(data.data.accountId);
          setRelatedTo("ACCOUNT");
          setValue("relatedTo", "ACCOUNT", {
            shouldDirty: true
          });
          setValue("relatedField", account.name as string, {
            shouldDirty: true
          });
        }
      }
    };
    fetchData();
  }, [accountId]);

  useEffect(() => {
    const fetchData = async () => {
      if (opportunityId) {
        const data = organizationId
          ? await adminCrmOrgOpportunityOpportunityIdGet(opportunityId)
          : await adminCrmOpportunityOpportunityIdGet(opportunityId);
        if (data.data.opportunityId) {
          const opp = data.data;
          setSelectedOpportunity(opp.opportunityId);
          setRelatedTo("OPPORTUNITY");
          setValue("relatedTo", "OPPORTUNITY", {
            shouldDirty: true
          });
          setValue("relatedField", opp.name as string, {
            shouldDirty: true
          });
        }
      }
    };
    fetchData();
  }, [opportunityId]);

  useEffect(() => {
    if (searchParams.get("type"))
      setSelectedActivity(
        (searchParams.get("type") as string).toUpperCase() as
          | "CALL"
          | "EMAIL"
          | "MEETING"
          | "TASK"
      );
  }, [searchParams]);

  const [isUploadingImage, setUploadingImage] = useState(false);
  const mutation = organizationId
    ? createCRMOrgActivity()
    : createCRMActivity();
  const onSave = async (resetInstead: boolean) => {
    const time = getValues().time;
    let date = getValues().date;
    let nextActionDate = getValues().nextActionDate;
    const nextActionTime = getValues().nextActionTime;
    if (time) {
      const timeDate = new Date(time);
      const dateobj = new Date(date);
      dateobj.setHours(timeDate.getHours());
      dateobj.setMinutes(timeDate.getMinutes());
      date = dateobj;
    }
    if (selectedActivity === "CALL" && nextActionTime) {
      const timeDate = new Date(nextActionTime);
      const dateobj = new Date(nextActionDate);
      dateobj.setHours(timeDate.getHours());
      dateobj.setMinutes(timeDate.getMinutes());
      nextActionDate = dateobj;
    }
    const data = {
      date: date,
      timezone: getValues().timeZone,
      notes: getValues().notes,
      ...(selectedActivity === "CALL" && {
        nextAction: getValues().nextAction,
        nextActionDate: nextActionDate,
        direction: getValues().callDirection,
        outcome: getValues().outcome
      }),
      ...(organizationId
        ? {
            organizationId: organizationId
          }
        : {
            leadId: selectedLead
          }),
      relatesTo: relatedTo as ActivityCreateInputRelatesTo,
      accountId: selectedAccount,
      contactId: selectedContact,
      opportunityId: selectedOpportunity,
      type: selectedActivity,
      ...(selectedActivity === "EMAIL" && {
        to: to.map((t) => ({
          name: t.label,
          email: t.text,
          ...(t.isInternal
            ? {
                userId: t.value
              }
            : {
                contactId: t.value
              })
        })),
        cc: cc.map((t) => ({
          name: t.label,
          email: t.text
        })),
        bcc: bcc.map((t) => ({
          name: t.label,
          email: t.text
        })),
        body: getValues().emailContent,
        subject: getValues().subject
      }),
      ...((selectedActivity === "CALL" || selectedActivity === "MEETING") && {
        internalParticipants: [
          ...(participants
            ?.filter((p) => p.isInternal && p.value !== userId)
            .map((p) => p.value) || []),
          userId
        ],
        externalParticipants: participants
          ?.filter((p) => !p.isInternal)
          .map((p) => ({
            contactId: p.value,
            phone: p.phone || ""
          }))
      }),
      ...(selectedActivity === "TASK" && {
        status: getValues().status,
        ...(showOnHoldReason && {
          reason: getValues().reason
        })
      })
    };
    if (selectedActivity === "MEETING" || selectedActivity === "TASK") {
      const content = getValues().description;
      if (getValues().hasMedia) {
        setUploadingImage(true);
        await Promise.all(
          content?.map(async (op, idx) => {
            if (op.insert && Object.keys(op.insert).includes("image")) {
              const file = base64ImageToFile(
                op.insert.image,
                getValues().headline + "_" + idx
              );
              const filesPromises = await Promise.all(
                //@ts-ignore
                [file].map((file) => {
                  if (file instanceof File) {
                    const promise = uploadMediaUsingPresignedUrl(file);
                    return promise;
                  } else {
                    return file.mediaId;
                  }
                })
              );

              if (filesPromises) {
                const response = await mediaGet({
                  mediaIds: [filesPromises[0]]
                });
                if (
                  response &&
                  response.data.media &&
                  response.data.media.length
                ) {
                  const media = response.data.media[0];
                  if (media.media && media.media.baseUrl && media.media.path) {
                    let path = media.media.path;
                    if (media.media.state !== "PUBLISHED") {
                      path = path.replace("original/", "");
                    }
                    const urlPath = media.media.baseUrl + path;
                    content[idx].insert.image = urlPath;
                  }
                }
              }
            }
          })
        );

        setUploadingImage(false);
      }
      data.notes = content;
    }
    mutation.mutate(
      {
        data: removeNullValues(data)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Activity Created successfully!", {
            variant: "success"
          });
          if (resetInstead) {
            reset();
            setSelectedAccount(undefined);
            setSelectedContact(undefined);
            setSelectedActivity(undefined);
            setSelectedLead(undefined);
          } else {
            if (accountId) {
              navigate(`/crm/accounts/${accountId}`);
            } else if (opportunityId) {
              navigate(`/crm/opportunities/${opportunityId}`);
            } else navigate("/crm/activities");
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Activity!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Container>
      <Toolbar title="Add Activity" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <FormSelect
              control={control}
              name="relatedTo"
              options={
                organizationId
                  ? ACTIVITY_RELATED_TO.filter(
                      (a) => a.value !== "LEAD" && a.value !== "ORDER"
                    )
                  : ACTIVITY_RELATED_TO
              }
              label="Related To"
              onChange={(e) => {
                setRelatedTo(e.target.value);
                setSelectedAccount(undefined);
                setSelectedContact(undefined);
                setSelectedLead(undefined);
                setSelectedOpportunity(undefined);
              }}
              disabled={!!accountId}
              required
              rules={{
                required: "Related To is required"
              }}
            />
          </Grid>
          <Grid xs={6}>
            {!relatedTo || relatedTo === "ACCOUNT" ? (
              <SearchAddAccount
                label="Select Account"
                showAddAccountOption={false}
                accountSelected={(selectedAccount) => {
                  if (selectedAccount?.accountId)
                    setSelectedAccount(selectedAccount.accountId);
                }}
                onClear={() => {
                  setSelectedAccount(undefined);
                }}
                searchValue={accountId ? getValues().relatedField : undefined}
                disabled={!!accountId}
                organizationId={organizationId}
                required
              />
            ) : relatedTo === "CONTACT" ? (
              <SearchAddContact
                contactSelected={(contact) => {
                  setSelectedContact(contact?.contactId);
                }}
                label="Select Contact"
                hideAddContactOption
                onClear={() => {
                  setSelectedContact(undefined);
                }}
                organizationId={organizationId}
              />
            ) : relatedTo === "LEAD" ? (
              <SearchAddLead
                label="Select Lead"
                showAddLeadOption={false}
                leadSelected={(selectedLead) => {
                  if (selectedLead?.leadId)
                    setSelectedLead(selectedLead.leadId);
                }}
                onClear={() => {
                  setSelectedLead(undefined);
                }}
                required
              />
            ) : (
              <SearchAddOpportunity
                label="Select Opportunity"
                showAddOpportunityOption={false}
                searchValue={
                  opportunityId ? getValues().relatedField : undefined
                }
                opportunitySelected={(selectedOpportunity) => {
                  if (selectedOpportunity?.accountId)
                    setSelectedAccount(selectedOpportunity.accountId);
                  if (selectedOpportunity?.opportunityId)
                    setSelectedOpportunity(selectedOpportunity.opportunityId);
                }}
                onClear={() => {
                  setSelectedAccount(undefined);
                  setSelectedOpportunity(undefined);
                }}
                required
                organizationId={organizationId}
              />
            )}
          </Grid>
          <Grid xs={12}>
            <StyledFormLabel required={true}>
              <Typography variant="formLabel">Select Activity</Typography>
            </StyledFormLabel>
          </Grid>
          {ACTIVITY_TYPES.map((activity) => (
            <Grid key={activity.value} lg={3} xs={6} paddingTop={0}>
              <ActivityType
                className={
                  activity.value === selectedActivity ? "selected" : ""
                }
                onClick={() => {
                  // setSelectedActivity(
                  //   activity.value as "CALL" | "EMAIL" | "MEETING" | "TASK"
                  // );
                  setSearchParams({
                    ...(accountId ? { accountId: accountId } : {}),
                    ...(opportunityId ? { opportunityId: opportunityId } : {}),
                    type: capitalize(activity.value)
                  });
                  if (activity.value === "TASK")
                    setValue("status", "COMPLETED", {
                      shouldDirty: true
                    });
                }}
              >
                <span className="icon">{activity.icon}</span>
                <span>Log {activity.label}</span>
              </ActivityType>
            </Grid>
          ))}
          {selectedActivity === "TASK" && (
            <Grid container xs={12}>
              <Grid xs={6}>
                <FormSelect
                  name="status"
                  control={control}
                  label="Status"
                  options={ActivityStatusTypes}
                  onChange={(e) => {
                    setShowOnHoldReason(e.target.value === "ON_HOLD");
                  }}
                />
              </Grid>
              {showOnHoldReason && (
                <Grid xs={6}>
                  <FormInput
                    name="reason"
                    control={control}
                    label="Reason for On Hold"
                    required
                    type="text"
                    rules={{
                      required: "Reason is required"
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid lg={3} md={6}>
            <FormDatePicker
              disableFuture
              name="date"
              required
              control={control}
              label="Date"
              rules={{
                required: "Date is required"
              }}
              onChange={() => trigger("date")}
            />
          </Grid>
          <Grid lg={3} md={6}>
            <FormTimePicker
              name="time"
              control={control}
              label="Time"
              required
              rules={{
                required: "Time is required"
              }}
            />
          </Grid>
          <Grid xs={6}>
            <FormSelect
              control={control}
              name="timeZone"
              label="Time Zone"
              options={timeZoneOptions}
              required
              rules={{
                required: "Time Zone is required"
              }}
            />
            <FormCheckbox
              control={control}
              name="currentTimezone"
              label="Use current timezone"
              onChange={(e) => {
                if (e.target.checked) {
                  setValue("timeZone", dayjs.tz.guess(), {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }
              }}
            />
          </Grid>
          {selectedActivity === "CALL" && (
            <>
              <Grid xs={6}>
                <SearchPartipants
                  label="Call Participants"
                  control={control}
                  name="callParticipants"
                  required={true}
                  showAddOption={!!selectedAccount}
                  labelField="address"
                  selectedOption={{
                    label: userName,
                    text: "",
                    value: userId,
                    isInternal: true
                  }}
                  addNewlick={
                    selectedAccount
                      ? () => {
                          setAddContact(true);
                          setAddContactTo("callParticipants");
                        }
                      : undefined
                  }
                  type="CALL"
                  onChange={(option) => {
                    setParticipants(option);
                  }}
                  organizationId={organizationId}
                />
              </Grid>
              <Grid lg={3} md={6}>
                <FormSelect
                  label="Call Direction"
                  options={CRM_CALL_DIRECTIONS}
                  name="callDirection"
                  control={control}
                  required
                  rules={{
                    required: "Call Direction is required"
                  }}
                />
              </Grid>
              <Grid lg={3} md={6}>
                <FormSelect
                  label="Call Outcome"
                  options={ACTIVITY_OUTCOME}
                  name="outcome"
                  control={control}
                  required
                  rules={{
                    required: "Call Outcome is required"
                  }}
                />
              </Grid>
              <Grid xs={6}>
                <FormSelect
                  label="Next Action"
                  options={CRM_ACTIVITY_NEXT_ACTIONS}
                  name="nextAction"
                  control={control}
                  required
                  rules={{
                    required: "Next Action is required"
                  }}
                />
              </Grid>
              <Grid lg={3} md={6}>
                <FormDatePicker
                  disablePast
                  name="nextActionDate"
                  required
                  control={control}
                  label="Date"
                  rules={{
                    required: "Next Action Date is required"
                  }}
                  onChange={() => trigger("nextActionDate")}
                />
              </Grid>
              <Grid lg={3} md={6}>
                <FormTimePicker
                  name="nextActionTime"
                  control={control}
                  label="Time"
                  required
                  rules={{
                    required: "Next Action Time is required"
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <FormInput
                  name="notes"
                  control={control}
                  label="Call Notes"
                  type="text"
                  multiline
                  rows={4}
                />
              </Grid>
            </>
          )}
          {selectedActivity == "EMAIL" && (
            <>
              <Grid xs={12}>
                <SearchPartipants
                  label="Email To"
                  control={control}
                  name="to"
                  required={true}
                  showAddOption={!!selectedAccount}
                  labelField="email"
                  addNewlick={
                    selectedAccount
                      ? () => {
                          setAddContact(true);
                          setAddContactTo("to");
                        }
                      : undefined
                  }
                  type="EMAIL"
                  onChange={(option) => {
                    setTo(option);
                  }}
                  organizationId={organizationId}
                />
              </Grid>
              <Grid xs={12}>
                <FormCheckbox
                  control={control}
                  name="ccEnabled"
                  label="Add CC/BCC"
                  onChange={(e) => setHasCC(e.target.checked)}
                />
              </Grid>
              {hasCC && (
                <>
                  <Grid xs={12}>
                    <SearchPartipants
                      label="Email Cc"
                      control={control}
                      name="cc"
                      required={false}
                      showAddOption={!!selectedAccount}
                      labelField="email"
                      addNewlick={
                        selectedAccount
                          ? () => {
                              setAddContact(true);
                              setAddContactTo("cc");
                            }
                          : undefined
                      }
                      onChange={(option) => {
                        setCC(option);
                      }}
                      type="EMAIL"
                      organizationId={organizationId}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <SearchPartipants
                      label="Email Bcc"
                      control={control}
                      name="bcc"
                      required={false}
                      showAddOption={!!selectedAccount}
                      labelField="email"
                      type="EMAIL"
                      addNewlick={
                        selectedAccount
                          ? () => {
                              setAddContact(true);
                              setAddContactTo("bcc");
                            }
                          : undefined
                      }
                      onChange={(option) => {
                        setBCC(option);
                      }}
                      organizationId={organizationId}
                    />
                  </Grid>
                </>
              )}
              <Grid xs={12}>
                <FormInput
                  control={control}
                  name="subject"
                  label="Subject"
                  type="text"
                  required
                  rules={{
                    required: "Subject is required"
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <FormInput
                  label="Email Content"
                  type="text"
                  control={control}
                  multiline
                  rows={15}
                  name="emailContent"
                  required
                  rules={{
                    required: "Email Content is required"
                  }}
                />
              </Grid>
            </>
          )}
          {selectedActivity == "MEETING" && (
            <>
              <Grid xs={12}>
                <SearchPartipants
                  label="Participants"
                  control={control}
                  name="meetingParticipants"
                  required={true}
                  showAddOption={!!selectedAccount}
                  labelField="address"
                  type="MEETING"
                  addNewlick={
                    selectedAccount
                      ? () => {
                          setAddContact(true);
                          setAddContactTo("meetingParticipants");
                        }
                      : undefined
                  }
                  onChange={(option) => {
                    setParticipants(option);
                  }}
                  organizationId={organizationId}
                />
              </Grid>
              <Grid xs={12}>
                <StyledFormLabel>
                  <Typography variant="formLabel">Meeting Notes</Typography>
                </StyledFormLabel>
                <Editor
                  html={
                    getValues().description
                      ? getValues().description
                      : undefined
                  }
                  onBlur={() => {
                    validateDescriptionArray(getValues().description);
                  }}
                  onValueChange={(deltaOperations, hasMedia) => {
                    setValue("description", deltaOperations, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setValue("hasMedia", hasMedia);
                    validateDescriptionArray(deltaOperations);
                  }}
                  style={{
                    minHeight: "350px"
                  }}
                />
              </Grid>
            </>
          )}
          {selectedActivity == "TASK" && (
            <Grid xs={12}>
              <StyledFormLabel required>
                <Typography variant="formLabel">Task Notes</Typography>
              </StyledFormLabel>
              <Editor
                html={
                  getValues().description ? getValues().description : undefined
                }
                onBlur={() => {
                  validateDescriptionArray(getValues().description);
                }}
                onValueChange={(deltaOperations, hasMedia) => {
                  setValue("description", deltaOperations, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  setValue("hasMedia", hasMedia);
                  validateDescriptionArray(deltaOperations);
                }}
                style={{
                  minHeight: "350px"
                }}
              />
            </Grid>
          )}
        </Grid>
      </Form>
      <Footer
        saveBtnClick={() => {
          onSave(false);
        }}
        saveAndNewBtnClick={() => {
          onSave(true);
        }}
        isLoading={mutation.isLoading || isUploadingImage}
        cancelBtnClick={() => setOpenCancelDialog(true)}
        isDisabled={
          !isValid ||
          mutation.isLoading ||
          selectedActivity === undefined ||
          participants?.length === 0 ||
          (selectedActivity === "TASK" && !descValid) ||
          (relatedTo === "ACCOUNT" && !selectedAccount) ||
          (relatedTo === "CONTACT" && !selectedContact) ||
          (relatedTo === "LEAD" && !selectedLead) ||
          (relatedTo === "OPPORTUNITY" && !selectedOpportunity) ||
          (selectedActivity === "CALL" && participants?.length === 1)
        }
      />
      {selectedAccount && addContactTo && (
        <QuickAddContactModal
          showAddContactModal={addContact}
          setShowAddContactModal={setAddContact}
          selectedAccount={selectedAccount}
          onSave={(resp) => {
            if (
              resp.contactId &&
              (selectedActivity !== "EMAIL" ||
                resp.email ||
                resp.accounts?.[0]?.workEmail)
            ) {
              const values = getValues()[addContactTo!];
              const newValues = [...values];
              if (selectedActivity === "EMAIL") {
                if (resp.accounts?.[0]?.workEmail) {
                  newValues.push({
                    label: formatFullName(resp),
                    value: resp.contactId,
                    text: resp.accounts?.[0].workEmail,
                    isInternal: false
                  });
                }
                if (resp.email) {
                  newValues.push({
                    label: formatFullName(resp),
                    value: resp.contactId,
                    text: resp.email,
                    isInternal: false
                  });
                }
              } else {
                newValues.push({
                  label: formatFullName(resp),
                  value: resp.contactId,
                  text: "",
                  isInternal: false
                });
              }
              setValue(addContactTo, newValues, {
                shouldDirty: true,
                shouldValidate: true
              });
              switch (addContactTo) {
                case "cc":
                  setCC(newValues);
                  break;
                case "bcc":
                  setBCC(newValues);
                  break;
                case "to":
                  setTo(newValues);
                  break;
                case "meetingParticipants":
                  setParticipants(newValues);
                  break;
                case "callParticipants":
                  setParticipants(newValues);
                  break;
              }
            }
          }}
        />
      )}
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/activities")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
