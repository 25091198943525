import { TableViewInfinite } from "@components/TableViewInfinite";
import { getBuilds } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { BUILD_STATUS } from "@utils/constants";
import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ModelBuild } from "@sportsgravyengineering/sg-api-react-sdk";

export const Builds = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onAdd = () => {
    navigate(`/builds/create`);
  };

  const onEdit = (build) => {
    navigate(`/builds/${build.buildId}/edit`);
  };
  const onView = (build) => {
    navigate(`/builds/${build.buildId}`);
  };
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      const del = await checkPermission("tech.test-cases", "VIEW");
      setPermissions({
        create,
        edit,
        view: del
      });
    };
    fetchPermissions();
  }, [organizationId]);

  const filterConfig = {
    field: "filter",
    defaultValue: "all",
    options: BUILD_STATUS,
    placeholderOption: {
      label: "All",
      value: "all"
    }
  };
  const LIST_COLUMNS: GridColDef<ModelBuild>[] = [
    {
      headerName: "Build No",
      field: "buildNumber",
      minWidth: 150
    },
    {
      headerName: "Platform",
      field: "platform",
      minWidth: 150,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 700,
      valueGetter: ({ row }) => row.description || "-",
      sortable: false
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 190,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        const status = row.buildStatus;
        return (
          <Chip
            style={{
              fontSize: "14px",
              padding: "4px 8px",
              fontWeight: 600
            }}
            label={BUILD_STATUS.find((st) => st.value === status)?.label}
            sx={{
              background:
                status === "PASSED"
                  ? "#BBF7D0"
                  : status === "NOT_TESTED"
                    ? "#FBF1BC"
                    : "#FECACA",
              color:
                status === "PASSED"
                  ? "#166534"
                  : status === "NOT_TESTED"
                    ? "#7B5B08"
                    : "#991B1B"
            }}
          />
        );
      }
    }
  ];
  return (
    <>
      <TableViewInfinite
        title="Builds"
        useGet={getBuilds}
        isDeleteDisabled={() => true}
        columns={LIST_COLUMNS}
        searchable={true}
        getRowId={(row) => row.buildId}
        filterConfig={filterConfig}
        addBtnText="Add Build"
        onAdd={permissions.create ? onAdd : undefined}
        onEdit={onEdit}
        onView={onView}
        hidePlatformFilter={true}
        isEditDisabled={() => permissions.edit}
        hideFooter
      />
    </>
  );
};
