import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Control } from "react-hook-form";
import colors from "theme/colors";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { ChangeEvent, useState } from "react";
import { DeleteIcon } from "@components/Icons";
import Grid from "@mui/system/Unstable_Grid";
import { formatNumber } from "@utils/formatNumber";
export type OrderApproval = {
  job: string;
  amount?: string;
  percentage?: string;
};

export const CRMApprovalSettings = (props: {
  orderApprovals: OrderApproval[];
  setOrderApprovals?: (approvals: OrderApproval[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  disabled?: boolean;
  jobOptions: { label: string; value: string }[];
  onSave: (val: OrderApproval[]) => void;
  newApproval: OrderApproval;
  inputField: {
    name: string;
    rules: object;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    formatCurrency: boolean;
  };
  key?: string;
}) => {
  const [toDelete, setToDelete] = useState<
    | {
        index: number;
        approval: OrderApproval;
      }
    | undefined
  >(undefined);

  return (
    <Grid
      container
      direction="row"
      columnSpacing={3}
      rowSpacing={1.5}
      key={props.key || "0"}
    >
      {props.orderApprovals.map((rl, idx) => {
        return (
          <>
            <Grid
              xs={props.orderApprovals.length <= 1 || props.disabled ? 6 : 5.75}
            >
              <FormSelect
                name={`${props.inputField.name}.job${idx}`}
                label=" "
                value={rl.job}
                control={props.control}
                options={props.jobOptions}
                onChange={(e) => {
                  const approvals = props.orderApprovals;
                  approvals[idx].job = e!.target!.value;
                  if (props.setOrderApprovals) {
                    props.setOrderApprovals([...approvals]);
                    props.onSave(approvals);
                  }
                }}
                disabled={props.disabled}
                rules={{
                  required:
                    "Approval required for changing pricing tier is required"
                }}
              />
            </Grid>
            <Grid
              xs={props.orderApprovals.length <= 1 || props.disabled ? 6 : 5.75}
            >
              <FormInput
                control={props.control}
                name={`${props.inputField.name}.${props.inputField.name}${idx}`}
                label=""
                enteredValue={
                  props.inputField.formatCurrency
                    ? formatNumber(rl[props.inputField.name])
                    : rl[props.inputField.name]
                }
                type="text"
                disabled={props.disabled}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>).target
                    .value;
                  const approvals = props.orderApprovals;
                  const formattedValue = props.inputField.formatCurrency
                    ? formatNumber(value)
                    : value;

                  approvals[idx][props.inputField.name] = formattedValue;
                  if (props.setOrderApprovals) {
                    props.setOrderApprovals([...approvals]);
                    props.onSave(approvals);
                  }
                }}
                rules={props.inputField.rules}
                InputProps={{
                  startAdornment: props.inputField.startAdornment,
                  endAdornment: props.inputField.endAdornment
                }}
              />
            </Grid>
            {props.orderApprovals.length > 1 && !props.disabled && (
              <Grid xs={0.5} padding={0} height="fit-content">
                <div
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #E5E5E5",
                    height: "40px",
                    width: "40px",
                    cursor: "pointer",
                    marginTop: "15px"
                  }}
                  onClick={() => {
                    setToDelete({ index: idx, approval: rl });
                  }}
                >
                  <DeleteIcon height="24px" width="24px" />
                </div>
              </Grid>
            )}
          </>
        );
      })}
      {!props.disabled && (
        <Grid>
          <Button
            startIcon={<Add />}
            disabled={props.disabled}
            style={{
              textTransform: "capitalize",
              color: colors.primary.main
            }}
            onClick={() => {
              if (props.setOrderApprovals) {
                const rl = props.orderApprovals;
                props.setOrderApprovals([...rl, props.newApproval]);
              }
            }}
          >
            Add Approval
          </Button>
        </Grid>
      )}
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Order Approval"
        body={`Are you sure you want to delete Order Approval ${
          toDelete?.approval.job
            ? `"${toDelete.approval[props.inputField.name]}"`
            : ""
        } attached to this account?`}
        close={() => setToDelete(undefined)}
        onConfirm={() => {
          const approvals = props.orderApprovals;
          approvals.splice(toDelete?.index as number, 1);
          const value = props.control._formValues;
          for (let i = 0; i <= props.orderApprovals.length; i++) {
            delete value[`job${i}`];
            delete value[`${props.inputField.name}${i}`];
          }
          approvals.map((link, index) => {
            value[`job${index}`] = link.job;
            value[`${props.inputField.name}${index}`] =
              link[props.inputField.name];
          });
          props.control._reset(value);
          if (props.setOrderApprovals) {
            props.setOrderApprovals([...approvals]);
            props.onSave(approvals);
          }
        }}
        onCancel={() => setToDelete(undefined)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Grid>
  );
};
