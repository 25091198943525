import { FormCheckbox } from "@components/FormCheckbox";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect, SelectOption } from "@components/FormSelect";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  FormHelperText,
  InputAdornment,
  styled,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom } from "@recoil/auth";
import { useSponsorshipLevelGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

const StyledFormHelperText = styled(FormHelperText)`
  color: #b3b3b3;
  font-size: 13px;
`;

export const CampaignForm = ({
  contactOptions,
  mode,
  editableFields = [
    "name",
    "sponsorshipId",
    "startDate",
    "contactId",
    "taxExempt"
  ]
}: {
  contactOptions: SelectOption[];
  mode: "CREATE" | "EDIT" | "VIEW";
  editableFields?: string[];
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const { control, setValue } = useFormContext();
  const { data: levels, isLoading: levelLoading } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });

  const levelsOptions = useMemo(
    () =>
      levels?.data?.sponsorshipLevels?.map((level) => ({
        label: `${level.name!} - ${formatCurrency(level.amount)}`,
        value: level.sponsorshipId
      })) || [],
    [levels]
  );
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <FormInput
          control={control}
          required
          label="Advertiser"
          disabled
          type="text"
          name="advertiser"
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          control={control}
          required
          label="Campaign Name"
          type="text"
          name="name"
          disabled={mode === "VIEW" || editableFields.indexOf("name") === -1}
          rules={{
            required: "Campaign Name is required"
          }}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <FormSelect
          control={control}
          name="sponsorshipId"
          label="Sponsorship Level"
          required={true}
          options={levelsOptions}
          isLoading={levelLoading}
          disabled={
            mode === "VIEW" || editableFields.indexOf("sponsorshipId") === -1
          }
          rules={{
            required: "Sponsorship Level is required"
          }}
        />
      </Grid>
      <Grid xs={6}>
        <FormDatePicker
          control={control}
          name="startDate"
          required
          disablePast
          label="Start Date"
          disabled={
            mode === "VIEW" || editableFields.indexOf("startDate") === -1
          }
          rules={{
            required: "Start Date is required"
          }}
          onChange={(e) => {
            if (!e) {
              setValue("endDate", "");
              return;
            }
            const date = new Date(e);
            date.setFullYear(date.getFullYear() + 1);
            setValue("endDate", date);
          }}
        />
        {mode === "EDIT" && (
          <StyledFormHelperText>
            Note: The start date for this campaign will be automatically updated
            once the advertiser pays for their sponsorship.
          </StyledFormHelperText>
        )}
      </Grid>
      <Grid xs={6}>
        <FormDatePicker
          control={control}
          name="endDate"
          required
          disablePast
          label="End Date"
          disabled
          rules={{
            required: "Start Date is required"
          }}
        />
      </Grid>
      <Grid xs={6}>
        <StyledFormLabel required>
          <Typography variant="formLabel">Billing Contact</Typography>
        </StyledFormLabel>
        <Controller
          control={control}
          name="contactId"
          rules={{
            required: "Billing Contact is required"
          }}
          render={({ field, fieldState }) => (
            <>
              <Autocomplete
                value={
                  contactOptions.find(
                    (option) => option.value === field.value
                  ) || null
                }
                clearOnBlur
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => option?.label || ""}
                disabled={
                  mode === "VIEW" || editableFields.indexOf("contactId") === -1
                }
                id="tags-outlined"
                options={contactOptions}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography>{option.label}</Typography>
                  </li>
                )}
                onBlur={field.onBlur}
                filterSelectedOptions
                renderInput={(params) => {
                  {
                    if (Array.isArray(params.InputProps.startAdornment)) {
                      params.InputProps.startAdornment.splice(
                        0,
                        0,
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    } else {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    }
                    return <TextField {...params} />;
                  }
                }}
                onChange={(e, value) => {
                  field.onChange(value?.value);
                }}
              />
              {fieldState.error?.message && (
                <FormHelperText error>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <StyledFormHelperText>
          Note: This contact will be responsible for receiving and paying the
          invoice related to this campaign.
        </StyledFormHelperText>
      </Grid>
      <Grid xs={6}>
        <div style={{ marginTop: "30px" }}>
          <FormCheckbox
            name="taxExempt"
            control={control}
            label="Tax Exempt"
            disabled={
              mode === "VIEW" || editableFields.indexOf("taxExempt") === -1
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};
