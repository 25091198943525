import { organizationAtom } from "@recoil/auth";
import React from "react";
import { useRecoilValue } from "recoil";
import { FSGOAdvertiserCreate } from "./FSGOAdvertiserCreate";
import { FSOAdvertiserCreate } from "./FSOAdvertiserCreate";

export const AdvertiserCreate = () => {
  const orgId = useRecoilValue(organizationAtom);
  return !orgId ? <FSGOAdvertiserCreate /> : <FSOAdvertiserCreate />;
};
