import { StyledFormLabel } from "@components/StyledFormLabel";
import { Box, FormHelperText, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import logoPickerImage from "@assets/images/iconPicker.png";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import { SearchAddAccount } from "@pages/crm/components/SearchAddAccount";
import {
  getAdminCrmOrgAccountAccountId,
  ModelAdvertiser,
  ModelMedia,
  ModelOrganizationAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { FormInput } from "@components/FormInput";
import { FormCheckbox } from "@components/FormCheckbox";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { EMAIL_REGEX } from "@utils/validation";
import { FormSelect } from "@components/FormSelect";
import { Loader } from "@components/crud/Loader";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export type SelectedAccountContact = {
  contactId: string;
  firstName: string;
  lastName: string;
  workPhone?: string;
  workEmail: string | undefined;
  personalEmail: string | undefined;
  jobTitle: string;
  allowApproveDecline?: boolean;
  emailType?: "WORK_EMAIL" | "PERSONAL_EMAIL" | undefined;
  accountId?: string;
};

export const FSOAdvertiserForm = ({
  mode,
  advertiser,
  setLogo,
  setAccountContacts,
  logo,
  selectedAccountContacts = []
}: {
  mode: "CREATE" | "EDIT" | "VIEW";
  advertiser?: ModelAdvertiser;
  selectedAccountContacts?: SelectedAccountContact[];
  setLogo?: (file: File | undefined) => void;
  setAccountContacts?: (contacts: SelectedAccountContact[]) => void;
  logo?: ModelMedia | File | undefined;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { control, setValue, watch } = useFormContext();
  const [iconFieldTouched, setIconFieldTouched] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [selectedAccount, setSelectedAccount] = useState<
    ModelOrganizationAccount | undefined
  >(advertiser?.account);
  const [contacts, setContacts] = useState<SelectedAccountContact[]>([]);
  const [dafaultEmail, setDefaultEmail] = useState<
    {
      work: string | undefined;
      personal: string | undefined;
    }[]
  >([]);

  const accountId = watch("accountId");

  useEffect(() => {
    if (selectedAccountContacts.length) {
      setDefaultEmail(
        selectedAccountContacts.map((c) => ({
          work: c.workEmail,
          personal: c.personalEmail
        }))
      );
      setContacts(selectedAccountContacts);
    }
  }, [selectedAccountContacts]);

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (tempFiles && tempFiles[0]) {
      setFile(tempFiles[0]);
      setLogo && setLogo(tempFiles[0]);
    }
    event.target!.value = "";
  };
  const crmContactDetails = (index: number) => {
    const contact = contacts[index];
    const defaultEmail = dafaultEmail[index];
    return (
      <>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].firstName`}>
            <FormInput
              name={`contact[${index}].firstName`}
              control={control}
              rules={{ required: "First Name is required" }}
              label="First Name"
              type="text"
              required
              disabled
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].lastName`}>
            <FormInput
              name={`contact[${index}].lastName`}
              control={control}
              rules={{ required: "Last Name is required" }}
              label="Last Name"
              type="text"
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].jobTitle`}>
            <FormInput
              name={`contact[${index}].jobTitle`}
              required
              control={control}
              label="Job Title"
              disabled
              type="text"
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].workPhone`}>
            <FormInput
              name={`contact[${index}].workPhone`}
              control={control}
              label="Work Phone"
              disabled
              type="tel"
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].workEmail`}>
            <FormInput
              name={`contact[${index}].workEmail`}
              control={control}
              disabled={
                (defaultEmail?.work
                  ? !!defaultEmail?.work
                  : !contact?.allowApproveDecline) || mode === "VIEW"
              }
              rules={{
                validate: (value) =>
                  value
                    ? !EMAIL_REGEX.test(value)
                      ? "Invalid Email"
                      : undefined
                    : undefined
              }}
              label="Work Email"
              type="text"
              onChange={(e) => {
                const value = (e as ChangeEvent<HTMLInputElement>)?.target
                  ?.value;

                let emailType: "PERSONAL_EMAIL" | "WORK_EMAIL" | undefined =
                  undefined;

                if (value) emailType = "WORK_EMAIL";
                else if (!value && contact.personalEmail)
                  emailType = "PERSONAL_EMAIL";

                setValue(`contact[${index}].emailType`, emailType);

                setContacts((prev) =>
                  prev.map((c, i) =>
                    i === index
                      ? {
                          ...c,
                          workEmail: value,
                          emailType: emailType
                        }
                      : c
                  )
                );
              }}
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].personalEmail`}>
            <FormInput
              name={`contact[${index}].personalEmail`}
              control={control}
              disabled={
                (defaultEmail?.personal
                  ? !!defaultEmail?.personal
                  : !contact?.allowApproveDecline) || mode === "VIEW"
              }
              label="Personal Email"
              type="text"
              rules={{
                validate: (value) =>
                  value
                    ? !EMAIL_REGEX.test(value)
                      ? "Invalid Email"
                      : undefined
                    : undefined
              }}
              onChange={(e) => {
                const value = (e as ChangeEvent<HTMLInputElement>)?.target
                  ?.value;

                let emailType: "PERSONAL_EMAIL" | "WORK_EMAIL" | undefined =
                  undefined;

                if (contact.workEmail) emailType = "WORK_EMAIL";
                else if (value && !contact.workEmail)
                  emailType = "PERSONAL_EMAIL";

                setValue(`contact[${index}].emailType`, emailType);

                setContacts((prev) =>
                  prev.map((c, i) =>
                    i === index
                      ? {
                          ...c,
                          personalEmail: (e as ChangeEvent<HTMLInputElement>)
                            ?.target?.value,
                          emailType: emailType
                        }
                      : c
                  )
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          marginTop="10px"
          paddingLeft="0"
          paddingBottom="0"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
          }}
        >
          <StyledFormLabel
            required={contact?.allowApproveDecline}
            style={{
              width: "210px",
              fontWeight: 600,
              marginTop: "5px"
            }}
          >
            Send Approval Emails To
          </StyledFormLabel>
          <FormSelect
            sx={{
              height: "30px",
              width: "150px"
            }}
            control={control}
            disabled={
              !(contact?.workEmail && contact?.personalEmail) || mode === "VIEW"
            }
            rules={{
              required: contact?.allowApproveDecline
                ? "Email Type is required"
                : undefined
            }}
            name={`contact[${index}].emailType`}
            options={[
              ...(contact?.workEmail || mode === "VIEW"
                ? [
                    {
                      label: "Work Email",
                      value: "WORK_EMAIL"
                    }
                  ]
                : []),
              ...(contact?.personalEmail || mode === "VIEW"
                ? [
                    {
                      label: "Personal Email",
                      value: "PERSONAL_EMAIL"
                    }
                  ]
                : [])
            ]}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={12}>
        {mode !== "VIEW" ? (
          <Controller
            name="icon"
            control={control}
            rules={{
              required: "Advertiser Logo is required"
            }}
            render={({ field }) => (
              <FormInputContainer>
                <StyledFormLabel required>
                  <Typography display="inline" variant="formLabel">
                    {"Advertiser Logo"}
                  </Typography>
                </StyledFormLabel>
                <Grid container spacing={3} xs={12} md={12}>
                  <Grid xs={12} md={2}>
                    {file || logo ? (
                      <MediaContianerWithDelete
                        onConfirmDelete={() => {
                          setFile(null);
                          setLogo && setLogo(undefined);
                        }}
                        index={0}
                      >
                        <img
                          src={
                            (logo as ModelMedia)?.mediaId
                              ? (logo as ModelMedia)?.baseUrl
                                ? ((((logo as ModelMedia)?.baseUrl as string) +
                                    (logo as ModelMedia)?.path) as string)
                                : ""
                              : file
                                ? URL.createObjectURL(file)
                                : ""
                          }
                          style={{
                            width: "96px",
                            height: "96px"
                          }}
                        />
                      </MediaContianerWithDelete>
                    ) : (
                      <img
                        src={logoPickerImage}
                        style={{
                          width: "96px",
                          height: "96px"
                        }}
                        onClick={() => {
                          setIconFieldTouched(true);
                          inputFileRef.current?.click();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <input
                  type="file"
                  onChange={(e) => onImageChange(e, field)}
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                {iconFieldTouched && !file && (
                  <FormHelperText error>
                    Advertiser Logo is required
                  </FormHelperText>
                )}
              </FormInputContainer>
            )}
          />
        ) : (
          <img
            src={
              advertiser?.logo?.baseUrl
                ? advertiser?.logo?.baseUrl + advertiser?.logo?.path
                : ""
            }
            style={{
              width: "96px",
              height: "96px"
            }}
          />
        )}
      </Grid>
      <Grid xs={12} md={12}>
        {mode === "CREATE" ? (
          <SearchAddAccount
            accountSelected={async (selectedAccount) => {
              setValue("businessName", selectedAccount?.account);
              setValue("accountId", selectedAccount?.accountId);
              setValue("advertiser", selectedAccount?.account);
              setIsLoading(true);
              const acc = await getAdminCrmOrgAccountAccountId(
                selectedAccount.accountId!
              );
              selectedAccount.contacts = acc.data.contacts;
              setSelectedAccount(selectedAccount);

              setIsLoading(false);
              const selectedAccountContacts: SelectedAccountContact[] =
                acc.data.contacts?.map((c) => ({
                  contactId: c.contactId!,
                  firstName: c.contact?.firstName || "",
                  lastName: c.contact?.lastName || "",
                  workEmail: c.workEmail || "",
                  workPhone: c.workPhone || "",
                  personalEmail: c.contact?.email || "",
                  jobTitle: c.jobTitle || "",
                  allowApproveDecline: selectedAccount.contacts.length === 1,
                  accountId: selectedAccount.accountId,
                  emailType:
                    c.workEmail && !!c.workEmail.length
                      ? "WORK_EMAIL"
                      : c.contact?.email && !!c.contact?.email.length
                        ? "PERSONAL_EMAIL"
                        : undefined
                })) || [];
              setValue("contact", selectedAccountContacts);
              setDefaultEmail(
                selectedAccountContacts.map((c) => ({
                  work: c.workEmail,
                  personal: c.personalEmail
                }))
              );
              setContacts(selectedAccountContacts);
              setAccountContacts && setAccountContacts(selectedAccountContacts);
            }}
            showAddAccountOption={false}
            label="Search and Select Account"
            required={true}
            organizationId={organizationId}
            filterOption={(option) =>
              !option?.advertisers || option?.advertisers?.length === 0
            }
          />
        ) : (
          <FormInput
            name="account"
            control={control}
            label="Search and Select Account"
            required
            type="text"
            disabled
          />
        )}
      </Grid>
      <Grid xs={12}>
        <FormInput
          label="Advertiser Display Name"
          required
          name="businessName"
          control={control}
          type="text"
          rules={{
            required: "Advertiser Display Name is required"
          }}
          disabled={mode === "VIEW"}
        />
      </Grid>
      {accountId && (
        <Loader isLoading={isLoading}>
          {(mode === "VIEW"
            ? selectedAccountContacts
            : selectedAccount?.contacts
          )?.map((c, idx) => (
            <Fragment key={c.contactId}>
              <Grid xs={12} sx={{ padding: 0, paddingLeft: "12px" }}>
                <FormCheckbox
                  name={`contact[${idx}].allowApproveDecline`}
                  control={control}
                  disabled={contacts.length === 1 || mode === "VIEW"}
                  label={`Allow ${mode === "VIEW" ? c.firstName : c.contact?.firstName} ${mode === "VIEW" ? c.lastName : c.contact?.lastName} to approve and decline advertisements`}
                  labelStyle={{
                    color: "#000",
                    opacity: 0.5,
                    letterSpacing: "0.5px",
                    textTransform: "uppercase"
                  }}
                  onChange={(e) => {
                    const updatedContacts = contacts.map((c, i) =>
                      i === idx
                        ? {
                            ...c,
                            allowApproveDecline: (
                              e as ChangeEvent<HTMLInputElement>
                            )?.target?.checked
                          }
                        : c
                    );
                    setContacts(updatedContacts);
                    setAccountContacts && setAccountContacts(updatedContacts);
                  }}
                />
              </Grid>
              <HeaderUnderLine width="98%" marginLeft="12px" />
              <Grid xs={12} sx={{ marginTop: "12px" }}>
                {crmContactDetails(idx)}
              </Grid>
            </Fragment>
          ))}
        </Loader>
      )}
    </Grid>
  );
};
