import { Container } from "@components/crud/Container";
import { RenderTableView } from "@components/RenderTableView";
import { ToolTip } from "@components/ToolTip";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton, styled, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "@components/Button";
import { SearchInput } from "@components/SearchInput";
import { useContext, useEffect, useState } from "react";
import { NoRecords } from "@components/NoRecords";
import formatFullName from "@utils/formatFullName";
import { useNavigate } from "react-router-dom";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import {
  ModelAccount,
  ModelAccountContact,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { GridColDef } from "@mui/x-data-grid";
import { createCRMActivity } from "@services/Network";
import { Loader } from "@components/crud/Loader";
import GoogleMap from "@components/GoogleMap";
import { Form } from "@components/crud/Form";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AccountContacts = ({
  contacts,
  onClickAdd,
  account,
  organizationId
}: {
  contacts;
  onClickAdd: () => void;
  account: ModelAccount;
  organizationId?: string;
}) => {
  dayjs.extend(timezone);
  const { setContact } = useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();
  const [addressToView, setAddressToView] = useState<string | undefined>(
    undefined
  );
  const [textSearch, setTextSearch] = useState("");
  const [rows, setRows] = useState(contacts);

  useEffect(() => {
    const filteredRows = contacts.filter((row) => {
      const searchTerms = textSearch.toLowerCase().split(" ");
      const nameMatches = (name, term) =>
        name && name.toLowerCase().includes(term);

      return searchTerms.every(
        (term) =>
          nameMatches(row.contact.firstName, term) ||
          nameMatches(row.contact.middleName, term) ||
          nameMatches(row.contact.lastName, term)
      );
    });
    setRows(filteredRows);
  }, [textSearch, contacts]);
  const { setEmailTo } = useContext(CallEmailTemplateContext);
  const navigate = useNavigate();
  const CONTACT_COLUMNS: GridColDef<ModelAccountContact>[] = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      maxWidth: 300,
      sortable: false,
      renderHeader: () => {
        return <div style={{ padding: "20px 20px" }}>Action</div>;
      },
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 20px", display: "flex" }}>
            <IconButton
              onClick={() => {
                navigate(`/crm/contacts/${params.row.contactId}`);
              }}
            >
              <ToolTip title="View Contact" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`/crm/contacts/${params.row.contactId}/edit`);
              }}
            >
              <ToolTip title="Edit Contact" placement="top">
                <Edit style={IconStyle} />
              </ToolTip>
            </IconButton>
            {!organizationId && (
              <>
                <Loader isLoading={mutation.isLoading}>
                  <IconButton
                    disabled={!params.row.contact?.phone}
                    onClick={() => {
                      setContact({
                        contactId: params.row.contactId,
                        phone: params?.row?.contact?.phone,
                        email: params?.row?.contact?.email,
                        firstName: params?.row?.contact?.firstName,
                        lastName: params?.row.contact?.lastName,
                        location: params.row.contact?.location,
                        accounts: [
                          {
                            accountId: account?.accountId,
                            jobTitle: params.row.jobTitle,
                            isPrimary: params.row.isPrimary,
                            workPhone: params.row.workPhone,
                            workEmail: params.row.workEmail,
                            account: {
                              ...account
                            }
                          }
                        ]
                      });
                    }}
                  >
                    <ToolTip
                      title={`Click to Call ${params?.row?.contact?.firstName} ${params?.row?.contact?.lastName}`}
                      placement="top-start"
                    >
                      <CallIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                </Loader>
                {params.row.contact?.email || params.row.workEmail ? (
                  <IconButton
                    disabled={!params.row.contact?.email}
                    onClick={() => {
                      setEmailTo({
                        to: params.row.contact?.email,
                        name: formatFullName(
                          params.row.contact as {
                            firstName: string;
                            middleName: string;
                            lastName: string;
                            suffix: string;
                          }
                        ),
                        accountId: account?.accountId,
                        relatesTo: "CONTACT",
                        contactId: params.row.contact?.contactId,
                        accountName: account?.name,
                        autoSuggestOptions: [
                          ...(params.row.workEmail
                            ? [
                                {
                                  label: formatFullName(
                                    params.row.contact as ModelPerson
                                  ),
                                  text: params.row.workEmail,
                                  value: params.row.contactId!,
                                  isInternal: false,
                                  emailDetails: {
                                    email: params.row.workEmail,
                                    emailInfo: `Work Email`,
                                    isPrimaryStar: params.row.isPrimary
                                  }
                                }
                              ]
                            : []),
                          ...(params.row.contact?.email
                            ? [
                                {
                                  label: formatFullName(
                                    params.row.contact as ModelPerson
                                  ),
                                  text: params.row.contact.email,
                                  value: params.row.contactId!,
                                  isInternal: false,
                                  emailDetails: {
                                    email: params.row.contact.email,
                                    emailInfo: `Personal Email`,
                                    isPrimaryStar: params.row.isPrimary
                                  }
                                }
                              ]
                            : [])
                        ]
                      });
                    }}
                  >
                    <ToolTip
                      title={`Click to Email ${formatFullName(
                        params.row.contact as ModelPerson
                      )}`}
                      placement="top-start"
                    >
                      <EmailIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      maxWidth: 350,
      valueGetter: ({ row }) => formatFullName(row.contact as ModelPerson),
      renderCell: ({ row }) => {
        return formatFullName(row.contact as ModelPerson);
      }
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1,
      maxWidth: 350,
      renderCell: ({ row }) => {
        return row.jobTitle ?? "-";
      }
    },
    {
      field: "isPrimary",
      headerName: "Is Primary",
      flex: 1,
      sortable: false,
      maxWidth: 150,
      renderCell: ({ row }) => {
        return row.isPrimary ? "Yes" : "No";
      }
    }
  ];
  return (
    <Container>
      <Form sx={{ padding: "0px !important" }}>
        <StyledBox>
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 300,
                fontSize: "32px"
              }}
            >
              Contacts
            </Typography>
            <Button
              variant="admin-primary"
              startIcon={<Add style={{ height: "27px", width: "27px" }} />}
              onClick={onClickAdd}
            >
              Add
            </Button>
          </div>
          <SearchInput
            required={false}
            placeholder="Search"
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </StyledBox>
        <RenderTableView
          title=""
          hideToolbar
          rows={rows}
          columns={CONTACT_COLUMNS}
          getRowId={(row) => row.contactId}
          hasActionColumn={false}
          hideFooter
          sortingMode="client"
          getRowHeight={() => "auto"}
          noRecordsFoundElemet={
            <NoRecords
              title="The list is empty"
              description="Add contacts related to this account"
              buttonClick={onClickAdd}
              buttonText="Add Contact"
              buttonIcon={<Add />}
            />
          }
          pinnedColumns={{
            left: ["action", "name"]
          }}
        />
        {addressToView && (
          <GoogleMap
            close={() => setAddressToView(undefined)}
            address={addressToView}
            showSearchBar={false}
            showClickedAddress={false}
          />
        )}
      </Form>
    </Container>
  );
};
